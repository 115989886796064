import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'

import { LabsStudentsArgsType, LabsStudentsResponseType } from '../students'

export const labsStudentsAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_LOGIN_URL,
    credentials: 'include',
  }),
  endpoints: build => ({
    getStudent: build.query<LabsStudentsResponseType, LabsStudentsArgsType>({
      providesTags: () => ['labsStudents'],
      query: ({ courseId, studentId }) => {
        return {
          headers: {
            'COURSE-ID': courseId,
          },
          url: '/labs/students/' + studentId,
        }
      },
    }),
    sendStudentLinkInTelegram: build.mutation({
      invalidatesTags: () => ['labsStudents'],
      query: (baseId: number) => {
        return {
          method: 'POST',
          url: `/mentor-labs/send-student-link-to-mentor/${baseId}`,
        }
      },
    }),
  }),
  reducerPath: 'labsStudentsAPI',
  tagTypes: ['labsStudents'],
})

export const { useGetStudentQuery, useSendStudentLinkInTelegramMutation } = labsStudentsAPI

import { Dispatch } from 'react'
import { Control, Controller, UseFormClearErrors, UseFormSetValue } from 'react-hook-form'

import { InputLabel, MenuItem, Select } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { Video } from 'features/videos/service'

type Props = {
  clearErrors: UseFormClearErrors<Video>
  control: Control<Video>
  setValue: UseFormSetValue<Video>
  setVideoUrl: Dispatch<string>
}

export const HostingController = ({ clearErrors, control, setValue, setVideoUrl }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue={'youtube'}
      name={'hosting'}
      render={({ field }) => (
        <FormControl fullWidth variant={'outlined'}>
          <InputLabel>Хостинг</InputLabel>
          <Select
            label={'Хостинг'}
            {...field}
            onChange={event => {
              setVideoUrl('')
              setValue('hostingId', '')
              setValue('hostingHash', '')
              clearErrors()

              field.onChange(event.target.value)
            }}
          >
            <MenuItem value={'youtube'}>Youtube</MenuItem>
            <MenuItem value={'vimeo'}>Vimeo</MenuItem>
            <MenuItem value={'vk'}>VK</MenuItem>
          </Select>
        </FormControl>
      )}
    />
  )
}

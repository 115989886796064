import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { util } from 'features/videos/service'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { CommentsArgs, CommentsResponse } from '../service'

export const commentsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_VIDEO_URL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    deleteComment: build.mutation({
      invalidatesTags: () => ['comments'],
      onQueryStarted(_, { dispatch }): Promise<void> | void {
        dispatch(util.invalidateTags(['delete comment-card']))
      },
      query: (commentId: string) => {
        return {
          method: 'DELETE',
          url: `/admin/comments/${commentId}`,
        }
      },
    }),
    getComments: build.query<CommentsResponse, CommentsArgs>({
      providesTags: () => ['comments'],
      query: ({ courseId, page, pageSize, search, videoAuthor }) => {
        return {
          params: {
            courseId,
            page,
            pageSize,
            search,
            videoAuthor,
          },
          url: '/admin/comments',
        }
      },
    }),
  }),
  reducerPath: 'commentsService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['comments'],
})

export const { useDeleteCommentMutation, useGetCommentsQuery } = commentsService

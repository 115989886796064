import { Dispatch, SetStateAction } from 'react'

import { Checkbox } from '@it-incubator/ui-kit'
import { Playlist } from 'features/playlists/service'
import { lessThanYearLeft } from 'utils'

import s from './MaxStudentStartCourseDateCell.module.css'

type Props = {
  playlist: Playlist
  setSettingIds: Dispatch<SetStateAction<string[]>>
  settingIds: string[]
  showShort: boolean
}

export const MaxStudentStartCourseDateCell = ({
  playlist,
  setSettingIds,
  settingIds,
  showShort,
}: Props) => {
  const shortCount = 1

  const settingIdChangeHandler = (settingId: string) => {
    if (settingIds.includes(settingId)) {
      setSettingIds(settingIds.filter(id => id !== settingId))

      return
    }

    setSettingIds([...settingIds, settingId])
  }

  return (
    <div className={s.container}>
      {[...playlist.settings]
        .sort((a, b) => a.courseId - b.courseId)
        .map((setting, i) => {
          const dateRenderCondition = showShort ? i < shortCount : true

          const maxStudentStartCourseDate = new Date(setting.maxStudentStartCourseDate)
          const isLessThanYearLeft = lessThanYearLeft(maxStudentStartCourseDate)
          const redTextClassName = isLessThanYearLeft ? s.redText : ''

          const settingId = setting.id as string
          const selected = settingIds.includes(settingId)

          return (
            dateRenderCondition && (
              <div className={`${s.dateAndCheckboxContainer} ${redTextClassName}`} key={setting.id}>
                <Checkbox checked={selected} onChange={() => settingIdChangeHandler(settingId)} />

                <div>{maxStudentStartCourseDate.toLocaleDateString('ru-RU')}</div>
              </div>
            )
          )
        })}
    </div>
  )
}

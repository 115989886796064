import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  Combobox,
  Dialog,
  MessageBubble,
  MessageInput,
  Scrollbar,
  Skeleton,
  Typography,
} from '@it-incubator/ui-kit'
import { selectAdmin } from 'app'
import { LoadingOverlay } from 'common/components/loading-overlay/LoadingOverlay'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { PATH } from 'common/components/routing/Routing'
import { useChats, useInfinityScroll, useScrollToMessageBubble } from 'features/chats/hooks'
import {
  Message,
  PostMessageModel,
  useGetChatByIdQuery,
  usePostManagerMutation,
  usePostMessageMutation,
  useResolveIssueMutation,
} from 'features/chats/service'
import { FormattedMessagesData, formatMessagesData, getFormattedTime } from 'features/chats/utils'
import moment from 'moment'
import { useGetUsersQuery } from 'services/users'

import s from './ChatWithStudent.module.css'

import { MessagesSeparator } from './messages-separator/MessagesSeparator'

export const ChatWithStudent = () => {
  const [time, setTime] = useState(new Date().toISOString())
  const [managerId, setManagerId] = useState('')
  const [mentor, setMentor] = useState('')
  const [inputText, setInputText] = useState('')
  const [issueResolveDialog, setIssueResolveDialog] = useState(false)

  const { groupId } = useParams<{ groupId: string }>()

  const navigate = useNavigate()

  const admin = useSelector(selectAdmin)

  const { messages, scrollTrigger, setMessages, setScrollTrigger } = useChats(groupId || '')

  const messagesData: FormattedMessagesData = formatMessagesData(messages, admin.id)

  const {
    data,
    isError,
    isLoading: isMessagesLoading,
  } = useGetChatByIdQuery(
    { groupId: groupId || '', time },
    {
      skip: !groupId,
    }
  )

  const { data: usersData, isLoading: isUsersLoading } = useGetUsersQuery()

  const isLoading = isMessagesLoading || isUsersLoading

  const [sendMessage] = usePostMessageMutation()
  const [addManager] = usePostManagerMutation()
  const [resolveIssue] = useResolveIssueMutation()

  const users = usersData?.items || []

  const options = users.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: String(user.id),
  }))

  const changeInputTextHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value)
  }

  const sendButtonHandler = () => {
    const payload: PostMessageModel = {
      attachments: [
        {
          meta_data: {
            payload: inputText,
            type: 'text',
          },
        },
      ],
      group_id: +groupId!,
      metadata: {},
    }

    sendMessage(payload)
    setInputText('')
  }

  const changeManagerHandler = (value: null | string) => {
    setManagerId(value || '')

    if (value) {
      const user = users.find(user => user.id === +value)

      if (user) {
        const { firstName, id, lastName, nickName } = user
        const addManagerModel = {
          admin: {
            first_name: firstName,
            groupId: groupId || '',
            id,
            last_name: lastName,
            nickname: nickName || '',
          },
        }

        addManager(addManagerModel)
      }
    }
  }

  const dialogConfirmHandler = () => {
    resolveIssue({
      groupId: groupId || '',
      messageId: messagesData.length ? String(messagesData[messagesData.length - 1].id) : '',
    })
    setIssueResolveDialog(false)
  }

  // const disableIssueResolveButton = messages
  //   .filter(message => !message.isManager)
  //   .every(message => message.isRead)

  useInfinityScroll(messages, setTime, setScrollTrigger)

  useScrollToMessageBubble(messages, scrollTrigger)

  useEffect(() => {
    if (data) {
      setMessages((messages: Message[]) => [
        ...data.messages.messages.slice().reverse(),
        ...messages,
      ])

      if (data.group.extra_data.managers) {
        setManagerId(data.group.extra_data.managers[0].id.slice(2))
      }
    }
  }, [data])

  if (isError) {
    navigate(-1)
  }

  if (!admin) {
    return
  }

  return (
    <div>
      <PageHeader
        backButtonText={'Назад к чатам'}
        onGoBack={() => navigate(PATH.CHATS)}
        title={'Чат со студентом'}
      >
        <div className={s.controlsContainer}>
          <div className={s.combobox}>
            <Combobox
              inputValue={mentor}
              label={'Ответственный'}
              onChange={changeManagerHandler}
              onInputChange={setMentor}
              options={options}
              placeholder={'Выберите преподавателя'}
              value={managerId}
            />
          </div>

          <Button
            // disabled={disableIssueResolveButton}
            disabled={!messagesData.length}
            onClick={() => setIssueResolveDialog(true)}
            variant={'secondary'}
          >
            Проблема решена
          </Button>
        </div>
      </PageHeader>

      <Card className={s.chatContainer} contentClassName={s.cardContent}>
        <div className={s.chatHeader}>
          {isLoading ? (
            <Skeleton count={2} height={20} width={350} />
          ) : (
            <>
              <Typography.H3>{`${data?.user.first_name} ${data?.user.last_name}`}</Typography.H3>
              <Typography.Caption>
                Группа:{' '}
                <b>{data?.user.metadata.groups ? data?.user.metadata.groups.join(', ') : '-'}</b>
              </Typography.Caption>
            </>
          )}
        </div>

        <Scrollbar className={s.scrollbar} id={'chat-scrollbar'} type={'hover'}>
          <div className={s.contentContainer}>
            {messagesData.map((message, index) => {
              const formattedTime = getFormattedTime(message.time)

              const isCurrentUser = parseInt(message.user_id.slice(2)) === admin.id

              const isFirst =
                index === 0 ||
                message.isFirstOfDay ||
                message.isReadSeparator ||
                message.isGroupOfMessages ||
                message.isAnotherManager

              return (
                <Fragment key={message.id}>
                  {message.isFirstOfDay && (
                    <MessagesSeparator text={moment(message.time).format('DD.MM.YYYY')} />
                  )}
                  {message.isReadSeparator && <MessagesSeparator text={'Новые сообщения'} />}
                  <MessageBubble
                    className={s.messageBubble}
                    id={`${index + 1}`}
                    isCurrentUser={isCurrentUser}
                    isFirst={isFirst}
                    message={message.attachments[0].metadata.payload}
                    showUsername={'first'}
                    time={formattedTime}
                    username={`${message.first_name} ${message.last_name}`}
                  />
                </Fragment>
              )
            })}
          </div>
        </Scrollbar>
      </Card>

      <MessageInput
        buttonProps={{
          disabled: !inputText.trim(),
          onClick: sendButtonHandler,
        }}
        onChange={changeInputTextHandler}
        placeholder={'Напишите сообщение...'}
        value={inputText}
      />

      <Dialog
        cancelButtonText={'Нет'}
        confirmButtonText={'Да'}
        onCancelButtonClick={() => setIssueResolveDialog(false)}
        onClose={() => setIssueResolveDialog(false)}
        onConfirmButtonClick={dialogConfirmHandler}
        open={issueResolveDialog}
        size={'sm'}
        title={'Проблема решена?'}
      >
        <p className={s.dialogContent}>Вы действительно решили эту проблему?</p>
      </Dialog>

      <LoadingOverlay open={isLoading} />
    </div>
  )
}

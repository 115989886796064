import { CommonColumn } from 'common/components/custom-table/CustomTable'

export const cvTableData: CommonColumn[] = [
  {
    id: 1,
    isSort: true,
    name: 'Имя студента',
    title: 'lastName',
    width: '15%',
  },
  { id: 2, isSort: false, name: 'Курс', title: 'courseId', width: '12%' },
  { id: 3, isSort: false, name: 'Ссылка на CV', title: 'cvLink', width: '150px' },
  { id: 4, isSort: true, name: 'Дата', title: 'cvDate', width: '10%' },
  {
    id: 5,
    isSort: true,
    name: 'Статус',
    title: 'mentorStatus',
    width: '230px',
  },
  {
    id: 6,
    isSort: true,
    name: 'Ментор',
    title: 'mentor',
    width: '12%',
  },
  { id: 7, isSort: true, name: 'Страна', title: 'country', width: '130px' },
  { id: 8, isSort: false, name: 'TelegramID', title: '', width: '200px' },
]

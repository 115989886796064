import { Dispatch, SetStateAction, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { NavLink } from 'react-router-dom'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { IconButton } from '@mui/material'
import { uiActions } from 'app'
import { ConfirmationModal } from 'common/components/confirmation-modal/ConfirmationModal'
import { PATH } from 'common/components/routing/Routing'
import { useActions } from 'common/hooks'
import { Playlist } from 'features/playlists/service'
import { videosTableData } from 'features/videos/data'
import { Video, useDeleteVideoMutation, useUpdateVideoMutation } from 'features/videos/service'
import { Course } from 'services/courses'
import { capitalizeFirstLetter, truncateString } from 'utils'

import s from './VideosTableRow.module.css'
import s2 from 'features/chats/ui/chats-table/chat-table-row/ChatTableRow.module.css'

import { VideosModal } from '../../videos-modal/VideosModal'
import { MaxStudentStartCourseDateCell } from './max-student-start-course-date-cell/MaxStudentStartCourseDateCell'
import { PlaylistCell } from './playlist-cell/PlaylistCell'

type Props = {
  courseId: number | string
  courses?: Course[]
  index: number
  playlistId: string
  playlists?: Playlist[]
  setSettingIds: Dispatch<SetStateAction<string[]>>
  settingIds: string[]
  video: Video
}

export const VideosTableRow = ({
  courseId,
  courses,
  index,
  playlistId,
  playlists,
  setSettingIds,
  settingIds,
  video,
}: Props) => {
  const [showShort, setShowShort] = useState<boolean>(true)
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false)
  const [showUpdateVideoModal, setShowUpdateVideoModal] = useState(false)

  const [updateVideo, { isLoading: isUpdateLoading }] = useUpdateVideoMutation()
  const [deleteVideo, { isLoading: isDeleteLoading }] = useDeleteVideoMutation()

  const { showSnackbar } = useActions(uiActions)

  const isLoading = isUpdateLoading || isDeleteLoading

  const onDeleteOpen = () => setShowDeleteVideoModal(true)
  const onDeleteClose = () => setShowDeleteVideoModal(false)

  const deleteVideoHandler = () => {
    deleteVideo(video.id as string)
      .catch(e => showSnackbar({ message: e.data.message, severity: 'error' }))
      .finally(onDeleteClose)
  }

  const onUpdateOpen = () => setShowUpdateVideoModal(true)
  const onUpdateClose = () => setShowUpdateVideoModal(false)

  const updateVideoCallback = (videoModel: Video) => {
    updateVideo({ video: videoModel, videoId: video.id })
      .unwrap()
      .catch(e => showSnackbar({ message: e.data.message, severity: 'error' }))
      .finally(onUpdateClose)
  }

  const columnCellClassname = `commonCell ${s.column}`

  const withoutSettings = video.settings.length === 0 && '-'

  const settingsShortCount = 1

  const showSwitchButton = video.settings.length > settingsShortCount

  const shortSettings = video.settings.slice(0, 1)

  const settingsForRender = showShort ? shortSettings : video.settings

  const dragNDropButtonDisable = !playlistId || !courseId

  return (
    <Draggable draggableId={video.id || '1'} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {showUpdateVideoModal && (
            <VideosModal
              callback={updateVideoCallback}
              close={onUpdateClose}
              courses={courses}
              defaultValues={video}
              isLoading={isLoading}
              playlists={playlists}
              show={showUpdateVideoModal}
            />
          )}

          {showDeleteVideoModal && (
            <ConfirmationModal
              cancelButtonText={'Нет'}
              okButtonText={'Да'}
              onCancel={onDeleteClose}
              onOk={deleteVideoHandler}
              show={showDeleteVideoModal}
              text={`Вы уверены, что хотите удалить видео "${truncateString(video.name, 18)}"?`}
              title={`Удалить`}
            />
          )}

          <div className={'commonRow'}>
            <div
              className={'commonCell'}
              style={{ alignItems: 'normal', width: videosTableData[0].width }}
            >
              <div className={s.iconContainer}>
                <IconButton
                  edge={'start'}
                  size={'small'}
                  {...provided.dragHandleProps}
                  disabled={dragNDropButtonDisable}
                >
                  <DragIndicatorIcon
                    className={`${s.icon} ${dragNDropButtonDisable ? s.disabled : ''}`}
                  />
                </IconButton>
              </div>
            </div>

            <div
              className={'commonCell'}
              style={{ alignItems: 'normal', width: videosTableData[1].width }}
            >
              <div className={video.hosting === 'vimeo' ? s2.lensActive : s2.lens} />

              <NavLink className={'commonLinkText'} to={`${PATH.VIDEOS}${PATH.WATCH}/${video.id}`}>
                {video.name}
              </NavLink>
            </div>

            <div className={columnCellClassname} style={{ width: videosTableData[2].width }}>
              <PlaylistCell
                playlists={playlists}
                setShowShort={setShowShort}
                settings={settingsForRender}
                showShort={showShort}
                showSwitchButton={showSwitchButton}
              />
              {withoutSettings}
            </div>

            <div className={columnCellClassname} style={{ width: videosTableData[3].width }}>
              {settingsForRender.map(setting => {
                return courses?.map(course => {
                  return (
                    setting.courseId === course.id && (
                      <span className={s.mainContent} key={setting.id}>
                        {capitalizeFirstLetter(course.title)}
                      </span>
                    )
                  )
                })
              })}
              {withoutSettings}
            </div>

            <div className={columnCellClassname} style={{ width: videosTableData[4].width }}>
              <MaxStudentStartCourseDateCell
                setSettingIds={setSettingIds}
                settingIds={settingIds}
                settings={settingsForRender}
              />
              {withoutSettings}
            </div>

            <div className={columnCellClassname} style={{ width: videosTableData[5].width }}>
              {settingsForRender.map(setting => (
                <span
                  className={s.mainContent}
                  key={setting.id}
                >{`${setting.accessLevel} уровень`}</span>
              ))}
              {withoutSettings}
            </div>

            <div className={columnCellClassname} style={{ width: videosTableData[6].width }}>
              {settingsForRender.map(setting => (
                <span
                  className={s.mainContent}
                  key={setting.id}
                >{`${setting.forLesson} урок`}</span>
              ))}
              {withoutSettings}
            </div>

            <div
              className={'commonCell'}
              style={{ alignItems: 'normal', textWrap: 'nowrap', width: videosTableData[7].width }}
            >
              {video.mentor || '-'}
            </div>

            <div
              className={'commonCell'}
              style={{ alignItems: 'normal', width: videosTableData[8].width }}
            >
              {video.startDate ? new Date(video.startDate).toLocaleDateString('ru-RU') : '-'}
            </div>

            <div className={'commonButtonsCell'} style={{ width: videosTableData[9].width }}>
              <IconButton
                aria-label={'rename'}
                disabled={isLoading}
                onClick={onUpdateOpen}
                size={'small'}
              >
                <DriveFileRenameOutlineIcon className={'icon'} />
              </IconButton>

              <IconButton
                aria-label={'delete'}
                disabled={isLoading}
                onClick={onDeleteOpen}
                size={'small'}
              >
                <DeleteForeverIcon className={'icon'} />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

import { Dispatch, SetStateAction } from 'react'

import { Setting } from '@/features/videos/service'
import { Checkbox, Tooltip } from '@it-incubator/ui-kit'
import { lessThanYearLeft } from 'utils'

import s from './MaxStudentStartCourseDateCell.module.css'

type Props = {
  setSettingIds: Dispatch<SetStateAction<string[]>>
  settingIds: string[]
  settings: Setting[]
}

export const MaxStudentStartCourseDateCell = ({ setSettingIds, settingIds, settings }: Props) => {
  const settingIdChangeHandler = (settingId: string) => {
    if (settingIds.includes(settingId)) {
      setSettingIds(settingIds.filter(id => id !== settingId))

      return
    }

    setSettingIds([...settingIds, settingId])
  }

  return (
    <div className={s.container}>
      <div className={s.dateContainer}>
        {settings.map(setting => {
          const minStudentStartCourseDate = new Date(setting.minStudentStartCourseDate)
          const maxStudentStartCourseDate = new Date(setting.maxStudentStartCourseDate)

          const minStartDateString = minStudentStartCourseDate.toLocaleDateString('ru-RU')
          const maxStartDateString = maxStudentStartCourseDate.toLocaleDateString('ru-RU')

          const isLessThanYearLeft = lessThanYearLeft(maxStudentStartCourseDate)
          const redTextClassName = isLessThanYearLeft ? s.redText : ''

          const settingId = setting.id as string
          const selected = settingIds.includes(settingId)

          return (
            <div className={`${s.dateAndCheckboxContainer} ${redTextClassName}`} key={setting.id}>
              <Checkbox checked={selected} onChange={() => settingIdChangeHandler(settingId)} />

              <div>{maxStartDateString}</div>

              <div className={s.tooltip}>
                <Tooltip>
                  <p>{`${minStartDateString} - ${maxStartDateString}`}</p>
                </Tooltip>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

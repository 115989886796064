import { useState } from 'react'

import { Card, Label, Typography } from '@it-incubator/ui-kit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { ThumbUp } from 'assets/icons/adaptive-icons/ThumbUp'
import { format } from 'date-fns'
import { ConfirmDeleteCommentModal } from 'features/comments/ui/comments-table-row/confirm-delete-comment-modal/ConfirmDeleteCommentModal'
import { Like } from 'features/videos/service'

import s from './CommentCard.module.css'

type Props = {
  avatar: string
  comment: string
  commentId: string
  date: string
  firstName: string
  lastName: string
  likes: Like[]
}

export const CommentCard = ({
  avatar,
  comment,
  commentId,
  date,
  firstName,
  lastName,
  likes,
}: Props) => {
  const [showModalId, setShowModalId] = useState('')

  const name = `${firstName} ${lastName}`
  const commentDate = format(new Date(date), 'P')

  return (
    <Card contentClassName={s.review}>
      <div className={s.reviewWrapper}>
        <img alt={'avatar'} className={s.avatar} src={avatar} />

        <div>
          <Typography.Subtitle2 className={s.reviewSubtitle}>
            {name} <Label className={s.reviewTime} label={commentDate} />
          </Typography.Subtitle2>

          <Typography.Body2 className={s.reviewText}>{comment}</Typography.Body2>

          <button className={s.icon}>
            <DeleteIcon onClick={() => setShowModalId(commentId)} />
          </button>
        </div>
      </div>

      <div className={s.likesContainer}>
        <ThumbUp className={s.likeIco} />

        <Typography.Body2 className={s.likesCountText}>{likes.length || 0}</Typography.Body2>
      </div>

      {showModalId === commentId && (
        <ConfirmDeleteCommentModal
          commentId={commentId}
          firstName={firstName}
          lastName={lastName}
          onClose={() => setShowModalId('')}
          open={showModalId === commentId}
        />
      )}
    </Card>
  )
}

import { Dispatch, SetStateAction, useState } from 'react'
import { NavLink } from 'react-router-dom'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { IconButton } from '@mui/material'
import { uiActions } from 'app'
import { ConfirmationModal } from 'common/components/confirmation-modal/ConfirmationModal'
import { PATH } from 'common/components/routing/Routing'
import { useActions } from 'common/hooks'
import { playlistsTableData } from 'features/playlists/data'
import {
  Playlist,
  useDeletePlaylistMutation,
  useUpdatePlaylistMutation,
} from 'features/playlists/service'
import { VideosQueries } from 'features/videos/service'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState, saveState, truncateString } from 'utils'

import { PlaylistModal } from '../playlists-modal/PlaylistModal'
import { CoursesAccessCell } from './courses-access-cell/CoursesAccessCell'
import { MaxStudentStartCourseDateCell } from './max-student-start-course-date-cell/MaxStudentStartCourseDateCell'

type Props = {
  playlist: Playlist
  setSettingIds: Dispatch<SetStateAction<string[]>>
  settingIds: string[]
}

export const PlaylistsTableRow = ({ playlist, setSettingIds, settingIds }: Props) => {
  const [showShort, setShowShort] = useState<boolean>(true)
  const [showDeletePlaylistModal, setShowDeletePlaylistModal] = useState(false)
  const [showUpdatePlaylistModal, setShowUpdatePlaylistModal] = useState(false)

  const [updatePlaylist, { isLoading: isUpdateLoading }] = useUpdatePlaylistMutation()
  const [deletePlaylist, { isLoading: isDeleteLoading }] = useDeletePlaylistMutation()

  const { showSnackbar } = useActions(uiActions)

  const isLoading = isUpdateLoading || isDeleteLoading

  const onDeleteOpen = () => setShowDeletePlaylistModal(true)
  const onDeleteClose = () => setShowDeletePlaylistModal(false)

  const deletePlaylistHandler = () => {
    deletePlaylist(playlist.id as string).catch(e =>
      showSnackbar({ message: e.data.message, severity: 'error' })
    )

    onDeleteClose()
  }

  const onUpdateOpen = () => setShowUpdatePlaylistModal(true)
  const onUpdateClose = () => setShowUpdatePlaylistModal(false)

  const updatePlaylistCallback = (playlistModel: Playlist) => {
    updatePlaylist({ playlist: playlistModel, playlistId: playlist.id })
      .unwrap()
      .catch(e => showSnackbar({ message: e.data.message, severity: 'error' }))
      .finally(onUpdateClose)
  }

  const videosRedirectHandler = () => {
    const videosQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {} as VideosQueries)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {
      ...videosQueries,
      filters: { ...videosQueries.filters, playlist: playlist.id },
      sorting: { ...videosQueries.sorting },
    })
  }

  return (
    <>
      {showUpdatePlaylistModal && (
        <PlaylistModal
          callback={updatePlaylistCallback}
          close={onUpdateClose}
          defName={playlist.name}
          defSettings={playlist.settings}
          show={showUpdatePlaylistModal}
        />
      )}

      {showDeletePlaylistModal && (
        <ConfirmationModal
          cancelButtonText={'Нет'}
          okButtonText={'Да'}
          onCancel={onDeleteClose}
          onOk={deletePlaylistHandler}
          show={showDeletePlaylistModal}
          text={`Вы уверены, что хотите удалить видео "${truncateString(playlist.name, 18)}"?`}
          title={`Удалить`}
        />
      )}

      <div className={'commonRow'}>
        <div
          className={'commonCell'}
          style={{
            alignItems: 'normal',
            textOverflow: 'initial',
            width: playlistsTableData[0].width,
            wordBreak: 'break-word',
          }}
        >
          <NavLink className={'commonLinkText'} onClick={videosRedirectHandler} to={PATH.VIDEOS}>
            {playlist.name}
          </NavLink>
        </div>

        <div
          className={'commonCell'}
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            width: playlistsTableData[1].width,
          }}
        >
          <CoursesAccessCell
            playlist={playlist}
            setShowShort={setShowShort}
            showShort={showShort}
          />
        </div>

        <div
          className={'commonCell'}
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            width: playlistsTableData[2].width,
          }}
        >
          <MaxStudentStartCourseDateCell
            playlist={playlist}
            setSettingIds={setSettingIds}
            settingIds={settingIds}
            showShort={showShort}
          />
        </div>

        <div className={'commonButtonsCell'} style={{ width: playlistsTableData[3].width }}>
          <IconButton className={'icon'} disabled={isLoading} onClick={onUpdateOpen} size={'small'}>
            <DriveFileRenameOutlineIcon />
          </IconButton>

          <IconButton className={'icon'} disabled={isLoading} onClick={onDeleteOpen} size={'small'}>
            <DeleteForeverIcon />
          </IconButton>
        </div>
      </div>
    </>
  )
}

import s from './CustomTableHeader.module.css'

import { CommonColumn } from '../CustomTable'

type Props = {
  arr: CommonColumn[]
}
export const CustomTableHeader = ({ arr }: Props) => {
  return (
    <div className={s.cthBox}>
      {arr.map(el => {
        return (
          <div className={s.cthItem} key={el.id} style={{ whiteSpace: 'nowrap', width: el.width }}>
            {el.name}
          </div>
        )
      })}
    </div>
  )
}

import { FeedbackModel, FeedbackTypeEnum } from 'features/code-review/service'
export const feedbackData: FeedbackModel[] = [
  {
    id: 1,
    title: 'Качество кода',
    type: FeedbackTypeEnum.CODE_QUALITY,
  },
  {
    id: 2,
    title: 'Качество продукта (вёрстка / сваггер дока)',
    type: FeedbackTypeEnum.PRODUCT_QUALITY,
  },
  {
    id: 3,
    title: 'Тесты + (StoryBook по необходимости)',
    type: FeedbackTypeEnum.TESTS,
  },
  {
    id: 4,
    title: 'Инструменты (Eslint, Prettier, Ci/Cd, Docker и т.д.)',
    type: FeedbackTypeEnum.TOOLS,
  },
  {
    id: 5,
    title: 'Общее впечатление о проекте',
    type: FeedbackTypeEnum.GENERAL,
  },
  {
    id: 6,
    score: 1,
    title: 'Информация студенту почему вернули на доработку',
    type: FeedbackTypeEnum.REJECTED,
  },
]

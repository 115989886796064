import { ReactNode, useEffect, useState } from 'react'

import { Scrollbar, Sidebar, clsx } from '@it-incubator/ui-kit'
import { appActions, selectIsDarkTheme, selectUI, uiThunks } from 'app'
import { NavBar } from 'common/components/navbar/NavBar'
import { useActions, useAppSelector } from 'common/hooks'
import { loadNavBarStatus, loadTheme } from 'utils'

import './../../../index.css'

import s from './Layout.module.scss'

type Props = {
  children: ReactNode
}

export const Layout = ({ children }: Props) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme)
  const { isNavBarOpen } = useAppSelector(selectUI)
  const { setNavbar } = useActions(uiThunks)
  const { toggleDarkTheme } = useActions(appActions)
  const [, setWindow] = useState<null | number>(window.innerWidth)

  const handleSidebarClosed = () => {
    setNavbar(false)
  }

  useEffect(() => {
    window.addEventListener('resize', () => setWindow(window.innerWidth))
  }, [window.innerWidth])

  useEffect(() => {
    const theme = loadTheme()
    const isNavBarOpen = loadNavBarStatus()

    if (theme) {
      toggleDarkTheme({ theme })
    }
    if (isNavBarOpen) {
      setNavbar(isNavBarOpen)
    }
  }, [])

  useEffect(() => {
    document.body.classList.remove('darkTheme')
    document.body.classList.remove('lightTheme')
    document.body.classList.add(isDarkTheme ? 'darkTheme' : 'lightTheme')
  }, [isDarkTheme]) //для глобальных css переменных

  return (
    <>
      <Sidebar isOpen={isNavBarOpen} onClose={handleSidebarClosed} style={{ zIndex: '30' }}>
        <NavBar />
      </Sidebar>

      {isNavBarOpen && <div className={s.backBlurBlock} onClick={() => setNavbar(false)} />}

      <Scrollbar className={clsx(s.main, isNavBarOpen && s.open)} id={'scrollbar-root'}>
        {children}
      </Scrollbar>
    </>
  )
}

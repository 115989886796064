import { toast } from 'react-toastify'

import { format } from 'date-fns'
import { instance } from 'services/instances/default'

import { PERS_AREA_ADMIN_TOKEN, restoreState } from '../../../utils'
import { DownloadQuestionnaireArgs, QuestionnaireType } from '../service'

export const downloadQuestionnaireApi = {
  download: async (args: DownloadQuestionnaireArgs, questionnaireType: QuestionnaireType) => {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    return await instance.get(`answers/download/${questionnaireType}`, {
      headers: {
        token,
      },
      params: args,
      responseType: 'blob',
    })
  },
}

export const downloadFile = async (
  args: DownloadQuestionnaireArgs,
  questionnaireType: QuestionnaireType
) => {
  const formattedFromDate = format(args.fromDate || new Date(), 'yyyy-MM-dd')
  const formattedToDate = format(args.toDate || new Date(), 'yyyy-MM-dd')
  const formattedFromDateToFileName = format(args.fromDate || new Date(), 'dd_MM_yyyy')
  const formattedToDateToFileName = format(args.toDate || new Date(), 'dd_MM_yyyy')

  const newQuestionnaireFileName = `new_questionnaire(${formattedFromDateToFileName}-${formattedToDateToFileName})`
  const oldQuestionnaireFileName = 'old_questionnaire'

  try {
    const response = await downloadQuestionnaireApi.download(
      {
        ...args,
        fromDate: questionnaireType === 'new' ? new Date(formattedFromDate) : null,
        toDate: questionnaireType === 'new' ? new Date(formattedToDate) : null,
      },
      questionnaireType
    )

    const href = URL.createObjectURL(response.data)
    const link = document.createElement('a')

    link.href = href
    link.download =
      questionnaireType === 'old' ? oldQuestionnaireFileName : newQuestionnaireFileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    toast.error('Не удалось загрузить файл')
  }
}

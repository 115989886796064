import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { feedbackData } from 'features/code-review/data/feedbackData'
import { FeedbackModel, FeedbackTypeEnum, ReviewStatusType } from 'features/code-review/service'
import { compareArrays } from 'features/code-review/utils'

import { FeedbackItem } from './feedback-item/FeedbackItem'

type Props = {
  defaultFeedbacks: FeedbackModel[]
  onClose: () => void
  open: boolean
  reviewId: number
  reviewStatus: ReviewStatusType
}

export const ReviewModal = ({ defaultFeedbacks, onClose, open, reviewId, reviewStatus }: Props) => {
  const feedbackTypesArr = Object.values(FeedbackTypeEnum)
  const feedbackTypesArrWithoutRejected = feedbackTypesArr.filter(
    type => type !== FeedbackTypeEnum.REJECTED
  )

  const disableAcceptButton = compareArrays(feedbackTypesArrWithoutRejected, defaultFeedbacks)
  const disableRejectButton = compareArrays(feedbackTypesArr, defaultFeedbacks)

  return (
    <CustomModal modalTitle={'Отзыв'} onClose={onClose} open={open}>
      {feedbackData.map(item => {
        const defaultFeedback = defaultFeedbacks.find(feedback => feedback.type === item.type)

        return (
          <FeedbackItem
            defaultFeedback={defaultFeedback}
            disableAcceptButton={disableAcceptButton}
            disableRejectButton={disableRejectButton}
            feedback={item}
            key={item.id}
            onModalClose={onClose}
            reviewId={reviewId}
            reviewStatus={reviewStatus}
          />
        )
      })}
    </CustomModal>
  )
}

import { ReactElement } from 'react'

export type VideosArgs = {
  course?: number | string
  direction?: 'asc' | 'desc'
  page?: number
  pageSize?: number
  playlist?: string
  search?: string
  sort?: string
}

export type VideosResponse = {
  totalCount: number
  videos: Video[]
}

export type Comment = {
  avatar: string
  created: string
  firstName: string
  id: string
  lastName: string
  likes: Like[]
  studentId: number
  text: string
  updated: string
}

export type Like = {
  commentId: string
  created: string
  firstName: string
  id: string
  lastName: string
  studentId: number
  updated: string
}

export type Video = {
  allUnderstandingCount: number
  code: string
  description: string
  failedPresentationCount: number
  firePresentationCount: number
  hardUnderstandingCount: number
  hosting: Hosting
  hostingHash: string
  hostingId: string
  id?: string
  improvedUnderstandingCount: number
  mentor: string
  mentorId: number
  name: string
  normalCount: number
  nothingNewCount: number
  obsoleteCount: number
  order: number
  settings: Setting[]
  startDate: string
  superContentCount: number
  tags: Tag[]
  url: string
}

export type GetVideoByIdRes = {
  comments: Comment[]
  description: string
  hosting: Hosting
  hostingHash: string
  hostingId: string
  id: string
  name: string
  reactions: ReactionType[]
  settings: Setting[]
  tags: Tag[]
  url: string
}

export type Hosting = 'vimeo' | 'vk' | 'youtube'

export type Setting = {
  accessLevel: number
  courseId: number
  forLesson: number
  id?: string
  maxStudentStartCourseDate: number
  minStudentStartCourseDate: number
  playlistId: string
}

export type Tag = {
  id?: string
  text: string
}

export type ReactionType = {
  id: string
  reaction: string
  studentId: number
}

type Sorting = {
  sort: string
}

export type VideosQueries = {
  filters: VideosArgs
  sorting: Sorting
}

export type ChangeVideoOrderBody = {
  courseId: number | string
  order: number
  playlistId: string
  videoId: string
}

export enum SortComments {
  LikesAsc,
  LikesDesc,
  DateAsc,
  DateDesc,
}

export type ReactionsData = {
  countName: keyof Video
  icon: ReactElement
  reaction: Reactions
  title: string
  variant: 'primary' | 'secondary'
}

export enum Reactions {
  AllUnderstanding = 'all_understanding',
  FailedPresentation = 'failed_presentation',
  FirePresentation = 'fire_presentation',
  HardUnderstanding = 'hard_understanding',
  ImprovedUnderstanding = 'improved_understanding',
  Normal = 'normal',
  NothingNew = 'nothing_new',
  Obsolete = 'obsolete',
  SuperContent = 'super_content',
}

export type VideoSettingsState = {
  [key: string]: Setting[]
}

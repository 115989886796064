import { useEffect, useState } from 'react'

import { Spinner } from '@it-incubator/ui-kit'
import { Backdrop, CircularProgress } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { CustomPagination } from 'common/components/custom-pagination/CustomPagination'
import { CustomTable } from 'common/components/custom-table/CustomTable'
import { SortDirection } from 'common/components/custom-table/SortColumn/SortColumn'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { Mood, MoodQueries, useGetUsersMoodsQuery } from 'features/mood/service'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState, saveState } from 'utils'

import s from './MoodsPage.module.css'

import { moodTableData } from '../data'
import { MoodFilters } from './mood-filters/MoodFilters'
import { MoodTableRow } from './mood-table-row/MoodTableRow'

export const MoodsPage = () => {
  const [showMoodId, setShowMoodId] = useState<string>('')
  const [deleteMoodId, setDeleteMoodId] = useState<string>('')
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState<number>(50)
  const [sort, setSort] = useState<string>('0created')
  const [sortColumn, setSortColumn] = useState<string>('created')
  const [sortDirection, setSortDirection] = useState<SortDirection>('0')
  const [lastName, setLastName] = useState<string>('')
  const [mood, setMood] = useState<number | string>('')
  const [programComplexity, setProgramComplexity] = useState<number | string>('')
  const [courseId, setCourseId] = useState<number | string>('')
  const [level, setLevel] = useState<number | string>('')
  const [learningTime, setLearningTime] = useState<number | string>('')

  const debouncedLastName = useDebounce(lastName, 1000)

  const moodQueries: MoodQueries = {
    filters: {
      courseId,
      lastName,
      learningTime,
      level,
      mood,
      programComplexity,
    },
    pagination: {
      page,
      pageCount,
    },
    sorting: {
      sort,
      sortColumn,
      sortDirection,
    },
  }

  const { data, isFetching, isLoading } = useGetUsersMoodsQuery({
    courseId,
    lastName: debouncedLastName,
    learningTime,
    level,
    mood,
    page,
    pageCount,
    programComplexity,
    sort,
  })

  const { data: courses } = useGetCoursesQuery()

  const onChangeSort = (sc: string, sd: SortDirection) => {
    setSortColumn(sc)
    setSortDirection(sd)
    setSort(sd + sc)
    saveState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, {
      ...moodQueries,
      sorting: { ...moodQueries.sorting, sort: sd + sc, sortColumn: sc, sortDirection: sd },
    })
  }

  const onChangePage = (event: any, page: number) => {
    setPage(page)
    saveState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, {
      ...moodQueries,
      pagination: { ...moodQueries.pagination, page },
    })
  }
  const onSelectPageChange = (event: SelectChangeEvent) => {
    setPageCount(Number(event.target.value))
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, {
      ...moodQueries,
      pagination: {
        ...moodQueries.pagination,
        page: FIRST_PAGE_NUMBER,
        pageCount: Number(event.target.value),
      },
    })
  }

  useEffect(() => {
    const restoredMoodQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, moodQueries)
    const { page, pageCount } = restoredMoodQueries.pagination
    const { sort, sortColumn, sortDirection } = restoredMoodQueries.sorting

    setPage(page)
    setPageCount(pageCount)
    setSort(sort)
    setSortColumn(sortColumn)
    setSortDirection(sortDirection)
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <PageHeader title={'Настроения'} />

      <MoodFilters
        courseId={courseId}
        courses={courses?.items}
        lastName={lastName}
        learningTime={learningTime}
        level={level}
        mood={mood}
        moodQueries={moodQueries}
        programComplexity={programComplexity}
        setCourseId={setCourseId}
        setLastName={setLastName}
        setLearningTime={setLearningTime}
        setLevel={setLevel}
        setMood={setMood}
        setPage={setPage}
        setProgramComplexity={setProgramComplexity}
        setSort={setSort}
        setSortColumn={setSortColumn}
        setSortDirection={setSortDirection}
      />

      <div className={s.containerTable}>
        <CustomTable
          onChange={onChangeSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          tableData={moodTableData}
        >
          {data?.moods.map((mood: Mood) => {
            return (
              <MoodTableRow
                courses={courses?.items}
                deleteMoodId={deleteMoodId}
                key={mood._id}
                mood={mood}
                onDeleteMoodId={setDeleteMoodId}
                onShowMoodId={setShowMoodId}
                showMoodId={showMoodId}
              />
            )
          })}
        </CustomTable>
      </div>

      <Backdrop
        open={isFetching || isLoading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color={'inherit'} />
      </Backdrop>

      <CustomPagination
        count={Math.ceil((data?.totalCount ?? 1) / pageCount)}
        disabled={isLoading || isFetching}
        itemForPageCount={pageCount}
        itemName={''}
        onChange={onChangePage}
        onSelectChange={onSelectPageChange}
        pageNumber={page}
      />
    </div>
  )
}

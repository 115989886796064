import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { TextField } from '@mui/material'

import s from '../WorkItem.module.css'

type Props = {
  company: string
  editMode: boolean
  setCompany: Dispatch<SetStateAction<string>>
}

export const Company = ({ company, editMode, setCompany }: Props) => {
  const companyChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCompany(event.currentTarget.value)
  }

  return (
    <div>
      {editMode ? (
        <TextField
          fullWidth
          label={'Компания'}
          onChange={companyChangeHandler}
          sx={{ marginBottom: '5px' }}
          value={company}
        />
      ) : (
        <span className={s.textSecondary}>{company}</span>
      )}
    </div>
  )
}

import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { TextField } from '@mui/material'

type Props = {
  editMode: boolean
  setSpeciality: Dispatch<SetStateAction<string>>
  speciality: string
}

export const Speciality = ({ editMode, setSpeciality, speciality }: Props) => {
  const specialityChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSpeciality(event.currentTarget.value)
  }

  return (
    <span>
      {editMode ? (
        <TextField
          label={'Специальность'}
          onChange={specialityChangeHandler}
          sx={{ marginBottom: '5px', marginLeft: '10px' }}
          value={speciality}
        />
      ) : (
        <span>{speciality}</span>
      )}
    </span>
  )
}

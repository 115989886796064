import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { UsersMoodsArgs, UsersMoodsResponse } from '../service'

export const moodsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    deleteUserMood: build.mutation({
      invalidatesTags: () => ['usersMoods'],
      query: moodId => {
        return {
          method: 'DELETE',
          url: '/mood/' + moodId,
        }
      },
    }),
    getUsersMoods: build.query<UsersMoodsResponse, UsersMoodsArgs>({
      providesTags: () => ['usersMoods'],
      query: ({
        courseId,
        lastName,
        learningTime,
        level,
        mood,
        page,
        pageCount,
        programComplexity,
        sort,
      }) => {
        return {
          params: {
            courseId,
            lastName,
            learningTime,
            level,
            mood,
            page,
            pageCount,
            programComplexity,
            sort,
          },
          url: '/mood/users',
        }
      },
    }),
  }),
  reducerPath: 'moodsService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['usersMoods'],
})

export const { useDeleteUserMoodMutation, useGetUsersMoodsQuery } = moodsService

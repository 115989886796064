import { useEffect, useState } from 'react'

import { Select, Typography } from '@it-incubator/ui-kit'
import { Comment, SortComments } from 'features/videos/service'
import { getSortedComments } from 'features/videos/utils'

import s from './Comments.module.css'

import { CommentCard } from './comment-card/CommentCard'

type Props = {
  videoComments?: Comment[]
}

export const Comments = ({ videoComments }: Props) => {
  const [comments, setComments] = useState(videoComments || [])
  const [sort, setSort] = useState(SortComments.LikesDesc)

  const commentsCount = videoComments?.length || 0

  const sortOptions = [
    { label: 'По возрастанию лайков', value: SortComments.LikesAsc },
    { label: 'По убыванию лайков', value: SortComments.LikesDesc },
    { label: 'По дате свежие', value: SortComments.DateDesc },
    { label: 'По дате старые', value: SortComments.DateAsc },
  ]

  const sorted = getSortedComments(comments, sort)

  const handleSortChange = (value: SortComments) => {
    setSort(value)
  }

  useEffect(() => {
    setComments(videoComments || [])
  }, [videoComments])

  return (
    <div className={s.commentsContainer}>
      <div className={s.sortBlock}>
        <Typography.Subtitle2 className={s.videoCommentsTitle}>
          Комментарии ({commentsCount})
        </Typography.Subtitle2>

        <div className={s.sortContainer}>
          <Typography.Body1>Сортировать:</Typography.Body1>

          <div>
            <Select
              className={s.sortSelect}
              onChange={handleSortChange}
              options={sortOptions}
              value={sort}
              variant={'secondary'}
            />
          </div>
        </div>
      </div>

      <div className={s.commentsBlock}>
        {sorted.map((comment: Comment) => (
          <CommentCard
            avatar={comment.avatar}
            comment={comment.text}
            commentId={comment.id}
            date={comment.created}
            firstName={comment.firstName}
            key={comment.id}
            lastName={comment.lastName}
            likes={comment.likes}
          />
        ))}
      </div>
    </div>
  )
}

import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { Select, TextField } from '@it-incubator/ui-kit'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { Admin, Option } from 'common/types'
import { CvQueries, MentorStatus, MentorStatusEnum } from 'features/cv/service'
import { Course } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, getCoursesSelectOptions, saveState } from 'utils'

import s from './CvFilters.module.css'

type Props = {
  admin?: Admin
  courseId: number | string
  courses: Course[]
  cvMentor: string
  cvQueries: CvQueries
  lastName: string
  mentorStatus: MentorStatus
  setCourseId: Dispatch<SetStateAction<number | string>>
  setCvMentor: Dispatch<SetStateAction<string>>
  setLastName: Dispatch<SetStateAction<string>>
  setMentorStatus: Dispatch<SetStateAction<MentorStatus>>
  setPage: Dispatch<SetStateAction<number>>
}

export const CvFilters = ({
  admin,
  courseId,
  courses,
  cvMentor,
  cvQueries,
  lastName,
  mentorStatus,
  setCourseId,
  setCvMentor,
  setLastName,
  setMentorStatus,
  setPage,
}: Props) => {
  const statusSelectOptions: Option[] = [
    { label: 'Кроме принятых', value: MentorStatusEnum.WORK },
    { label: 'Любой статус', value: '' },
    { label: 'Ожидание', value: MentorStatusEnum.AWAIT },
    { label: 'В работе', value: MentorStatusEnum.IN_WORK },
    { label: 'Перепроверка', value: MentorStatusEnum.REVIEW },
    { label: 'Жду ответ', value: MentorStatusEnum.NOT_DONE },
    { label: 'Принято', value: MentorStatusEnum.DONE },
  ]

  const mentorSelectOptions: Option[] = [
    { label: 'Любой ментор', value: '' },
    { label: 'я', value: admin?.name as string },
  ]

  const startCoursesSelectOptions: Option[] = [{ label: 'Все', value: '' }]
  const coursesSelectOptions = getCoursesSelectOptions(courses, startCoursesSelectOptions)

  const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lastName = e.target.value

    setLastName(lastName)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CV, {
      ...cvQueries,
      filters: { ...cvQueries.filters, lastName },
    })
  }

  const onClearClick = () => {
    setLastName('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CV, {
      ...cvQueries,
      filters: { ...cvQueries.filters, lastName: '' },
    })
  }

  const onStatusChange = (newStatus: string) => {
    const mentorStatus = newStatus as MentorStatus

    setMentorStatus(mentorStatus)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CV, {
      ...cvQueries,
      filters: { ...cvQueries.filters, mentorStatus },
    })
  }

  const onMentorChange = (cvMentor: string) => {
    setCvMentor(cvMentor)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CV, {
      ...cvQueries,
      filters: { ...cvQueries.filters, cvMentor },
    })
  }

  const onCourseIdChange = (value: string) => {
    setCourseId(value)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CV, {
      ...cvQueries,
      filters: { ...cvQueries.filters, courseId: value },
    })
  }

  return (
    <div>
      <TextField
        className={s.search}
        onChange={onLastNameChange}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={lastName}
      />

      <div className={s.selectsContainer}>
        <Select
          label={'Статус'}
          onChange={onStatusChange}
          options={statusSelectOptions}
          value={mentorStatus}
          width={300}
        />

        <Select
          label={'Ментор'}
          onChange={onMentorChange}
          options={mentorSelectOptions}
          value={cvMentor}
          width={300}
        />

        <Select
          label={'Курс'}
          onChange={onCourseIdChange}
          options={coursesSelectOptions}
          value={courseId + ''}
          width={300}
        />
      </div>
    </div>
  )
}

/* eslint-disable no-nested-ternary */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import s from './SortColumn.module.css'

export type SortDirection = '' | '0' | '1'

type SortColumnProps = {
  onChange: (sc: string, sd: SortDirection) => void
  sortColumn: string
  sortDirection: string
  sortName: string
}

export const SortColumn = ({ onChange, sortColumn, sortDirection, sortName }: SortColumnProps) => {
  const changeDirectionHandler = () => {
    let d: SortDirection = ''

    if (sortName !== sortColumn) {
      d = '1'
    } else if (sortDirection === '1') {
      d = '0'
    }

    onChange(d ? sortName : '', d)
  }

  return (
    <div className={s.scBox} onClick={changeDirectionHandler}>
      {sortName === sortColumn ? (
        sortDirection === '1' ? (
          <ArrowDropUpIcon className={s.scIcon} />
        ) : sortDirection === '0' ? (
          <ArrowDropDownIcon className={s.scIcon} />
        ) : null
      ) : null}
    </div>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { AdminCvsArgs, AdminCvsResponse } from '../service'

export const cvService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    changeCvStatus: build.mutation({
      invalidatesTags: () => ['cv'],
      query: ({ mentorStatus, studentId }) => {
        return {
          body: { mentorStatus, studentId },
          method: 'PUT',
          url: 'auth/cv/change-status',
        }
      },
    }),
    getAllCvs: build.query<AdminCvsResponse, AdminCvsArgs>({
      providesTags: () => ['cv'],
      query: ({
        courseId,
        cvMentor,
        itemForPageCount,
        lastName,
        mentorStatus,
        pageNumber,
        sort,
      }) => {
        return {
          params: {
            courseId,
            cvMentor,
            itemForPageCount,
            lastName,
            mentorStatus,
            pageNumber,
            sort,
          },
          url: 'auth/cv/for-admin',
        }
      },
    }),
  }),
  reducerPath: 'cvService',
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  tagTypes: ['cv'],
})

export const { useChangeCvStatusMutation, useGetAllCvsQuery } = cvService

import { Dispatch, SetStateAction } from 'react'

import { Button, Combobox, Select, TextField } from '@it-incubator/ui-kit'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { Playlist } from 'features/playlists/service'
import { VideosQueries } from 'features/videos/service'
import { Course } from 'services/courses'

import s from './VideosHeader.module.css'

import { VideosModal } from '../videos-modal/VideosModal'
import { useVideosHeader } from './hooks'

type Props = {
  courseId: number | string
  courses?: Course[]
  playlistId: string
  playlists?: Playlist[]
  search: string
  setCourseId: Dispatch<SetStateAction<number | string>>
  setPage: Dispatch<SetStateAction<number>>
  setPlaylistId: Dispatch<SetStateAction<string>>
  setSearch: Dispatch<SetStateAction<string>>
  setSort: Dispatch<SetStateAction<string>>
  sort: string
  videosQueries: VideosQueries
}

export const VideosHeader = ({
  courseId,
  courses,
  playlistId,
  playlists,
  search,
  setCourseId,
  setPage,
  setPlaylistId,
  setSearch,
  setSort,
  sort,
  videosQueries,
}: Props) => {
  const {
    addVideoCallback,
    coursesSelectOptions,
    isLoading,
    onChangeCourseId,
    onChangePlaylistId,
    onChangeSearch,
    onChangeSortReaction,
    onClearClick,
    onClose,
    onOpen,
    playlistsSelectOptions,
    reactionsSelectOptions,
    searchPlaylistTerm,
    setSearchPlaylistTerm,
    showAddVideoModal,
  } = useVideosHeader(
    setPage,
    setSearch,
    setPlaylistId,
    setCourseId,
    setSort,
    videosQueries,
    playlists ?? [],
    courses ?? []
  )

  return (
    <div className={s.container}>
      {showAddVideoModal && (
        <VideosModal
          callback={addVideoCallback}
          close={onClose}
          courses={courses}
          isLoading={isLoading}
          playlists={playlists}
          show={showAddVideoModal}
        />
      )}

      <div className={s.headerContainer}>
        <PageHeader title={'Видео'} />

        <Button disabled={isLoading} onClick={onOpen}>
          + Добавить видео
        </Button>
      </div>

      <TextField
        onChange={onChangeSearch}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <div className={s.filtersContainer}>
        <div className={s.combobox}>
          <Combobox
            inputValue={searchPlaylistTerm}
            label={'Плейлисты'}
            onChange={onChangePlaylistId}
            onInputChange={setSearchPlaylistTerm}
            options={playlistsSelectOptions}
            placeholder={'Плейлист не выбран'}
            value={playlistId}
          />
        </div>

        <Select
          label={'Курс'}
          onChange={onChangeCourseId}
          options={coursesSelectOptions}
          value={courseId + ''}
          width={250}
        />

        <Select
          label={'Реакция'}
          onChange={onChangeSortReaction}
          options={reactionsSelectOptions}
          value={sort}
          width={350}
        />
      </div>
    </div>
  )
}

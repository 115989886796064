import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button, Card, Select, Tag, TextField, Typography } from '@it-incubator/ui-kit'
import { Screen } from 'common/api/upload-file-api'
import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { PhotoUploader } from 'common/components/photo-uploader/PhotoUploader'
import { SANITIZE_HTML_OPTIONS } from 'common/constants'
import { useCreateFaqMutation, useUpdateFaqMutation } from 'features/faq/service'
import { Faq, FaqModel } from 'features/faq/service/types'
import sanitizeHtml from 'sanitize-html'
import { Course } from 'services/courses'
import SunEditor, { buttonList } from 'suneditor-react'
import { getCoursesSelectOptions } from 'utils'

import s from './QuestionModal.module.css'

type Props = {
  courses: Course[]
  defaultFaq?: Faq
  onClose: () => void
  open: boolean
}

export const QuestionModal = ({ courses, defaultFaq, onClose, open }: Props) => {
  const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([])
  const [screenshots, setScreenshots] = useState<Screen[]>([])

  const [createFaq, { isLoading: isCreateFaqLoading }] = useCreateFaqMutation()
  const [updateFaq, { isLoading: isUpdateFaqLoading }] = useUpdateFaqMutation()

  const isLoading = isCreateFaqLoading || isUpdateFaqLoading

  const { control, handleSubmit, reset } = useForm<FaqModel>()

  const coursesSelectOptions = getCoursesSelectOptions(courses, [])

  const onSubmit = (faq: FaqModel) => {
    const faqModel: FaqModel = {
      ...faq,
      priority: Number(faq.priority),
    }

    const faqMutation = defaultFaq
      ? updateFaq({ faqModel, id: defaultFaq.id })
      : createFaq(faqModel)

    faqMutation
      .unwrap()
      .then(() => toast.success('Вопрос успешно сохранен'))
      .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
      .finally(() => {
        onClose()
        reset()
      })
  }

  useEffect(() => {
    if (defaultFaq) {
      reset(defaultFaq)
    }
  }, [defaultFaq])

  return (
    <CustomModal
      modalTitle={`${defaultFaq ? 'Редактировать' : 'Добавить'} вопрос`}
      onClose={onClose}
      open={open}
      width={800}
    >
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue={''}
          name={'title'}
          render={({ field, fieldState }) => (
            <TextField
              errorMessage={
                fieldState.error ? 'Минимальная длина - 10 символов, максимальная - 500' : undefined
              }
              label={'Название вопроса*'}
              placeholder={'Напишите название вопроса'}
              required
              {...field}
            />
          )}
          rules={{ maxLength: 500, minLength: 10 }}
        />

        <Controller
          control={control}
          defaultValue={[]}
          name={'forCourses'}
          render={({ field }) => {
            return (
              <Select
                label={'Курс'}
                multiple
                options={coursesSelectOptions}
                placeholder={'Курс не выбран'}
                {...field}
                onChange={(idsArr: number[]) => {
                  setSelectedTagsIds(idsArr)

                  field.onChange(idsArr)
                }}
              />
            )
          }}
        />

        {selectedTagsIds.length !== 0 && (
          <div className={s.tagsContainer}>
            {selectedTagsIds.map(id => {
              return coursesSelectOptions.map(
                course =>
                  id === course.value && <Tag key={course.value} readonly>{`#${course.label}`}</Tag>
              )
            })}
          </div>
        )}

        <Controller
          control={control}
          defaultValue={0}
          name={'priority'}
          render={({ field }) => (
            <TextField
              label={'Приоритет'}
              required
              style={{ width: '150px' }}
              type={'number'}
              {...field}
            />
          )}
        />

        <Card>
          <PhotoUploader
            editMode
            screenshots={screenshots}
            setScreenshots={setScreenshots}
            style={{ marginBottom: '36px' }}
            uploadFor={'ответа'}
          />

          <Controller
            control={control}
            defaultValue={''}
            name={'content'}
            render={({ field, fieldState }) => (
              <div>
                {fieldState.error && <Typography.Error>Ошибка. Проверьте длину</Typography.Error>}

                <SunEditor
                  {...field}
                  onChange={event => field.onChange(sanitizeHtml(event, SANITIZE_HTML_OPTIONS))}
                  setContents={field.value}
                  setDefaultStyle={'p {margin: 0px;}'}
                  setOptions={{
                    buttonList: [...buttonList.complex, ['textStyle']],
                    height: '300px',
                  }}
                />
              </div>
            )}
            rules={{ maxLength: 10000, minLength: 10, required: true }}
          />
        </Card>

        <Button className={s.button} disabled={isLoading} type={'submit'}>
          Сохранить изменения
        </Button>
      </form>
    </CustomModal>
  )
}

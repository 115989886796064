import { Grid, InputLabel, MenuItem, OutlinedInput } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ru } from 'date-fns/locale/ru'
import { PlaylistType } from 'features/playlists/service'
import { MAX_LEVEL, renderMenuItems } from 'utils/renderMenuItems'

type Props = {
  changeEndDate: (event: null | number) => void
  changeLevelAccess: (event: SelectChangeEvent<number>) => void
  changePlaylistType: (event: SelectChangeEvent<PlaylistType>) => void
  changeStartDate: (event: null | number) => void
  endDate: number
  levelAccess: number
  playlistType: PlaylistType
  startDate: number
}

export const PlaylistSettings = ({
  changeEndDate,
  changeLevelAccess,
  changePlaylistType,
  changeStartDate,
  endDate,
  levelAccess,
  playlistType,
  startDate,
}: Props) => {
  return (
    <>
      <Grid marginBottom={2} marginTop={2}>
        <FormControl fullWidth variant={'outlined'}>
          <InputLabel>С какого уровня плейлист доступен</InputLabel>
          <Select
            label={'С какого уровня плейлист доступен'}
            onChange={changeLevelAccess}
            value={levelAccess}
          >
            {renderMenuItems(MAX_LEVEL, 'уровень')}
          </Select>
        </FormControl>
      </Grid>

      <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
        <Grid container flexDirection={'column'}>
          <Grid container flexDirection={'row'} justifyContent={'space-between'} marginBottom={2}>
            <Grid width={'45%'}>
              <DatePicker label={'Для студентов с'} onChange={changeStartDate} value={startDate} />
            </Grid>

            <Grid width={'45%'}>
              <DatePicker label={'Для студентов до'} onChange={changeEndDate} value={endDate} />
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Grid marginBottom={2}>
        <FormControl fullWidth variant={'outlined'}>
          <InputLabel shrink>Тип плейлиста</InputLabel>
          <Select
            displayEmpty
            input={<OutlinedInput label={'Тип плейлиста'} notched />}
            onChange={changePlaylistType}
            value={playlistType}
          >
            <MenuItem value={''}>Не выбрано</MenuItem>
            <MenuItem value={'main'}>Основной видеоматериал</MenuItem>
            <MenuItem value={'online'}>Онлайн занятия</MenuItem>
            <MenuItem value={'additional'}>Дополнительный видеоматериал</MenuItem>
            <MenuItem value={'other'}>Другое</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}

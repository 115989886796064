import { HTMLAttributes } from 'react'

import s from './Center.module.css'

export const Center = ({ children, className, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`${s.center} ${className}`} {...rest}>
      {children}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Pagination } from '@it-incubator/ui-kit'
import { Backdrop, CircularProgress } from '@mui/material'
import { CustomTable } from 'common/components/custom-table/CustomTable'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { playlistsTableData } from 'features/playlists/data'
import {
  PlaylistsQueries,
  useAddDaysForPlaylistsSettingsMutation,
  useGetAllPlaylistsQuery,
} from 'features/playlists/service'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState } from 'utils'

import s from './Playlists.module.css'

import { PlaylistsHeader } from './playlists-header/PlaylistsHeader'
import { PlaylistsTableRow } from './playlists-table-row/PlaylistsTableRow'

export const Playlists = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [search, setSearch] = useState('')
  const [courseId, setCourseId] = useState('')
  const [settingIds, setSettingIds] = useState<string[]>([])

  const debouncedSearch = useDebounce(search, 1000)

  const playlistsQueries: PlaylistsQueries = {
    filters: {
      search,
    },
  }

  const {
    data,
    isFetching,
    isLoading: isPlaylistsLoading,
  } = useGetAllPlaylistsQuery({
    courseId,
    page,
    pageSize,
    search: debouncedSearch,
  })

  const [addDaysForPlaylistsSettings, { isLoading: isAddDaysLoading }] =
    useAddDaysForPlaylistsSettingsMutation()

  const { data: courses, isLoading: isCoursesLoading } = useGetCoursesQuery()

  const isLoading = isPlaylistsLoading || isAddDaysLoading || isCoursesLoading

  const addDaysHandler = () => {
    addDaysForPlaylistsSettings(settingIds)
      .unwrap()
      .then(() => {
        toast.success('Выбранные даты успешно продлены')
        setSettingIds([])
      })
      .catch(() => toast.error('Произошла ошибка'))
  }

  const perPageChangeHandler = (itemPerPage: number) => {
    setPageSize(itemPerPage)
    setPage(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredPlaylistsQueries = restoreState(
      LOCAL_STORAGE_QUERIES_KEYS.PLAYLISTS,
      playlistsQueries
    )
    const { courseId, search } = restoredPlaylistsQueries.filters

    if (search) {
      setSearch(search)
    }
    if (courseId) {
      setCourseId(courseId)
    }
  }, [])

  return (
    <div>
      <PlaylistsHeader
        courseId={courseId}
        courses={courses?.items || []}
        playlistsQueries={playlistsQueries}
        search={search}
        setCourseId={setCourseId}
        setPage={setPage}
        setSearch={setSearch}
      />

      <CustomTable
        onChange={() => {}}
        sortColumn={''}
        sortDirection={''}
        tableData={playlistsTableData}
      >
        {data?.playlists.map(playlist => (
          <PlaylistsTableRow
            key={playlist.id}
            playlist={playlist}
            setSettingIds={setSettingIds}
            settingIds={settingIds}
          />
        ))}
      </CustomTable>

      <Backdrop
        open={isFetching || isLoading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color={'inherit'} />
      </Backdrop>

      <div className={s.footerContainer}>
        <Pagination
          count={Math.ceil((data?.totalCount ?? 1) / pageSize)}
          onChange={setPage}
          onPerPageChange={perPageChangeHandler}
          page={page}
          perPage={pageSize}
          perPageOptions={[10, 20, 50, 100]}
        />

        <Button
          disabled={settingIds.length === 0 || isLoading}
          onClick={addDaysHandler}
          variant={'secondary'}
        >
          Продлить выбранные даты
        </Button>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'

import { Backdrop, CircularProgress } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { CustomPagination } from 'common/components/custom-pagination/CustomPagination'
import { CustomTable } from 'common/components/custom-table/CustomTable'
import { SortDirection } from 'common/components/custom-table/SortColumn/SortColumn'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { cvTableData } from 'features/cv/data'
import { CV, CvQueries, MentorStatus, useGetAllCvsQuery } from 'features/cv/service'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState, saveState } from 'utils'

import { CvFilters } from './cv-filters/CvFilters'
import { CvTableRow } from './cv-table-row/CvTableRow'

export const Cv = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [itemForPageCount, setItemForPageCount] = useState(50)
  const [sort, setSort] = useState('0created')
  const [sortColumn, setSortColumn] = useState('created')
  const [sortDirection, setSortDirection] = useState<SortDirection>('0')
  const [lastName, setLastName] = useState('')
  const [cvMentor, setCvMentor] = useState('')
  const [mentorStatus, setMentorStatus] = useState<MentorStatus>('work')
  const [courseId, setCourseId] = useState<number | string>('')

  const debouncedLastName = useDebounce(lastName, 1000)

  const cvQueries: CvQueries = {
    filters: {
      courseId,
      cvMentor,
      lastName,
      mentorStatus,
    },
    sorting: {
      sort,
      sortColumn,
      sortDirection,
    },
  }

  const { data, isLoading } = useGetAllCvsQuery({
    courseId,
    cvMentor,
    itemForPageCount,
    lastName: debouncedLastName,
    mentorStatus,
    pageNumber,
    sort,
  })

  const { data: coursesData } = useGetCoursesQuery()

  const courses = coursesData?.items ?? []

  const onChangeSort = (sc: string, sd: SortDirection) => {
    setSortColumn(sc)
    setSortDirection(sd)
    setSort(sd + sc)
    saveState(LOCAL_STORAGE_QUERIES_KEYS.CV, {
      ...cvQueries,
      sorting: { ...cvQueries.sorting, sort: sd + sc, sortColumn: sc, sortDirection: sd },
    })
  }

  const onPageChange = (event: any, page: number) => setPageNumber(page)
  const onSelectPageChange = (event: SelectChangeEvent) => {
    setItemForPageCount(Number(event.target.value))
    setPageNumber(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredCvQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.CV, cvQueries)
    const { courseId, cvMentor, lastName, mentorStatus } = restoredCvQueries.filters
    const { sort, sortColumn, sortDirection } = restoredCvQueries.sorting

    setLastName(lastName)
    setMentorStatus(mentorStatus)
    setCvMentor(cvMentor)
    setCourseId(courseId + '')
    setSortColumn(sortColumn)
    setSortDirection(sortDirection)
    setSort(sort)
  }, [])

  return (
    <div>
      <PageHeader title={'Список CV'} />

      <CvFilters
        admin={data?.admin}
        courseId={courseId}
        courses={courses}
        cvMentor={cvMentor}
        cvQueries={cvQueries}
        lastName={lastName}
        mentorStatus={mentorStatus}
        setCourseId={setCourseId}
        setCvMentor={setCvMentor}
        setLastName={setLastName}
        setMentorStatus={setMentorStatus}
        setPage={setPageNumber}
      />

      <CustomTable
        onChange={onChangeSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        tableData={cvTableData}
      >
        {data?.cvs.map((cv: CV) => (
          <CvTableRow admin={data?.admin} courses={courses} cv={cv} key={cv._id} />
        ))}
      </CustomTable>

      <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
        <CircularProgress color={'inherit'} />
      </Backdrop>

      <CustomPagination
        count={Math.ceil((data?.totalCount ?? 1) / itemForPageCount)}
        itemForPageCount={itemForPageCount}
        itemName={'CV'}
        onChange={onPageChange}
        onSelectChange={onSelectPageChange}
        pageNumber={pageNumber}
      />
    </div>
  )
}

import { Dispatch, MouseEvent, useState } from 'react'

import { TextField } from '@it-incubator/ui-kit'
import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { SelectChangeEvent } from '@mui/material/Select'
import { SuperCheckbox } from 'common/components/custom-checkbox/SuperCheckbox'
import { Playlist } from 'features/playlists/service'
import { VideoSettingsState } from 'features/videos/service'

import s from './PlaylistsSelect.module.css'

type Props = {
  playlists?: Playlist[]
  selectedPlaylistsIds: string[]
  setSelectedPlaylistsIds: Dispatch<string[]>
  setTabValue: Dispatch<boolean | string>
  setVideoSettingsState: Dispatch<VideoSettingsState>
  videoSettingsState: VideoSettingsState
}

export const PlaylistsSelect = ({
  playlists,
  selectedPlaylistsIds,
  setSelectedPlaylistsIds,
  setTabValue,
  setVideoSettingsState,
  videoSettingsState,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const onSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    if (typeof value === 'string') {
      setSelectedPlaylistsIds(value.split(','))
    } else {
      const refreshedVideoSettingsState = value.reduce((target, key) => {
        if (videoSettingsState[key]) {
          target[key] = [...videoSettingsState[key]]
        } else {
          target[key] = []
        }

        return target
      }, {} as VideoSettingsState)

      setSelectedPlaylistsIds(value)
      setVideoSettingsState(refreshedVideoSettingsState)
    }
  }

  const onSelectOpen = () => {
    setOpen(true)

    setTabValue(false)
  }

  const onSelectClose = () => {
    setOpen(false)

    setTabValue(selectedPlaylistsIds[selectedPlaylistsIds.length - 1])
  }

  const selectRenderValue = (selected: string[]) => {
    if (selectedPlaylistsIds.length === 0) {
      return 'Выберите плейлисты'
    }

    return selected
      .map(id => playlists?.filter(playlist => id === playlist.id).map(playlist => playlist.name))
      .join(', ')
  }

  const searchedAll = () => {
    const lowerCaseSearch = search.toLowerCase()

    return playlists?.filter(playlist => {
      return playlist.name.toLowerCase().includes(lowerCaseSearch)
    })
  }

  const searched = searchedAll()

  const mappedMenuItems = searched?.map(playlist => (
    <MenuItem key={playlist.id} style={{ width: '800px' }} value={playlist.id}>
      <SuperCheckbox checked={selectedPlaylistsIds.indexOf(playlist.id as string) > -1} />
      <ListItemText primary={playlist.name} />
    </MenuItem>
  ))

  // https://github.com/mui/material-ui/issues/17775
  const stopImmediatePropagation = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <FormControl fullWidth variant={'outlined'}>
      <InputLabel shrink>Плейлисты в которые добавить видео *</InputLabel>
      <Select
        MenuProps={{
          PaperProps: {
            style: {
              height: '100vh',
              width: '800px',
            },
          },
        }}
        displayEmpty
        input={<OutlinedInput label={'Плейлисты в которые добавить видео *'} notched />}
        multiple
        onChange={onSelectChange}
        onClose={onSelectClose}
        onOpen={onSelectOpen}
        open={open}
        renderValue={selectRenderValue}
        required
        value={selectedPlaylistsIds}
      >
        <div className={s.menuHeaderContainer}>
          <TextField
            onChange={e => setSearch(e.target.value)}
            onClearClick={() => setSearch('')}
            onClickCapture={stopImmediatePropagation}
            onKeyDown={e => e.stopPropagation()}
            placeholder={'Поиск...'}
            search
            value={search}
          />

          <IconButton onClick={onSelectClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {mappedMenuItems}
      </Select>
    </FormControl>
  )
}

import { z } from 'zod'

import packageJson from '../package.json'
import { EnvironmentsTypes, appSettings } from './settings/app-settings'

const ApiSettingsSchema = z
  .object({
    REACT_APP_BASE_URL: z.string().url(),
    REACT_APP_LOGIN_URL: z.string().url(),
    REACT_APP_SAMURAI_HUB_BACK_URL: z.string().url(),
    REACT_APP_SAMURAI_HUB_MESSENGER: z.string().url(),
    REACT_APP_VIDEO_URL: z.string().url(),
  })
  .strict()

const NodeEnvSchema = z.union([
  z.literal(EnvironmentsTypes.PRODUCTION),
  z.literal(EnvironmentsTypes.DEVELOPMENT),
  z.literal(EnvironmentsTypes.STAGING),
])

const EnvironmentSettingsSchema = z
  .object({
    env: NodeEnvSchema,
    getEnv: NodeEnvSchema,
    isDevelopment: z.boolean(),
    isProduction: z.boolean(),
    isStaging: z.boolean(),
  })
  .strict()

const AppSettingsSchema = z
  .object({
    api: ApiSettingsSchema,
    env: EnvironmentSettingsSchema,
  })
  .strict()

export const zodValidation = () => {
  try {
    AppSettingsSchema.parse(appSettings)
  } catch (error) {
    console.error(`Ошибка конфигурации ${packageJson.name}: `, error)
    throw error
  }
}

zodValidation()

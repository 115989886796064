import { ChangeEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Button, Grid, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ru } from 'date-fns/locale/ru'
import { Playlist } from 'features/playlists/service'
import { Video, VideoSettingsState } from 'features/videos/service'
import { restoreVideoSettingsState } from 'features/videos/utils'
import { Course } from 'services/courses'
import { generateVideoUrlFromId, parseVideoUrl } from 'utils/parseVideoUrl'

import s from './VideoMainForm.module.css'

import { CodeController } from './controllers/code-controller/CodeController'
import { DescriptionController } from './controllers/description-controller/DescriptionController'
import { HostingController } from './controllers/hosting-controller/HostingController'
import { MentorController } from './controllers/mentor-controller/MentorController'
import { NameController } from './controllers/name-controller/NameController'
import { StartDateController } from './controllers/start-date-controller/StartDateController'
import { VideoUrlController } from './controllers/video-url-controller/VideoUrlController'
import { VideoSettings } from './video-settings/VideoSettings'

type Props = {
  courses?: Course[]
  defaultValues?: Video
  isLoading: boolean
  playlists?: Playlist[]
  submitHandler: SubmitHandler<Video>
}

export const VideoMainForm = ({
  courses,
  defaultValues,
  isLoading,
  playlists,
  submitHandler,
}: Props) => {
  const [videoUrl, setVideoUrl] = useState('')
  const [tabValue, setTabValue] = useState<boolean | string>(false)
  const [selectedPlaylistsIds, setSelectedPlaylistsIds] = useState<string[]>([])
  const [videoSettingsState, setVideoSettingsState] = useState<VideoSettingsState>({})

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
  } = useForm<Video>({
    mode: 'all',
  })

  const changeUrl = (event: ChangeEvent<HTMLInputElement>) => {
    parseVideoUrl(event, setVideoUrl, setValue, getValues, setError, clearErrors)
  }

  const checkSelectedPlaylistsRequiredSettings = () => {
    for (const playlistId in videoSettingsState) {
      if (videoSettingsState[playlistId].length === 0) {
        setTabValue(playlistId)

        return true
      }
    }
  }

  const onSubmit = (videoModel: Video) => {
    if (checkSelectedPlaylistsRequiredSettings()) {
      return
    }

    const settings = Object.values(videoSettingsState).flat()
    const tags = defaultValues ? defaultValues.tags : []

    submitHandler({ ...videoModel, settings, tags })
  }

  const submitButtonDisable =
    !!errors.hostingId || !!errors.hostingHash || !!errors.name || isLoading

  useEffect(() => {
    if (defaultValues) {
      const { hosting, hostingHash, hostingId, settings, url } = defaultValues

      const defaultVideoUrl = generateVideoUrlFromId(hosting, hostingId, hostingHash, url)

      setVideoUrl(defaultVideoUrl)

      const defaultSelectedPlaylistsIds = Array.from(
        new Set(settings.map(setting => setting.playlistId))
      )

      setSelectedPlaylistsIds(defaultSelectedPlaylistsIds)
      setTabValue(defaultSelectedPlaylistsIds[0])

      const defaultVideoSettingsState = restoreVideoSettingsState(
        defaultSelectedPlaylistsIds,
        settings
      )

      setVideoSettingsState(defaultVideoSettingsState)

      reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <form className={s.container} onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
        <NameController control={control} />

        <DescriptionController control={control} />

        <HostingController
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          setVideoUrl={setVideoUrl}
        />

        <TextField
          fullWidth
          label={'Ссылка на видео'}
          onChange={changeUrl}
          required
          value={videoUrl}
        />

        <VideoUrlController control={control} name={'hostingId'} />

        <VideoUrlController control={control} name={'hostingHash'} />

        <div className={s.flexContainer}>
          <MentorController control={control} setValue={setValue} />

          <StartDateController control={control} setValue={setValue} />

          <CodeController control={control} />
        </div>

        <VideoSettings
          courses={courses}
          playlists={playlists}
          selectedPlaylistsIds={selectedPlaylistsIds}
          setSelectedPlaylistsIds={setSelectedPlaylistsIds}
          setTabValue={setTabValue}
          setVideoSettingsState={setVideoSettingsState}
          tabValue={tabValue}
          videoSettingsState={videoSettingsState}
        />

        <Grid container justifyContent={'flex-end'} marginTop={4}>
          <Button disabled={submitButtonDisable} type={'submit'}>
            Сохранить
          </Button>
        </Grid>
      </LocalizationProvider>
    </form>
  )
}

import { Table } from '@it-incubator/ui-kit'
import { Review } from 'features/code-review/service'
import { Course } from 'services/courses'

import s from './CodeReviewTable.module.css'

import { CodeReviewTableRow } from './code-review-table-row/CodeReviewTableRow'

type Props = {
  codeReviews: Review[]
  courses?: Course[]
}

export const CodeReviewTable = ({ codeReviews, courses }: Props) => {
  return (
    <Table.Root className={s.tableRoot}>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell align={'left'}>Имя студента</Table.HeadCell>
          <Table.HeadCell align={'left'}>Ссылка на проект</Table.HeadCell>
          <Table.HeadCell align={'left'}>Дата</Table.HeadCell>
          <Table.HeadCell align={'left'}>Статус</Table.HeadCell>
          <Table.HeadCell align={'left'}>Ментор</Table.HeadCell>
          <Table.HeadCell align={'left'}>Курс</Table.HeadCell>
          <Table.HeadCell />
        </Table.Row>
      </Table.Head>

      <Table.Body>
        {codeReviews.map(review => (
          <CodeReviewTableRow courses={courses} key={review.id} review={review} />
        ))}
      </Table.Body>
    </Table.Root>
  )
}

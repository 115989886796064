import { appActions, uiActions } from 'app'

// BaseThunkAPI need import
export const thunkTryCatch = async (thunkAPI: any, logic: Function) => {
  thunkAPI.dispatch(appActions.setLoading({ isLoading: true }))
  try {
    return await logic()
  } catch (e) {
    const er: any = e
    const error = er.response
      ? er.response.data.error
      : er.message + ', more details in the console'

    thunkAPI.dispatch(
      uiActions.showSnackbar({
        message: error,
        severity: 'error',
      })
    )
    thunkAPI.dispatch(appActions.setLoading({ isLoading: false }))

    return thunkAPI.rejectWithValue({ error })
  }
}

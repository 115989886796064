import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { Playlist, PlaylistsArgs, PlaylistsResponse } from '../service'

export const playlistsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_VIDEO_URL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    addDaysForPlaylistsSettings: build.mutation({
      invalidatesTags: ['playlists'],
      query: (settingIds: string[]) => {
        return {
          body: { settingIds },
          method: 'PUT',
          url: 'playlists/add-days',
        }
      },
    }),
    createPlaylist: build.mutation({
      invalidatesTags: ['playlists'],
      query: (playlist: Playlist) => {
        return {
          body: playlist,
          method: 'POST',
          url: 'playlists',
        }
      },
    }),
    deletePlaylist: build.mutation({
      invalidatesTags: ['playlists'],
      query: (playlistId: string) => {
        return {
          method: 'DELETE',
          url: `playlists/${playlistId}`,
        }
      },
    }),
    getAllPlaylists: build.query<PlaylistsResponse, PlaylistsArgs>({
      providesTags: () => ['playlists'],
      query: ({ courseId, page, pageSize = 5000, search }) => {
        return {
          params: {
            courseId,
            page,
            pageSize,
            search,
          },
          url: 'playlists',
        }
      },
    }),
    updatePlaylist: build.mutation({
      invalidatesTags: ['playlists'],
      query: ({ playlist, playlistId }) => {
        return {
          body: playlist,
          method: 'PUT',
          url: `playlists/${playlistId}`,
        }
      },
    }),
  }),
  reducerPath: 'playlistsService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['playlists'],
})

export const {
  useAddDaysForPlaylistsSettingsMutation,
  useCreatePlaylistMutation,
  useDeletePlaylistMutation,
  useGetAllPlaylistsQuery,
  useUpdatePlaylistMutation,
} = playlistsService

import { ButtonHTMLAttributes, DetailedHTMLProps, memo } from 'react'

import s from './Button.module.css'

export const Button = memo(
  ({
    ...restProps
  }: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    return <button {...restProps} className={s.button} />
  }
)

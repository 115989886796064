import { Spinner } from '@it-incubator/ui-kit'
import { Backdrop } from '@mui/material'

type Props = {
  open: boolean
}

export const LoadingOverlay = ({ open }: Props) => {
  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Spinner />
    </Backdrop>
  )
}

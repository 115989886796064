import { useState } from 'react'

import { Button } from '@it-incubator/ui-kit'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { Course } from 'services/courses'

import s from './FaqPageHeader.module.css'

import { QuestionModal } from '../question-modal/QuestionModal'

type Props = {
  courses: Course[]
}

export const FaqPageHeader = ({ courses }: Props) => {
  const [openAddQuestionModal, setOpenQuestionModal] = useState(false)

  const openModalHandler = () => setOpenQuestionModal(true)
  const closeModalHandler = () => setOpenQuestionModal(false)

  return (
    <div className={s.headerContainer}>
      <PageHeader title={'FAQ'} />

      <Button onClick={openModalHandler}>+ Добавить вопрос</Button>

      <QuestionModal courses={courses} onClose={closeModalHandler} open={openAddQuestionModal} />
    </div>
  )
}

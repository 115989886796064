import { Dispatch, SyntheticEvent } from 'react'

import { Tab, Tabs } from '@mui/material'
import { Playlist } from 'features/playlists/service'

type Props = {
  playlists?: Playlist[]
  selectedPlaylistsIds: string[]
  setTabValue: Dispatch<boolean | string>
  tabValue: boolean | string
}

export const SelectedPlaylistsTabs = ({
  playlists,
  selectedPlaylistsIds,
  setTabValue,
  tabValue,
}: Props) => {
  return (
    <Tabs
      onChange={(event: SyntheticEvent, newValue: string) => setTabValue(newValue)}
      scrollButtons={false}
      value={tabValue}
      variant={'scrollable'}
    >
      {selectedPlaylistsIds.map(selectedPlaylistId => {
        return playlists?.map(playlist => {
          return (
            selectedPlaylistId === playlist.id && (
              <Tab
                key={selectedPlaylistId}
                label={playlist.name.substring(0, 25)}
                value={selectedPlaylistId}
              />
            )
          )
        })
      })}
    </Tabs>
  )
}

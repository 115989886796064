import { Button } from '@mui/material'
import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { Mood, useDeleteUserMoodMutation } from 'features/mood/service'

import s from './DeleteMoodModal.module.css'

type Props = {
  mood: Mood
  onCLose: () => void
  onOpen: boolean
}

export const DeleteMoodModal = ({ mood, onCLose, onOpen }: Props) => {
  const [deleteMood, { isLoading }] = useDeleteUserMoodMutation()

  return (
    <CustomModal modalTitle={'Удалить'} onClose={onCLose} open={onOpen} width={330}>
      <div>
        Вы уверены, что хотите удалить
        <br />
        <b>Настроение ({`${mood.studentId.lastName} ${mood.studentId.firstName}`})?</b>
      </div>
      <div className={s.buttonsWrapper}>
        <Button
          color={'info'}
          disabled={isLoading}
          onClick={() => {
            deleteMood(mood._id)
            onCLose()
          }}
        >
          Да
        </Button>
        <Button onClick={onCLose}>Нет</Button>
      </div>
    </CustomModal>
  )
}

import { Admin, User } from '@/common/types'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mainApi, uiActions } from 'app'
import { PERS_AREA_ADMIN_TOKEN, saveState, thunkTryCatch } from 'utils'

const defUser: User = {
  _id: '0',
  avatar: '',
  baseId: 0,
  courseId: 1,
  courseTitle: 'Front-end developer',
  firstName: 'Fake',
  inactive: false,
  isAdmin: false,
  lastName: 'Anonymous',
  lastUpdateDate: '2020-12-30T10:09:01.0488913Z',
  level: 0,
  telegramId: 700000000,
}

const loginThunk = createAsyncThunk<{ user: User }, void, { rejectValue: { error: string } }>(
  'app/loginThunk',
  async (payload, thunkAPI) => {
    return thunkTryCatch(thunkAPI, async () => {
      const { token } = await mainApi.login()

      thunkAPI.dispatch(
        uiActions.showSnackbar({
          message: 'Logged in successfully',
          severity: 'success',
        })
      )

      saveState(PERS_AREA_ADMIN_TOKEN, token)
      const p = { user: defUser }

      thunkAPI.dispatch(appActions.setVerified({ isVerified: true, user: p.user }))

      return p
    })
  }
)

const setDarkTheme = createAsyncThunk(
  'app/setDarkTheme',
  async (payload: { isDarkTheme: boolean }, thunkAPI) => {
    const serializedTheme = JSON.stringify(payload.isDarkTheme)

    localStorage.setItem('isDarkTheme', serializedTheme)
    thunkAPI.dispatch(appActions.toggleDarkTheme({ theme: payload.isDarkTheme }))
  }
)

const me = createAsyncThunk('app/me', async (_, thunkAPI) => {
  return thunkTryCatch(thunkAPI, async () => {
    const res = await mainApi.me()

    return res.admin
  })
})

const slice = createSlice({
  extraReducers: builder => {
    builder.addCase(me.fulfilled, (state, action) => {
      state.admin = action.payload
    })
  },
  initialState: {
    admin: {} as Admin,
    // isAuth: false,
    error: '',
    isDarkTheme: false,
    isLoading: false,
    isVerified: false,
    user: defUser,
  },
  name: 'app',
  reducers: {
    setLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading
      state.error = ''
    },
    setVerified: (
      state,
      action: PayloadAction<{
        error?: string
        isVerified: boolean
        user: User
      }>
    ) => {
      state.isVerified = action.payload.isVerified
      state.isLoading = false
      state.user = action.payload.user
      state.error = action.payload.error || ''
    },
    toggleDarkTheme: (state, action: PayloadAction<{ theme: boolean }>) => {
      state.isDarkTheme = action.payload.theme
      document.querySelector('body')?.classList.toggle('dark-mode', action.payload.theme)
    },
  },
})

export const appReducer = slice.reducer
export const appActions = slice.actions
export const appThunks = { loginThunk, me, setDarkTheme }

import { Fragment, memo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
  CV,
  Chats,
  CodeReview,
  Comment,
  DarkMode,
  FAQ,
  Interviews,
  Lessons,
  Moods,
  PhotoAnalysis,
  Playlists,
  Reviews,
  Scrollbar,
  Statistics,
  TaskCheck,
  Toggle,
  VideoContent,
  clsx,
} from '@it-incubator/ui-kit'
import { appThunks, selectIsDarkTheme, selectIsVerified, selectUI } from 'app'
import { PATH } from 'common/components/routing/Routing'
import { useActions, useAppSelector } from 'common/hooks'

import s from './NavBar.module.scss'

import { MenuButton } from '../menu-button/MenuButton'
import { ChatsCounts } from './chats-counts/ChatsCounts'

const menuItems = [
  { img: <TaskCheck />, title: 'Проверка заданий', to: PATH.HOMEWORKS_CHECKED },
  { img: <Playlists />, title: 'Плейлисты', to: PATH.PLAYLISTS },
  { img: <VideoContent />, title: 'Видео', to: PATH.VIDEOS },
  { img: <CV />, title: 'Список CV', to: PATH.CV },
  { img: <Interviews />, title: 'Dev Club', to: PATH.DEV_CLUB },
  { img: <Statistics />, title: 'Статистика', to: PATH.STATISTICS },
  { img: <Comment />, title: 'Комментарии', to: PATH.COMMENTS },
  { img: <Moods />, title: 'Настроения', to: PATH.MOOD },
  { img: <PhotoAnalysis />, title: 'Анализ фото студентов', to: PATH.STUDENTS_PHOTOS },
  { img: <Chats />, title: 'Чаты', to: PATH.CHATS },
  { img: <Reviews />, title: 'Отзывы', to: PATH.REVIEWS },
  { img: <CodeReview />, title: 'Code review', to: PATH.CODE_REVIEW },
  { img: <Lessons />, title: 'Отзывы об уроках', to: PATH.LESSON_REVIEWS },
  { img: <FAQ />, title: 'FAQ', to: PATH.FAQ },
]

export const NavBar = memo(() => {
  const isVerified = useSelector(selectIsVerified)
  const isDarkTheme = useSelector(selectIsDarkTheme)
  const { isNavBarOpen } = useAppSelector(selectUI)
  const { setDarkTheme } = useActions(appThunks)

  const handleThemeToggle = () => {
    setDarkTheme({ isDarkTheme: !isDarkTheme })
  }

  const mappedMenu = menuItems.map(item => (
    <Fragment key={item.to}>
      <li className={clsx(s.linkItem)}>
        <NavLink
          className={({ isActive }) => {
            return clsx(s.menuItem, isNavBarOpen && s.sidebarOpen, isActive && s.activeLink)
          }}
          title={item.title}
          to={item.to}
        >
          {item.img}
          <span>{item.title}</span>
          <ChatsCounts isNavBarOpen={isNavBarOpen} itemPath={item.to} />
        </NavLink>
      </li>

      {item.to === PATH.FAQ && (
        <div className={s.dividerContainer}>
          <hr className={s.divider} />
        </div>
      )}
    </Fragment>
  ))

  return (
    <div
      className={clsx(s.sidebar, isNavBarOpen && s.active)}
      id={'header'}
      style={{ backgroundColor: isDarkTheme ? '#131E2B' : '#FFFEFE' }}
    >
      {isVerified && (
        <>
          <MenuButton />

          <Scrollbar id={'sidebar-menu'} type={'hover'}>
            <menu>
              <ul className={s.menuBlock}>{mappedMenu}</ul>
            </menu>

            <div className={clsx(s.switchBlock, isNavBarOpen && s.cursorPointer)}>
              <DarkMode
                className={clsx(!isNavBarOpen && s.cursorPointer)}
                onClick={() => !isNavBarOpen && handleThemeToggle()}
              />

              {isNavBarOpen && (
                <>
                  <span>Тёмная тема</span>
                  <Toggle checked={isDarkTheme} onCheckedChange={handleThemeToggle} />
                </>
              )}
            </div>
          </Scrollbar>
        </>
      )}
    </div>
  )
})

import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react'

import FilterListOffRoundedIcon from '@mui/icons-material/FilterListOffRounded'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { SortDirection } from 'common/components/custom-table/SortColumn/SortColumn'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { MoodScores } from 'features/mood/data'
import { Filters, MoodQueries } from 'features/mood/service'
import { Course } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, capitalizeFirstLetter, restoreState, saveState } from 'utils'
import { MAX_LEVEL, renderMenuItems } from 'utils/renderMenuItems'

import s from '../MoodsPage.module.css'

type Props = {
  courseId: number | string
  courses?: Course[]
  lastName: string
  learningTime: number | string
  level: number | string
  mood: number | string
  moodQueries: MoodQueries
  programComplexity: number | string
  setCourseId: Dispatch<SetStateAction<number | string>>
  setLastName: Dispatch<SetStateAction<string>>
  setLearningTime: Dispatch<SetStateAction<number | string>>
  setLevel: Dispatch<SetStateAction<number | string>>
  setMood: Dispatch<SetStateAction<number | string>>
  setPage: Dispatch<SetStateAction<number>>
  setProgramComplexity: Dispatch<SetStateAction<number | string>>
  setSort: Dispatch<SetStateAction<string>>
  setSortColumn: Dispatch<SetStateAction<string>>
  setSortDirection: Dispatch<SetStateAction<SortDirection>>
}

export const MoodFilters = ({
  courseId,
  courses,
  lastName,
  learningTime,
  level,
  mood,
  moodQueries,
  programComplexity,
  setCourseId,
  setLastName,
  setLearningTime,
  setLevel,
  setMood,
  setPage,
  setProgramComplexity,
  setSort,
  setSortColumn,
  setSortDirection,
}: Props) => {
  const setFilters = (
    lastName: string,
    learningTime: number | string,
    mood: number | string,
    programComplexity: number | string,
    courseId: number | string,
    level: number | string
  ) => {
    setLastName(lastName)
    setLearningTime(learningTime)
    setMood(mood)
    setProgramComplexity(programComplexity)
    setCourseId(courseId)
    setLevel(level)
  }

  const setDefaultSorting = () => {
    setSort('0created')
    setSortColumn('created')
    setSortDirection('0')
  }

  const onFilterChange = (setState: void, filter: Filters) => {
    saveState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, {
      ...moodQueries,
      filters: filter,
      pagination: {
        ...moodQueries.pagination,
        page: FIRST_PAGE_NUMBER,
      },
      sorting: {
        ...moodQueries.sorting,
        sort: '0created',
        sortColumn: 'created',
        sortDirection: '0',
      },
    })
    setDefaultSorting()
    setPage(FIRST_PAGE_NUMBER)
  }

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const lastName = event.target.value

    onFilterChange(setLastName(lastName), { ...moodQueries.filters, lastName })
  }
  const onCourseIdChange = (event: SelectChangeEvent<number | string>) => {
    const courseId = event.target.value

    onFilterChange(setCourseId(courseId), { ...moodQueries.filters, courseId })
  }
  const onLevelChange = (event: SelectChangeEvent<number | string>) => {
    const level = event.target.value

    onFilterChange(setLevel(level), { ...moodQueries.filters, level })
  }
  const onMoodChange = (event: SelectChangeEvent<number | string>) => {
    const mood = event.target.value

    onFilterChange(setMood(mood), { ...moodQueries.filters, mood })
  }
  const onLearningTimeChange = (event: SelectChangeEvent<number | string>) => {
    const learningTime = event.target.value

    onFilterChange(setLearningTime(learningTime), { ...moodQueries.filters, learningTime })
  }
  const onProgramComplexityChange = (event: SelectChangeEvent<number | string>) => {
    const programComplexity = event.target.value

    onFilterChange(setProgramComplexity(programComplexity), {
      ...moodQueries.filters,
      programComplexity,
    })
  }

  const resetFilters = () => {
    setFilters('', '', '', '', '', '')
    setDefaultSorting()
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, {
      ...moodQueries,
      filters: {
        courseId: '',
        lastName: '',
        learningTime: '',
        level: '',
        mood: '',
        programComplexity: '',
      },
      pagination: {
        ...moodQueries.pagination,
        page: FIRST_PAGE_NUMBER,
      },
      sorting: {
        sort: '0created',
        sortColumn: 'created',
        sortDirection: '0',
      },
    })
  }

  const disableFilterButton =
    lastName === '' &&
    mood === '' &&
    programComplexity === '' &&
    courseId === '' &&
    level === '' &&
    learningTime === ''

  useEffect(() => {
    const restoredMoodQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.MOOD, moodQueries)
    const { courseId, lastName, learningTime, level, mood, programComplexity } =
      restoredMoodQueries.filters

    setFilters(lastName, learningTime, mood, programComplexity, courseId, level)
  }, [])

  return (
    <div className={s.filtersWrapper}>
      <TextField
        fullWidth
        label={'Поиск по фамилии'}
        onChange={onLastNameChange}
        value={lastName}
      />

      <FormControl variant={'outlined'}>
        <InputLabel>Курс</InputLabel>
        <Select label={'Курс'} onChange={onCourseIdChange} sx={{ width: '140px' }} value={courseId}>
          <MenuItem value={''}>Все</MenuItem>
          {courses?.map(course => (
            <MenuItem key={course.title + course.id} value={course.id}>
              {capitalizeFirstLetter(course.title)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant={'outlined'}>
        <InputLabel>Уровень</InputLabel>
        <Select label={'Уровень'} onChange={onLevelChange} sx={{ width: '140px' }} value={level}>
          <MenuItem value={''}>Все</MenuItem>
          {renderMenuItems(MAX_LEVEL, 'уровень')}
        </Select>
      </FormControl>

      <FormControl variant={'outlined'}>
        <InputLabel>Настроение</InputLabel>
        <Select label={'Настроение'} onChange={onMoodChange} sx={{ width: '140px' }} value={mood}>
          <MenuItem value={''}>Все</MenuItem>
          <MenuItem value={MoodScores.TERRIBLE}>1</MenuItem>
          <MenuItem value={MoodScores.BAD}>2</MenuItem>
          <MenuItem value={MoodScores.SATISFACTORY}>3</MenuItem>
          <MenuItem value={MoodScores.GOOD}>4</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant={'outlined'}>
        <InputLabel>Время</InputLabel>
        <Select
          label={'Время'}
          onChange={onLearningTimeChange}
          sx={{ width: '140px' }}
          value={learningTime}
        >
          <MenuItem value={''}>Все</MenuItem>
          <MenuItem value={MoodScores.TERRIBLE}>1</MenuItem>
          <MenuItem value={MoodScores.BAD}>2</MenuItem>
          <MenuItem value={MoodScores.SATISFACTORY}>3</MenuItem>
          <MenuItem value={MoodScores.GOOD}>4</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant={'outlined'}>
        <InputLabel>Программа</InputLabel>
        <Select
          label={'Программа'}
          onChange={onProgramComplexityChange}
          sx={{ width: '140px' }}
          value={programComplexity}
        >
          <MenuItem value={''}>Все</MenuItem>
          <MenuItem value={MoodScores.TERRIBLE}>1</MenuItem>
          <MenuItem value={MoodScores.BAD}>2</MenuItem>
          <MenuItem value={MoodScores.SATISFACTORY}>3</MenuItem>
          <MenuItem value={MoodScores.GOOD}>4</MenuItem>
        </Select>
      </FormControl>

      <Tooltip placement={'top-start'} title={!disableFilterButton ? 'Сбросить фильтры' : ''}>
        <span>
          <IconButton disabled={disableFilterButton} onClick={resetFilters}>
            <FilterListOffRoundedIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Checkbox, Select, Textarea } from '@it-incubator/ui-kit'
import { Divider } from '@mui/material'
import { Option } from 'common/types'
import {
  FeedbackModel,
  FeedbackTypeEnum,
  ReviewStatusEnum,
  ReviewStatusType,
  useCreateFeedbackMutation,
  usePublishFeedbacksMutation,
  useUpdateFeedbackMutation,
} from 'features/code-review/service'

import s from './FeedbackItem.module.css'

type Props = {
  defaultFeedback?: FeedbackModel
  disableAcceptButton: boolean
  disableRejectButton: boolean
  feedback: FeedbackModel
  onModalClose: () => void
  reviewId: number
  reviewStatus: ReviewStatusType
}

export const FeedbackItem = ({
  defaultFeedback,
  disableAcceptButton,
  disableRejectButton,
  feedback,
  onModalClose,
  reviewId,
  reviewStatus,
}: Props) => {
  const [feedbackModel, setFeedbackModel] = useState(feedback)
  const [saveReview, setSaveReview] = useState(false)
  const [error, setError] = useState(false)

  const [createFeedback, { isLoading: isCreateFeedbackLoading }] = useCreateFeedbackMutation()
  const [updateFeedback, { isLoading: isUpdateFeedbackLoading }] = useUpdateFeedbackMutation()
  const [publishFeedbacks, { isLoading: isPublishFeedbacksLoading }] = usePublishFeedbacksMutation()

  const isLoading = isCreateFeedbackLoading || isUpdateFeedbackLoading || isPublishFeedbacksLoading

  const MIN_FEEDBACK_LENGTH = 10
  const MAX_FEEDBACK_LENGTH = 1000

  const onFeedbackChange = (feedback: string) => {
    if (error) {
      if (feedback.length >= MIN_FEEDBACK_LENGTH && feedback.length <= MAX_FEEDBACK_LENGTH) {
        setError(false)
      }
    }

    setFeedbackModel({ ...feedbackModel, feedback })

    setSaveReview(false)
  }

  const selectOptions: Option[] = [{ label: 'Выберите оценку от 1 до 10', value: 0 }]

  const MIN_SCORE = 1
  const SCORES_COUNT = 10

  for (let i = MIN_SCORE; i <= SCORES_COUNT; i++) {
    selectOptions.push({ label: i, value: i })
  }

  const onScoreChange = (score: number) => {
    setFeedbackModel({ ...feedbackModel, score })

    setSaveReview(false)
  }

  const onSaveReviewChange = () => {
    const feedback = feedbackModel.feedback

    if (feedback) {
      if (feedback.length < MIN_FEEDBACK_LENGTH || feedback.length > MAX_FEEDBACK_LENGTH) {
        setError(true)

        return
      }
    }

    const feedbackMutate = defaultFeedback ? updateFeedback : createFeedback
    const id = defaultFeedback ? defaultFeedback.id : reviewId

    feedbackMutate({
      feedbackModel: {
        feedback: feedbackModel.feedback,
        score: feedbackModel.score,
        type: feedbackModel.type,
      },
      id,
    })
      .unwrap()
      .then(() => {
        toast.success('Отзыв успешно сохранен')
        setSaveReview(true)
      })
      .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
  }

  const onAcceptProject = () => {
    publishFeedbacks({ reviewId, status: ReviewStatusEnum.ACCEPTED })
      .unwrap()
      .then(() => toast.success('Проект успешно принят'))
      .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
      .finally(onModalClose)
  }

  const onRejectProject = () => {
    publishFeedbacks({ reviewId, status: ReviewStatusEnum.REJECTED })
      .unwrap()
      .then(() => toast.success('Проект успешно отклонен'))
      .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
      .finally(onModalClose)
  }

  const disableCheckbox = isLoading || !feedbackModel.score || !feedbackModel.feedback

  useEffect(() => {
    if (defaultFeedback) {
      setFeedbackModel({
        ...feedbackModel,
        feedback: defaultFeedback.feedback,
        score: defaultFeedback.score,
        type: defaultFeedback.type,
      })
    }
  }, [defaultFeedback])

  return (
    <>
      <div className={s.container}>
        {feedbackModel.type !== FeedbackTypeEnum.REJECTED && (
          <Select
            disabled={isLoading}
            label={feedbackModel.title}
            onChange={onScoreChange}
            options={selectOptions}
            value={feedbackModel.score ?? 0}
          />
        )}

        <Textarea
          disabled={isLoading}
          errorMessage={
            error ? 'Минимальная длина отзыва - 10 символов, максимальная - 1000' : undefined
          }
          label={feedbackModel.type === FeedbackTypeEnum.REJECTED ? feedbackModel.title : 'Отзыв'}
          onValueChange={onFeedbackChange}
          placeholder={'Оставьте отзыв о студенте'}
          rows={5}
          value={feedbackModel.feedback ?? ''}
        />

        <div className={`${s.checkBoxContainer} ${!disableCheckbox && s.labelCursor}`}>
          <Checkbox
            checked={saveReview}
            disabled={disableCheckbox}
            label={'Сохранить отзыв'}
            onChange={onSaveReviewChange}
          />
        </div>
      </div>

      {feedbackModel.type === FeedbackTypeEnum.GENERAL && (
        <div className={s.buttonContainer}>
          <Button
            disabled={
              isLoading || disableAcceptButton || reviewStatus === ReviewStatusEnum.ACCEPTED
            }
            onClick={onAcceptProject}
          >
            Принять проект
          </Button>
        </div>
      )}

      {feedbackModel.type === FeedbackTypeEnum.REJECTED && (
        <div className={s.buttonContainer}>
          <Button
            disabled={
              isLoading || disableRejectButton || reviewStatus === ReviewStatusEnum.REJECTED
            }
            onClick={onRejectProject}
            variant={'secondary'}
          >
            Вернуть на доработку
          </Button>
        </div>
      )}

      <Divider className={s.divider} />
    </>
  )
}

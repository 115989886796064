import { MenuItem, Pagination, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { selectIsDarkTheme } from 'app'
import { useAppSelector } from 'common/hooks'

import s from './CustomPagination.module.css'

type Props = {
  count: number
  disabled?: boolean
  itemForPageCount: number
  itemName: string
  onChange: any
  onSelectChange: any
  pageNumber: number
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    '&.MuiInputBase-root': {
      '& .MuiSelect-icon': {
        right: '2px',
      },
      '& .MuiSelect-select': {
        border: '1px solid #E5E5E5',
        borderRadius: '2px',
        padding: '2px 26px 2px 6px',
      },
      backgroundColor: 'white',
    },
  },
  rootSelectDark: {
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    '&.MuiInputBase-root': {
      '& .MuiSelect-icon': {
        right: '2px',
      },
      '& .MuiSelect-select': {
        backgroundColor: '#182636',
        border: '1px solid #263648',
        borderRadius: '2px',
        padding: '2px 26px 2px 6px',
      },
    },
  },
})

export const CustomPagination = ({
  count,
  disabled,
  itemForPageCount,
  itemName,
  onChange,
  onSelectChange,
  pageNumber,
}: Props) => {
  const classes = useStyles()
  const isDarkTheme = useAppSelector(selectIsDarkTheme)

  return (
    <div className={s.pagination}>
      <Pagination
        count={count}
        disabled={disabled}
        onChange={onChange}
        page={pageNumber}
        shape={'rounded'}
        showFirstButton
        showLastButton
      />
      <div className={s.selectPagination} style={{ display: 'flex' }}>
        <p>Показать</p>
        <Select
          className={isDarkTheme ? classes.rootSelectDark : classes.root}
          id={'selectPagination'}
          onChange={onSelectChange}
          value={itemForPageCount}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <p>{`${itemName}`} на странице</p>
      </div>
    </div>
  )
}

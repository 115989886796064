import { Control, Controller, UseFormSetValue } from 'react-hook-form'

import { Autocomplete, FormControl, TextField } from '@mui/material'
import { Video } from 'features/videos/service'
import { useGetUsersQuery } from 'services/users'

import s from './MentorController.module.css'

type Props = {
  control: Control<Video>
  setValue: UseFormSetValue<Video>
}

type Option = {
  id: number
  label: string
}

export const MentorController = ({ control, setValue }: Props) => {
  const { data } = useGetUsersQuery()

  const users = data?.items || []

  const options: Option[] = users.map(user => ({
    id: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }))

  return (
    <Controller
      control={control}
      defaultValue={''}
      name={'mentor'}
      render={({ field }) => (
        <FormControl className={s.container} fullWidth variant={'outlined'}>
          <Autocomplete
            {...field}
            freeSolo
            //@ts-ignore
            onChange={(_, data: Option) => {
              field.onChange(data?.label || '')
              setValue('mentorId', data?.id || 0)
            }}
            options={options}
            renderInput={params => (
              <TextField {...params} label={'Преподаватель'} variant={'outlined'} />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
          />
        </FormControl>
      )}
    />
  )
}

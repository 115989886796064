import { instance } from 'services/instances/default'
import { loginInstance } from 'services/instances/login'
import { PERS_AREA_ADMIN_TOKEN, getToken, restoreState } from 'utils'

export const mainApi = {
  changeStatus: (_id: string, status: number, mentorStatus: number) => {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    return instance.post('/auth/change-cv-status', {
      _id,
      mentorStatus,
      status,
      token,
    })
  },
  login: async () => {
    const response = await loginInstance.post<Login>('/auth/token')

    return response.data
  },
  me: async () => {
    const token = getToken()
    const response = await instance.get<Me>('auth/admin/me', { headers: token })

    return response.data
  },
}

// Types
type Login = {
  error?: string
  token: string
}
type Me = {
  admin: {
    id: number
    keycloakData: null
    name: string
    role: string
  }
}

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Checkbox, IconButton } from '@mui/material'
import avatarPlaceholder from 'assets/icons/rejected-avatar.svg'

import s from './StudentsPhotosTableRow.module.css'

type Props = {
  avatar: string
  deleteDisabled: boolean
  id: string
  name: string
  onDelete: (id: string, name: string) => void
  onImageClicked: (src: string) => void
  onSelected: (id: string) => void
  selected: boolean
  updatedDate: string
}

export const StudentsPhotosTableRow = ({
  avatar,
  deleteDisabled,
  id,
  name,
  onDelete,
  onImageClicked,
  onSelected,
  selected,
  updatedDate,
}: Props) => {
  const handleCheckboxChanged = () => {
    onSelected(id)
  }
  const handleDeleteButtonClicked = () => {
    onDelete(id, name)
  }
  const handleImageClicked = () => {
    onImageClicked(avatar)
  }

  return (
    <div className={s.rowBox}>
      <div className={s.studentBox}>
        <Checkbox checked={selected} onChange={handleCheckboxChanged} />
        <img
          alt={''}
          className={s.avatar}
          height={50}
          onClick={handleImageClicked}
          src={avatar || avatarPlaceholder}
          width={50}
        />
        <span>{name}</span>
      </div>

      <span>{updatedDate}</span>

      <IconButton disabled={deleteDisabled} onClick={handleDeleteButtonClicked}>
        <DeleteForeverIcon />
      </IconButton>
    </div>
  )
}

import { useState } from 'react'
import { toast } from 'react-toastify'

import { ReadMore, Table } from '@it-incubator/ui-kit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/DriveFileRenameOutline'
import { IconButton } from '@mui/material'
import { ConfirmationModal } from 'common/components/confirmation-modal/ConfirmationModal'
import { useDeleteFaqMutation } from 'features/faq/service'
import { Faq } from 'features/faq/service/types'
import moment from 'moment'
import { Course } from 'services/courses'
import { capitalizeFirstLetter, truncateString } from 'utils'

import s from './FaqTableRow.module.css'

import { QuestionModal } from '../question-modal/QuestionModal'

type Props = {
  courses: Course[]
  faq: Faq
}

export const FaqTableRow = ({ courses, faq }: Props) => {
  const [showEditModalId, setShowEditModalId] = useState(-1)
  const [showDeleteModalId, setShowDeleteModalId] = useState(-1)

  const [deleteFaq, { isLoading }] = useDeleteFaqMutation()

  const coursesTitles = faq.forCourses
    .map(courseId => {
      return courses
        .map(course => courseId === course.id && capitalizeFirstLetter(course.title))
        .filter(title => title)
        .flat()
    })
    .join(', ')

  const deleteFaqHandler = () => {
    deleteFaq(faq.id)
      .unwrap()
      .then(() => toast.success('Вопрос успешно удален'))
      .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
      .finally(() => setShowDeleteModalId(-1))
  }

  return (
    <Table.Row>
      <Table.Cell className={s.tableCell}>{faq.title}</Table.Cell>

      <Table.Cell className={s.tableCell} width={200}>
        {moment(faq.updatedAt).format('DD.MM.YYYY')}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={400}>
        <ReadMore maxLength={50} text={coursesTitles} />

        {faq.forCourses.length === 0 && <span className={s.opacityText}>Курсы не указаны</span>}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={100}>
        <div className={s.buttonsContainer}>
          <IconButton disabled={isLoading} onClick={() => setShowEditModalId(faq.id)}>
            <EditIcon />
          </IconButton>

          <IconButton disabled={isLoading} onClick={() => setShowDeleteModalId(faq.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </Table.Cell>

      {showEditModalId === faq.id && (
        <QuestionModal
          courses={courses}
          defaultFaq={faq}
          onClose={() => setShowEditModalId(-1)}
          open={showEditModalId === faq.id}
        />
      )}

      {showDeleteModalId === faq.id && (
        <ConfirmationModal
          cancelButtonText={'Нет'}
          disabledButtons={isLoading}
          okButtonText={'Да'}
          onCancel={() => setShowDeleteModalId(-1)}
          onOk={deleteFaqHandler}
          show={showDeleteModalId === faq.id}
          text={`Вы уверены, что хотите удалить вопрос "${truncateString(faq.title, 20)}" ?`}
          title={`Удалить`}
        />
      )}
    </Table.Row>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'

import { LabsUsersResponse } from '../users'

export const labsUsersAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_LOGIN_URL,
    credentials: 'include',
  }),
  endpoints: build => ({
    getUsers: build.query<LabsUsersResponse, void>({
      providesTags: () => ['users'],
      query: () => {
        return {
          url: '/users?pageSize=99999',
        }
      },
    }),
  }),
  reducerPath: 'labsUsersApi',
  tagTypes: ['users'],
})

export const { useGetUsersQuery } = labsUsersAPI

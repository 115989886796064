import { Dispatch, SetStateAction } from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

type Props = {
  editMode: boolean
  rank: number
  setRank: Dispatch<SetStateAction<number>>
}

const ranksMap: Record<string, string> = {
  0: 'Junior',
  1: 'Middle',
  2: 'Senior',
  3: 'Lead',
} as const

export const Rank = ({ editMode, rank, setRank }: Props) => {
  const rankChangeHandler = (event: SelectChangeEvent) => {
    setRank(+event.target.value)
  }

  return (
    <span>
      {editMode ? (
        <FormControl variant={'standard'}>
          <InputLabel>Позиция</InputLabel>
          <Select
            label={'Позиция'}
            onChange={rankChangeHandler}
            sx={{ width: '100px' }}
            value={rank.toString()}
          >
            {Object.keys(ranksMap).map(rank => {
              return (
                <MenuItem key={rank} value={rank}>
                  {ranksMap[rank as keyof typeof ranksMap]}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      ) : (
        ranksMap[rank] + ' '
      )}
    </span>
  )
}

import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'

import { Checkbox, TabType, Tabs, TextField } from '@it-incubator/ui-kit'
import { selectAdmin } from 'app'
import { Notification } from 'common/components/notification/Notification'
import { FIRST_PAGE_NUMBER, TabsValues } from 'features/chats/constants'
import { ChatQueriesType, UnreadCountsResponse } from 'features/chats/service'
import { LOCAL_STORAGE_QUERIES_KEYS, saveState } from 'utils'

import s from './ChatsFilters.module.css'

type Props = {
  chatQueries: ChatQueriesType
  managerId: string
  myUnreadCount: number
  search: string
  setManagerId: Dispatch<SetStateAction<string>>
  setPage: Dispatch<SetStateAction<number>>
  setSearch: Dispatch<SetStateAction<string>>
  setTabValue: Dispatch<SetStateAction<TabsValues>>
  tabValue: TabsValues
  unreadCountWithoutManager: number
}

export const ChatsFilters = ({
  chatQueries,
  managerId,
  myUnreadCount,
  search,
  setManagerId,
  setPage,
  setSearch,
  setTabValue,
  tabValue,
  unreadCountWithoutManager,
}: Props) => {
  const admin = useSelector(selectAdmin)

  const tabs: TabType[] = [
    { title: 'Все чаты', value: TabsValues.ALL },
    {
      // @ts-ignore
      title: (
        <div className={s.unread}>
          Непрочитанные чаты
          {!!myUnreadCount && <Notification unreadCount={myUnreadCount} />}
          {!!unreadCountWithoutManager && (
            <Notification color={'secondary'} unreadCount={unreadCountWithoutManager} />
          )}
        </div>
      ),
      value: TabsValues.UNREAD,
    },
    { title: 'Прочитанные чаты', value: TabsValues.READ },
  ]

  const tabChangeHandler = (value: string) => {
    setTabValue(value as TabsValues)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CHAT, {
      ...chatQueries,
      filters: { ...chatQueries.filters, tab: value },
    })
  }

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value

    setSearch(search)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CHAT, {
      ...chatQueries,
      filters: { ...chatQueries.filters, student: search },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CHAT, {
      ...chatQueries,
      filters: { ...chatQueries.filters, student: '' },
    })
  }

  const onChangeManagerId = (checked: boolean) => {
    if (checked) {
      setManagerId(String(admin.id))
    } else {
      setManagerId('')
    }

    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CHAT, {
      ...chatQueries,
      filters: { ...chatQueries.filters, managerId: checked ? String(admin.id) : '' },
    })
  }

  return (
    <>
      <Tabs fullWidth onValueChange={tabChangeHandler} tabs={tabs} value={tabValue} />

      <TextField
        className={s.search}
        onChange={onChangeSearch}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <Checkbox
        checked={!!managerId}
        label={'Отображать только мои чаты'}
        onChange={onChangeManagerId}
      />
    </>
  )
}

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { IconButton, useTheme } from '@mui/material'
import { clsx } from 'clsx'

import s from './MassDeleteAvatars.module.css'

type Props = {
  isDisabledDeleteButtons: boolean
  onDeleteAllSelected: () => void
  selectedStudentAvatarIds: string[]
}

export const MassDeleteAvatars = ({
  isDisabledDeleteButtons,
  onDeleteAllSelected,
  selectedStudentAvatarIds,
}: Props) => {
  const theme = useTheme()
  const isDarkTheme = theme.palette.mode === 'dark'

  const selectedStudentAvatarsCount = selectedStudentAvatarIds.length

  if (selectedStudentAvatarsCount === 0) {
    return null
  }

  return (
    <div className={clsx(s.box, isDarkTheme && s.dark)}>
      <p>Выделено студентов: {selectedStudentAvatarsCount}</p>
      <IconButton disabled={isDisabledDeleteButtons} onClick={onDeleteAllSelected}>
        <DeleteForeverIcon />
      </IconButton>
    </div>
  )
}

import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { Button, Select, TextField } from '@it-incubator/ui-kit'
import { uiActions } from 'app'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useActions } from 'common/hooks'
import { Option } from 'common/types'
import { Playlist, PlaylistsQueries, useCreatePlaylistMutation } from 'features/playlists/service'
import { Course } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, getCoursesSelectOptions, saveState } from 'utils'

import s from './PlaylistsHeader.module.css'

import { PlaylistModal } from '../playlists-modal/PlaylistModal'

type Props = {
  courseId: string
  courses: Course[]
  playlistsQueries: PlaylistsQueries
  search: string
  setCourseId: Dispatch<SetStateAction<string>>
  setPage: Dispatch<SetStateAction<number>>
  setSearch: Dispatch<SetStateAction<string>>
}

export const PlaylistsHeader = ({
  courseId,
  courses,
  playlistsQueries,
  search,
  setCourseId,
  setPage,
  setSearch,
}: Props) => {
  const [showCreatePlaylistModal, setShowCreatePlaylistModal] = useState(false)

  const startCoursesSelectOptions: Option[] = [{ label: 'Курс не выбран', value: '' }]
  const coursesSelectOptions = getCoursesSelectOptions(courses, startCoursesSelectOptions)

  const onOpen = () => setShowCreatePlaylistModal(true)
  const onClose = () => setShowCreatePlaylistModal(false)

  const { showSnackbar } = useActions(uiActions)

  const [createPlaylist, { isLoading }] = useCreatePlaylistMutation()

  const createPlaylistCallback = (playlistModel: Playlist) => {
    createPlaylist(playlistModel)
      .unwrap()
      .catch(e => showSnackbar({ message: e.data.message, severity: 'error' }))
      .finally(onClose)
  }

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.currentTarget.value

    setSearch(searchValue)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.PLAYLISTS, {
      ...playlistsQueries,
      filters: { ...playlistsQueries.filters, search: searchValue },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.PLAYLISTS, {
      ...playlistsQueries,
      filters: { ...playlistsQueries.filters, search: '' },
    })
  }

  const onCourseIdChange = (value: string) => {
    setCourseId(value)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.PLAYLISTS, {
      ...playlistsQueries,
      filters: { ...playlistsQueries.filters, courseId: value },
    })
  }

  return (
    <>
      {showCreatePlaylistModal && (
        <PlaylistModal
          callback={createPlaylistCallback}
          close={onClose}
          show={showCreatePlaylistModal}
        />
      )}

      <div className={s.headerContainer}>
        <PageHeader title={'Плейлисты'} />

        <Button disabled={isLoading} onClick={onOpen}>
          + Добавить плейлист
        </Button>
      </div>

      <TextField
        className={s.search}
        onChange={onChangeSearch}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <Select
        className={s.coursesFilter}
        label={'Курс'}
        onChange={onCourseIdChange}
        options={coursesSelectOptions}
        value={courseId}
        width={300}
      />
    </>
  )
}

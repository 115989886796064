import { useEffect, useState } from 'react'

import { Pagination, Spinner, Table } from '@it-incubator/ui-kit'
import { Backdrop } from '@mui/material'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants/'
import { useDebounce } from 'common/hooks'
import { Comment, CommentQueries, useGetCommentsQuery } from 'features/comments/service'
import { useGetCoursesQuery } from 'services/courses'
import { useGetUsersQuery } from 'services/users'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState } from 'utils'

import s from './Comments.module.css'

import { CommentsFilters } from './comments-filters/CommentsFilters'
import { CommentsTableRow } from './comments-table-row/CommentsTableRow'

export const Comments = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [search, setSearch] = useState('')
  const [courseId, setCourseId] = useState('')
  const [videoAuthor, setVideoAuthor] = useState('')

  const debouncedSearch = useDebounce(search, 1000)

  const commentsQueries: CommentQueries = {
    filters: {
      courseId,
      search,
      videoAuthor,
    },
    sorting: {},
  }

  const {
    data: commentsData,
    isFetching,
    isLoading: isCommentsLoading,
  } = useGetCommentsQuery({
    courseId,
    page,
    pageSize,
    search: debouncedSearch,
    videoAuthor,
  })

  const { data: coursesData, isLoading: isCoursesLoading } = useGetCoursesQuery()
  const { data: usersData, isLoading: isUsersLoading } = useGetUsersQuery()

  const courses = coursesData?.items || []
  const users = usersData?.items || []

  const isLoading = isCommentsLoading || isCoursesLoading || isUsersLoading

  const perPageChangeHandler = (itemPerPage: number) => {
    setPageSize(itemPerPage)
    setPage(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredCommentsQueries = restoreState(
      LOCAL_STORAGE_QUERIES_KEYS.COMMENTS,
      commentsQueries
    )
    const { courseId, search, videoAuthor } = restoredCommentsQueries.filters

    if (search) {
      setSearch(search)
    }
    if (courseId) {
      setCourseId(courseId)
    }
    if (videoAuthor) {
      setVideoAuthor(videoAuthor)
    }
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <PageHeader title={'Комментарии'} />

      <CommentsFilters
        commentsQueries={commentsQueries}
        courseId={courseId}
        courses={courses}
        search={search}
        setCourseId={setCourseId}
        setPage={setPage}
        setSearch={setSearch}
        setVideoAuthor={setVideoAuthor}
        users={users}
        videoAuthor={videoAuthor}
      />

      <Table.Root className={s.tableRoot}>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align={'left'}>Название</Table.HeadCell>
            <Table.HeadCell align={'left'}>Имя студента</Table.HeadCell>
            <Table.HeadCell align={'left'}>Курс</Table.HeadCell>
            <Table.HeadCell align={'left'}>Дата</Table.HeadCell>
            <Table.HeadCell align={'left'}>Преподаватель</Table.HeadCell>
            <Table.HeadCell align={'left'}>Комментарий</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {commentsData?.comments.map((comment: Comment) => {
            return <CommentsTableRow comment={comment} courses={courses} key={comment.id} />
          })}
        </Table.Body>
      </Table.Root>

      <Backdrop
        open={isFetching || isLoading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <Spinner />
      </Backdrop>

      <Pagination
        count={Math.ceil((commentsData?.totalCount ?? 1) / pageSize)}
        onChange={setPage}
        onPerPageChange={perPageChangeHandler}
        page={page}
        perPage={pageSize}
        perPageOptions={[10, 20, 50, 100]}
      />
    </div>
  )
}

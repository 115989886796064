import { Table } from '@it-incubator/ui-kit'
import { Student } from 'features/chats/service'

import s from './ChatsTable.module.css'

import { ChatTableRow } from './chat-table-row/ChatTableRow'

type Props = {
  students: Student[]
}

export const ChatsTable = ({ students }: Props) => {
  return (
    <Table.Root className={s.tableRoot}>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell align={'left'} className={s.lensHeadCell} />
          <Table.HeadCell align={'left'} className={s.lensHeadCell}>
            Имя студента
          </Table.HeadCell>
          <Table.HeadCell align={'left'}>Группа</Table.HeadCell>
          <Table.HeadCell align={'left'}>Дата</Table.HeadCell>
          <Table.HeadCell align={'left'}>Менеджеры</Table.HeadCell>
          <Table.HeadCell />
        </Table.Row>
      </Table.Head>

      <Table.Body>
        {students.map(student => (
          <ChatTableRow key={student.student_id} student={student} />
        ))}
      </Table.Body>
    </Table.Root>
  )
}

import { ChangeEvent, Dispatch } from 'react'
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

import { Video } from 'features/videos/service'
import { getVimeoHash, getVimeoId, getYouTubeVideoId } from 'utils/parseVideoUrl'

const VIDEO_URL_ERRORS = {
  HASH: 'hash не найден',
  ID: 'id не найден',
  INCOMPATIBILITY: 'не соответствие url и хостинга',
} as const

export const parseVideoUrl = (
  event: ChangeEvent<HTMLInputElement>,
  setVideoUrl: Dispatch<string>,
  setValue: UseFormSetValue<Video>,
  getValues: UseFormGetValues<Video>,
  setError: UseFormSetError<Video>,
  clearErrors: UseFormClearErrors<Video>
) => {
  const url = event.currentTarget.value
  const hosting = getValues().hosting

  setVideoUrl(url)
  clearErrors()

  if (hosting === 'youtube') {
    const youtubeId = getYouTubeVideoId(url)

    const checkVimeoUrl = getVimeoId(url)

    if (youtubeId) {
      setValue('hostingId', youtubeId)
    } else {
      setError('hostingId', { message: VIDEO_URL_ERRORS.ID })
    }

    if (checkVimeoUrl) {
      setError('hostingId', { message: VIDEO_URL_ERRORS.INCOMPATIBILITY })
    }
  }

  if (hosting === 'vk') {
    setValue('hostingId', '')
    setValue('url', url)
  }

  if (hosting === 'vimeo') {
    const vimeoId = getVimeoId(url)
    const vimeoHash = getVimeoHash(url)

    const checkYouTubeUrl = getYouTubeVideoId(url)

    if (vimeoId) {
      setValue('hostingId', vimeoId)
    } else {
      setError('hostingId', { message: VIDEO_URL_ERRORS.ID })
    }

    if (vimeoHash) {
      setValue('hostingHash', vimeoHash)
    } else {
      setError('hostingHash', { message: VIDEO_URL_ERRORS.HASH })
    }

    if (checkYouTubeUrl) {
      setError('hostingId', { message: VIDEO_URL_ERRORS.INCOMPATIBILITY })
      setError('hostingHash', { message: '' })
    }
  }
}

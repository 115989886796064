import { Option } from 'common/types'
import { useGetCoursesQuery } from 'services/courses'
import { getCoursesSelectOptions } from 'utils'

export const useGetCoursesOptions = () => {
  const { data: coursesData } = useGetCoursesQuery()
  const startCoursesSelectOptions: Option[] = [{ label: 'Курс не выбран', value: '' }]
  const courses = coursesData?.items || []

  return getCoursesSelectOptions(courses, startCoursesSelectOptions)
}

import { HTMLAttributes, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Typography, styled } from '@mui/material'
import InfoYellowIcon from 'assets/icons/adaptive-icons/InfoYellowIcon'
import { Screen, uploadFileAPI } from 'common/api/upload-file-api'
import { Center } from 'common/components/center/Center'
import { LoadedImages } from 'common/components/photo-uploader/LoadedImages/LoadedImages'
import { collectFormData, errorHandler } from 'utils'

const Input = styled('input')({
  border: '1px solid #ccc',
  borderRadius: '2px',
})

export type FormInput = {
  file: File[]
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  editMode?: boolean
  screenshots?: Screen[]
  setScreenshots?: (screenshots: Screen[]) => void
  uploadFor: string
}

export const PhotoUploader = ({
  editMode,
  screenshots,
  setScreenshots,
  uploadFor,
  ...rest
}: Props) => {
  const [error, setError] = useState('')

  const { register, resetField, watch } = useForm<FormInput>({
    mode: 'onBlur',
  })

  const files = watch('file')

  useEffect(() => {
    files && files[0] && files[0].name && sendFiles(files)()
  }, [files])

  const sendFiles = (files: File[]) => async () => {
    try {
      const pr = []
      const formData = collectFormData(files[0])
      const data = await uploadFileAPI.sendFiles(formData)

      pr.push(data)
      for (let i = 1; i < files.length; i++) {
        const formData = collectFormData(files[i])

        pr.push(uploadFileAPI.sendFiles(formData))
      }
      const response = await Promise.all(pr)

      if (screenshots !== undefined && setScreenshots !== undefined) {
        setScreenshots([...screenshots, ...response])
      }
      resetField('file')
    } catch (err) {
      setError(errorHandler(err))
      resetField('file')
    }
  }

  const removeImage = async (screenId: string) => {
    try {
      const res = await uploadFileAPI.removeFile(screenId)
      const newArr = screenshots?.filter(s => s.screenId !== res.data.screenId)

      setScreenshots && setScreenshots(newArr as Screen[])
    } catch (err) {
      setError(errorHandler(err))
    }
  }

  // TODO: Update image (don't delete)
  const updateImage = (files: File[], screenId: string) => {
    const formData = collectFormData(files[0])

    uploadFileAPI.updateFile(formData, screenId).then(data => {
      const newArr = screenshots?.map(s => {
        if (s.screenId === data.screenId) {
          return { ...s, screen: data.screen }
        } else {
          return s
        }
      })

      setScreenshots && setScreenshots(newArr as Screen[])
    })
  }

  return (
    <div {...rest}>
      {editMode ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <label>
            <Typography fontWeight={600}>Добавить изображения для {uploadFor}</Typography>
          </label>
          <div>
            <label htmlFor={'file'}>
              <Input
                {...register('file')}
                accept={'image/*'}
                hidden
                id={'file'}
                multiple
                type={'file'}
              />
              <Button color={'primary'} component={'span'} size={'small'} variant={'contained'}>
                Загрузить изображения
              </Button>
            </label>
          </div>
        </div>
      ) : (
        <Typography>Изображения для урока:</Typography>
      )}

      {!!error && <h2 style={{ color: 'red' }}>Error: {error}</h2>}
      {screenshots && screenshots?.length > 0 ? (
        <LoadedImages
          editMode={editMode}
          removeImage={removeImage}
          screenshots={screenshots}
          style={{ marginTop: '24px' }}
          updateImage={updateImage}
        />
      ) : (
        <Center style={{ gap: '20px', marginTop: '32px' }}>
          <InfoYellowIcon />
          <Typography>Тут пока нет изображений!</Typography>
        </Center>
      )}
    </div>
  )
}

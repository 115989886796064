import { useEffect, useState } from 'react'

import { SocketIoApi } from 'common/api/socket-api'
import { PublicEvent } from 'features/chats/constants'
import { Message, useGetUnreadCountsQuery } from 'features/chats/service'
import { PERS_AREA_MESSENGER_TOKEN, restoreState } from 'utils'

export const useChats = (groupId: string) => {
  const [messages, setMessages] = useState<Message[]>([])
  const [scrollTrigger, setScrollTrigger] = useState(false)

  const { data: unreadCountsData, refetch } = useGetUnreadCountsQuery()

  const myUnreadCount = unreadCountsData?.myUnreadCount || 0
  const unreadCountWithoutManager = unreadCountsData?.unreadCountWithoutManager || 0

  const token = restoreState(PERS_AREA_MESSENGER_TOKEN, '')

  const listenChatEvents = () => {
    SocketIoApi.socket?.on(
      PublicEvent.USER_SEND_MESSAGE,
      (data: { groupId: number; message: Message }) => {
        setMessages(messages => [...messages, data.message])
        setScrollTrigger(false)
        refetch()
      }
    )
  }

  useEffect(() => {
    SocketIoApi.socket?.removeListener(PublicEvent.USER_SEND_MESSAGE)

    setMessages([])
  }, [groupId])

  useEffect(() => {
    if (SocketIoApi.socket) {
      listenChatEvents()
    } else {
      SocketIoApi.createConnection(token)
    }
  }, [SocketIoApi.socket])

  return {
    messages,
    myUnreadCount,
    scrollTrigger,
    setMessages,
    setScrollTrigger,
    unreadCountWithoutManager,
  }
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { CodeReviewsArgs, CodeReviewsResponse } from '../service'

export const codeReviewService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_SAMURAI_HUB_BACK_URL + 'api',
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    createFeedback: build.mutation({
      invalidatesTags: () => ['code-review'],
      query: ({ feedbackModel, id: reviewId }) => {
        return {
          body: feedbackModel,
          method: 'POST',
          url: `admin/feedback/${reviewId}`,
        }
      },
    }),
    getCodeReviews: build.query<CodeReviewsResponse, CodeReviewsArgs>({
      providesTags: () => ['code-review'],
      query: ({ courseId, onlyTakenByMe, pageNumber, pageSize, searchNameTerm, status }) => {
        return {
          params: {
            courseId,
            onlyTakenByMe,
            pageNumber,
            pageSize,
            searchNameTerm,
            status: status ? status : undefined,
          },
          url: 'admin/review',
        }
      },
    }),
    publishFeedbacks: build.mutation({
      invalidatesTags: () => ['code-review'],
      query: ({ reviewId, status }) => {
        return {
          body: { status },
          method: 'POST',
          url: `admin/review/${reviewId}/publish`,
        }
      },
    }),
    takeReviewToWork: build.mutation({
      invalidatesTags: () => ['code-review'],
      query: (reviewId: number) => {
        return {
          method: 'POST',
          url: `admin/review/${reviewId}/take-to-work`,
        }
      },
    }),
    updateFeedback: build.mutation({
      invalidatesTags: () => ['code-review'],
      query: ({ feedbackModel, id: feedbackId }) => {
        return {
          body: feedbackModel,
          method: 'PUT',
          url: `admin/feedback/${feedbackId}`,
        }
      },
    }),
    updateReviewStatus: build.mutation({
      invalidatesTags: () => ['code-review'],
      query: ({ reviewId, status }) => {
        return {
          body: { status },
          method: 'PUT',
          url: `admin/review/${reviewId}`,
        }
      },
    }),
  }),
  reducerPath: 'codeReviewService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['code-review'],
})

export const {
  useCreateFeedbackMutation,
  useGetCodeReviewsQuery,
  usePublishFeedbacksMutation,
  useTakeReviewToWorkMutation,
  useUpdateFeedbackMutation,
  useUpdateReviewStatusMutation,
} = codeReviewService

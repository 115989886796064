import { Divider } from '@mui/material'
import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { Work } from 'features/dev-club/service'

import { LinkedIn } from './linked-in/LinkedIn'
import { WorkItem } from './work-item/WorkItem'

type Props = {
  firstName: string
  lastName: string
  linkedIn: string
  onClose: () => void
  open: boolean
  studentId: number
  works: Work[]
}

export const WorksModal = ({
  firstName,
  lastName,
  linkedIn,
  onClose,
  open,
  studentId,
  works,
}: Props) => {
  return (
    <CustomModal
      modalTitle={
        <span>
          Опыт работы <b>{`${lastName} ${firstName}`}</b>
        </span>
      }
      onClose={onClose}
      open={open}
      width={724}
    >
      <LinkedIn linkedIn={linkedIn} studentId={studentId} />

      <Divider />

      {works.map((work: Work, i) => {
        return (
          <WorkItem
            key={work._id}
            showDivider={works.length - 1 !== i}
            studentId={studentId}
            work={work}
            works={works}
          />
        )
      })}
    </CustomModal>
  )
}

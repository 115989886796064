import { instance } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

export type Screen = {
  screen: string
  screenId: string
}

export const uploadFileAPI = {
  removeFile(screenId: string) {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    return instance.delete<Pick<Screen, 'screenId'>>(
      `lessons/screenshot/${screenId}?token=${token}`
    )
  },
  sendFiles(files: FormData) {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    files.set('token', token)

    return instance.post<Screen>('lessons/screenshot', files).then(res => res.data)
  },
  updateFile(files: FormData, screenId: string) {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    files.set('token', token)

    return instance.put<Screen>(`lessons/screenshot/${screenId}`, files).then(res => res.data)
  },
}

import { Tooltip } from '@it-incubator/ui-kit'
import { Notification } from 'common/components/notification/Notification'
import { PATH } from 'common/components/routing/Routing'
import { useChats } from 'features/chats/hooks'

import s from './ChatsCounts.module.scss'

type Props = {
  isNavBarOpen: boolean
  itemPath: string
}

export const ChatsCounts = ({ isNavBarOpen, itemPath }: Props) => {
  const { myUnreadCount, unreadCountWithoutManager } = useChats('')

  return (
    <div className={s.container}>
      {itemPath === PATH.CHATS && isNavBarOpen && !!myUnreadCount && (
        <Tooltip component={<Notification unreadCount={myUnreadCount} />}>
          <p>Количество моих непрочитанных чатов</p>
        </Tooltip>
      )}

      {itemPath === PATH.CHATS && isNavBarOpen && !!unreadCountWithoutManager && (
        <Tooltip
          component={<Notification color={'secondary'} unreadCount={unreadCountWithoutManager} />}
        >
          <p>Количество непрочитанных чатов без ответственного</p>
        </Tooltip>
      )}
    </div>
  )
}

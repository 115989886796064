import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Empty, LoadingOverlay, Pagination } from '@it-incubator/ui-kit'
import { selectAdmin } from 'app'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { useDebounce } from 'common/hooks'
import { TabsValues } from 'features/chats/constants'
import { useChats } from 'features/chats/hooks'
import {
  ChatQueriesType,
  useGetChatsQuery,
  useLoginMessengerMutation,
} from 'features/chats/service'
import {
  LOCAL_STORAGE_QUERIES_KEYS,
  PERS_AREA_MESSENGER_TOKEN,
  restoreState,
  saveState,
} from 'utils'

import s from './Chats.module.css'

import { ChatsFilters } from './chats-filters/ChatsFilters'
import { ChatsTable } from './chats-table/ChatsTable'

export const Chats = () => {
  const [tabValue, setTabValue] = useState<TabsValues>(TabsValues.UNREAD)
  const [search, setSearch] = useState('')
  const [managerId, setManagerId] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(50)

  const [loginMessenger] = useLoginMessengerMutation()
  const admin = useSelector(selectAdmin)

  const debouncedSearch = useDebounce(search, 1500)

  const chatQueries: ChatQueriesType = {
    filters: {
      managerId,
      student: search,
      tab: tabValue,
    },
    sorting: {},
  }

  const {
    data: chatsData,
    isLoading: isChatsDataLoading,
    refetch,
  } = useGetChatsQuery({
    managerId,
    page: pageNumber,
    pageSize,
    student: debouncedSearch,
    tab: tabValue,
  })

  const { myUnreadCount, unreadCountWithoutManager } = useChats('')

  const isLoading = isChatsDataLoading

  useEffect(() => {
    const restoredChatQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.CHAT, chatQueries)
    const { managerId, student: search, tab: tabValue } = restoredChatQueries.filters

    if (managerId) {
      setManagerId(managerId)
    }
    if (search) {
      setSearch(search)
    }
    if (tabValue) {
      setTabValue(tabValue)
    }
  }, [])

  useEffect(() => {
    refetch()
  }, [myUnreadCount, unreadCountWithoutManager])

  useEffect(() => {
    if (admin.id) {
      const { id: userId, name, nickname } = admin
      const [first_name, last_name] = name.split(' ')

      loginMessenger({ first_name, last_name, nickname, userId })
        .unwrap()
        .then(res => {
          saveState(PERS_AREA_MESSENGER_TOKEN, res.jwtToken)
        })
        .catch(() => toast.error('Возникла ошибка авторизации мессенджера'))
    }
  }, [admin])

  if (isLoading) {
    return (
      <div className={s.loadingOverlayContainer}>
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <div>
      <PageHeader className={s.header} title={'Чаты со студентами'} />

      <ChatsFilters
        chatQueries={chatQueries}
        managerId={managerId}
        myUnreadCount={myUnreadCount || 0}
        search={search}
        setManagerId={setManagerId}
        setPage={setPageNumber}
        setSearch={setSearch}
        setTabValue={setTabValue}
        tabValue={tabValue}
        unreadCountWithoutManager={unreadCountWithoutManager || 0}
      />

      {chatsData?.totalCount ? (
        <>
          <ChatsTable students={chatsData.students} />

          <Pagination
            count={Math.ceil((chatsData?.totalCount || 0) / pageSize)}
            onChange={setPageNumber}
            onPerPageChange={(itemPerPage: number) => {
              setPageSize(itemPerPage)
              setPageNumber(1)
            }}
            page={pageNumber}
            perPage={pageSize}
            perPageOptions={[20, 50, 100]}
          />
        </>
      ) : (
        <Empty mt={'12px'} />
      )}
    </div>
  )
}

import { MoodScores } from 'features/mood/data'

type Props = {
  score: number
}

export const ScoresColumn = ({ score }: Props) => {
  return (
    <>
      {score === MoodScores.GOOD && <span style={{ color: '#1EA671', fontWeight: '600' }}>4</span>}

      {score === MoodScores.SATISFACTORY && (
        <span style={{ color: '#E29427', fontWeight: '600' }}>3</span>
      )}

      {score === MoodScores.BAD && <span style={{ color: '#F81951', fontWeight: '600' }}>2</span>}

      {score === MoodScores.TERRIBLE && (
        <span style={{ color: '#F81951', fontWeight: '600' }}>1</span>
      )}
    </>
  )
}

import { SubmitHandler } from 'react-hook-form'

import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { Playlist } from 'features/playlists/service'
import { Video } from 'features/videos/service'
import { Course } from 'services/courses'

import { VideoMainForm } from './video-main-form/VideoMainForm'

type Props = {
  callback: SubmitHandler<Video>
  close: () => void
  courses?: Course[]
  defaultValues?: Video
  isLoading: boolean
  playlists?: Playlist[]
  show: boolean
}

export const VideosModal = ({
  callback,
  close,
  courses,
  defaultValues,
  isLoading,
  playlists,
  show,
}: Props) => {
  const onSubmit = (videoModel: Video) => {
    callback(videoModel)
  }

  return (
    <CustomModal
      modalTitle={defaultValues ? 'Редактировать видео' : 'Новое видео'}
      onClose={close}
      open={show}
      width={670}
    >
      <VideoMainForm
        courses={courses}
        defaultValues={defaultValues}
        isLoading={isLoading}
        playlists={playlists}
        submitHandler={onSubmit}
      />
    </CustomModal>
  )
}

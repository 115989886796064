import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { DevClubUsersArgs, DevClubUsersRes, EditWorkModelType } from '../service'

export const devClubService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    getDevClubMembers: build.query<DevClubUsersRes, DevClubUsersArgs>({
      providesTags: () => ['devClubMembers'],
      query: ({ lastName, page, pageCount, status }) => {
        return {
          params: {
            lastName,
            page,
            pageCount,
            status,
          },
          url: '/work/users',
        }
      },
    }),
    putAdminStatus: build.mutation({
      invalidatesTags: () => ['devClubMembers'],
      query: ({ status, studentId }) => {
        return {
          body: {
            status,
            studentId,
          },
          method: 'PUT',
          url: '/work/admin-status',
        }
      },
    }),
    putStudentWorks: build.mutation({
      invalidatesTags: () => ['devClubMembers'],
      query: (editWorkModel: EditWorkModelType) => {
        return {
          body: editWorkModel,
          method: 'PUT',
          url: '/work/works',
        }
      },
    }),
    putYoutubeLink: build.mutation({
      query: ({ studentId, youtubeUrl }) => {
        return {
          body: {
            studentId,
            youtubeUrl,
          },
          method: 'PUT',
          url: '/work/youtube-url',
        }
      },
    }),
    updateReview: build.mutation({
      onQueryStarted(
        { lastName, page, pageCount, review, status, studentId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          devClubService.util.updateQueryData(
            'getDevClubMembers',
            { lastName, page, pageCount, status },
            draft => {
              const user = draft.users.find(u => u.user.id === studentId)

              user && (user.info.review = review)
            }
          )
        )

        queryFulfilled.catch(patchResult.undo)
      },
      query: ({ review, studentId }) => {
        return {
          body: {
            review,
            studentId,
          },
          method: 'PUT',
          url: '/work/review',
        }
      },
    }),
  }),
  reducerPath: 'devClubService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['devClubMembers'],
})
export const {
  useGetDevClubMembersQuery,
  usePutAdminStatusMutation,
  usePutStudentWorksMutation,
  usePutYoutubeLinkMutation,
  useUpdateReviewMutation,
} = devClubService

import { CircularProgress } from '@mui/material'
import { Mood } from 'features/mood/service'
import { useGetStudentQuery } from 'services/students'

import s from '../../MoodsPage.module.css'

type Props = {
  learningTime: string
  mood: Mood
}

export const StudentInfoList = ({ learningTime, mood }: Props) => {
  const { data, isLoading } = useGetStudentQuery({
    courseId: String(mood.studentId.currentCourseId),
    studentId: mood.studentId.baseId,
  })

  if (isLoading) {
    return (
      <div className={s.listPreloader}>
        <CircularProgress color={'primary'} />
      </div>
    )
  }

  return (
    <ul className={s.list}>
      <li className={s.item}>
        <p className={s.subtitleItem}>Месяцев обучения:</p>
        <p className={s.textItem}>{learningTime}</p>
      </li>

      <li className={s.item}>
        <p className={s.subtitleItem}>Уровень студента факт:</p>
        <p className={s.textItem}>{mood.level} уровень</p>
      </li>

      <li className={s.item}>
        <p className={s.subtitleItem}>Уровень студента план:</p>
        <p className={s.textItem}>{data?.group.level} уровень</p>
      </li>

      <li className={s.item}>
        <p className={s.subtitleItem}>Уровень домашки:</p>
        <p className={s.textItem}>{mood.studentId.homeworkLevel} уровень</p>
      </li>

      <li className={s.item}>
        <p className={s.subtitleItem}>Уровень экзамена:</p>
        <p className={s.textItem}>{mood.studentId.passedExamsCount} уровень</p>
      </li>
    </ul>
  )
}

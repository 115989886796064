import { Control, Controller } from 'react-hook-form'

import { TextField } from '@mui/material'
import { Video } from 'features/videos/service'

type Props = {
  control: Control<Video>
}

export const CodeController = ({ control }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue={''}
      name={'code'}
      render={({ field }) => <TextField fullWidth label={'Session code'} {...field} />}
    />
  )
}

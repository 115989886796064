import s from './CustomTableRows.module.css'

import { CommonColumn } from '../CustomTable'
import { SortColumn, SortDirection } from '../SortColumn/SortColumn'

type Props = {
  arr: CommonColumn[]
  onChange: (sc: string, sd: SortDirection) => void
  sortColumn: string
  sortDirection: string
}

export const CustomTableRows = ({ arr, onChange, sortColumn, sortDirection }: Props) => {
  return (
    <div className={s.sclBox}>
      {arr.map(cl => {
        return (
          <div key={cl.id} style={{ position: 'relative', width: cl.width }}>
            {cl.isSort && (
              <SortColumn
                onChange={onChange}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                sortName={cl.title}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

import { Hosting } from 'features/videos/service'

export const generateVideoUrlFromId = (
  hosting: Hosting,
  hostingId: string,
  hostingHash?: string,
  url?: string
): string => {
  if (hosting === 'youtube') {
    return `https://www.youtube.com/embed/${hostingId}`
  }

  if (hosting === 'vk' && url) {
    return url
  }

  if (hosting === 'vimeo') {
    return `https://player.vimeo.com/video/${hostingId}?h=${hostingHash}`
  }

  return ''
}

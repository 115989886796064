import { Control, Controller, UseFormSetValue } from 'react-hook-form'

import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { Video } from 'features/videos/service'
import moment from 'moment'

import s from './StartDateController.module.css'

type Props = {
  control: Control<Video>
  setValue: UseFormSetValue<Video>
}

export const StartDateController = ({ control, setValue }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue={''}
      name={'startDate'}
      render={({ field }) => (
        <>
          <DatePicker
            label={'Дата'}
            {...field}
            onChange={event =>
              setValue(
                'startDate',
                moment(event).set({ hour: 12, minute: 0, second: 0 }).toISOString() || ''
              )
            }
            slotProps={{
              textField: { error: false, fullWidth: true },
            }}
            value={new Date(field.value)}
          />

          <TimePicker
            className={s.timePicker}
            label={'Время'}
            {...field}
            onChange={event => setValue('startDate', moment(event).toISOString() || '')}
            slotProps={{
              textField: { error: false, fullWidth: true },
            }}
            value={new Date(field.value)}
          />
        </>
      )}
    />
  )
}

import { useEffect, useState } from 'react'

import { Pagination } from '@it-incubator/ui-kit'
import { LoadingOverlay } from 'common/components/loading-overlay/LoadingOverlay'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { CodeReviewQueries, useGetCodeReviewsQuery } from 'features/code-review/service'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState } from 'utils'

import { CodeReviewFilters } from './code-review-filters/CodeReviewFilters'
import { CodeReviewTable } from './code-review-table/CodeReviewTable'

export const CodeReview = () => {
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50)
  const [status, setStatus] = useState<string>('')
  const [onlyTakenByMe, setOnlyTakenByMe] = useState<string>('false')
  const [searchNameTerm, setSearchNameTerm] = useState<string>('')
  const [courseId, setCourseId] = useState<number | string>('')

  const debouncedSearch = useDebounce(searchNameTerm, 1500)

  const codeReviewQueries: CodeReviewQueries = {
    filters: {
      courseId,
      onlyTakenByMe,
      searchNameTerm,
      status,
    },
    sorting: {},
  }

  const { data, isFetching, isLoading } = useGetCodeReviewsQuery({
    courseId,
    onlyTakenByMe,
    pageNumber,
    pageSize,
    searchNameTerm: debouncedSearch,
    status,
  })

  const { data: courses } = useGetCoursesQuery()

  const totalPages = Math.ceil((data?.totalCount || 0) / pageSize)

  const perPageChangeHandler = (itemPerPage: number) => {
    setPageSize(itemPerPage)
    setPageNumber(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredCodeReviewQueries = restoreState(
      LOCAL_STORAGE_QUERIES_KEYS.CODE_REVIEW,
      codeReviewQueries
    )
    const { courseId, onlyTakenByMe, searchNameTerm, status } = restoredCodeReviewQueries.filters

    if (status) {
      setStatus(status)
    }
    if (onlyTakenByMe) {
      setOnlyTakenByMe(onlyTakenByMe + '')
    }
    if (searchNameTerm) {
      setSearchNameTerm(searchNameTerm)
    }
    if (courseId) {
      setCourseId(courseId)
    }
  }, [])

  return (
    <div>
      <PageHeader title={'Code review'} />

      <CodeReviewFilters
        codeReviewQueries={codeReviewQueries}
        courseId={courseId}
        onlyTakenByMe={onlyTakenByMe}
        searchNameTerm={searchNameTerm}
        setCourseId={setCourseId}
        setOnlyTakenByMe={setOnlyTakenByMe}
        setPage={setPageNumber}
        setSearchNameTerm={setSearchNameTerm}
        setStatus={setStatus}
        status={status}
      />

      <CodeReviewTable codeReviews={data?.reviews ?? []} courses={courses?.items} />

      <LoadingOverlay open={isLoading || isFetching} />

      <Pagination
        count={totalPages}
        onChange={setPageNumber}
        onPerPageChange={perPageChangeHandler}
        page={pageNumber}
        perPage={pageSize}
        perPageOptions={[20, 50, 100]}
      />
    </div>
  )
}

import { useState } from 'react'

import ok from 'assets/icons/completedIcon.svg'
import fail from 'assets/icons/failIcon.svg'
import review from 'assets/icons/inProgressIcon.svg'
import { Button } from 'common/components/button-pink/Button'
import { instance } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import s from './HomeworkUser.module.css'

import { UserNewHomework } from './HomeworkChecked'

type Props = {
  checkH: (id: string) => void
  checked?: boolean
  h: UserNewHomework
  u_id: string
  updateUser: (id: string, homeworks: UserNewHomework[]) => void
}

const getStatus = (ch: boolean, done: boolean) => {
  if (ch && done) {
    return (
      <span>
        <img alt={'ok'} src={ok} /> Готово!
      </span>
    )
  }
  if (!ch && done) {
    return (
      <span>
        <img alt={'review'} src={review} /> На проверке!
      </span>
    )
  }
  if (!ch && !done) {
    return 'Делает...'
  }
  if (ch && !done) {
    return (
      <span>
        <img alt={'fail'} src={fail} /> Не правильно!
      </span>
    )
  }
}

export const UserHomework = ({ checkH, checked, h, u_id, updateUser }: Props) => {
  const [com, setCom] = useState(h.mentorComment)
  const [del, setDel] = useState(false)

  const onClick = (done: boolean) => {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    instance
      .post('homework/check', {
        blockId: h.blockId,
        checked: true,
        courseId: h.courseId,
        done,
        homework_id: h.homeworkId,
        mentorComment: com,
        token,
        uniqId: h.uniqId,
        user_id: u_id,
      })
      .then(res => {
        updateUser(u_id, res.data.newHomeworks)
      })
  }
  const onDelete = () => {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    instance
      .put('homework/del-check', {
        blockId: h.blockId,
        courseId: h.courseId,
        homework_id: h.homeworkId,
        token,
        uniqId: h.uniqId,
        user_id: u_id,
      })
      .then(res => {
        updateUser(u_id, res.data.newHomeworks)
      })
  }

  return (
    <div className={s.homework}>
      <div
        className={s.homeworkHeader}
        onClick={() => {
          checkH(checked ? '' : '' + h.homeworkId + h.blockId + h.uniqId)
          console.log('' + h.homeworkId + h.blockId + h.uniqId)
        }}
      >
        <div>
          <div className={s.plus}>{!checked ? '+' : '-'}</div>
          Домашнее задание #{h.accessLevel} courseId: {h.courseId} block: {h.blockId} type:{' '}
          {h.checkType} uniq: {h.uniqId}
        </div>
        {getStatus(h.checked, h.done)}
      </div>

      {checked && (
        <div>
          {/*<div className={s.status}>*/}
          {/*    {getStatus(h.checked, h.done)}*/}
          {/*</div>*/}
          <div className={s.h3}>Комментарий от студента:</div>
          <pre className={s.studentCom}>{h.userComment}</pre>

          <div className={s.h3}>
            Комментарий от ментора:
            <span>продУрл: </span>
            <a
              href={h.productionUrl}
              rel={'noreferrer'}
              style={{ color: '#88aaff' }}
              target={'_blank'}
            >
              {h.productionUrl}
            </a>
          </div>
          <div className={s.answerBlock}>
            <textarea className={s.input} onChange={e => setCom(e.target.value)} value={com} />

            <Button onClick={() => onClick(true)} style={{ color: 'white' }}>
              Принять
            </Button>

            <Button onClick={() => setDel(true)} style={{ color: 'white' }}>
              Удалить
            </Button>
            <Button onClick={() => onClick(false)} style={{ color: 'white' }}>
              Ошибка
            </Button>
          </div>
          {del && (
            <div style={{ border: '1px solid red', padding: 8 }}>
              удалить?
              <Button onClick={() => setDel(false)} style={{ color: 'white' }}>
                Нет
              </Button>
              <Button
                onClick={() => {
                  setDel(false)
                  onDelete()
                }}
                style={{ color: 'white' }}
              >
                Да
              </Button>
            </div>
          )}
          {com !== h.mentorComment && <span style={{ color: '#F51A51' }}> не сохранено!</span>}
          <div>
            {(h.commonFailureMessages || []).map((m, i) => (
              <pre key={i}>{m}</pre>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

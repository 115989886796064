import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'

import { CustomTable } from 'common/components/custom-table/CustomTable'
import { LoadingOverlay } from 'common/components/loading-overlay/LoadingOverlay'
import { Playlist } from 'features/playlists/service'
import { videosTableData } from 'features/videos/data'
import { Video, useChangeVideosOrderMutation } from 'features/videos/service'
import { Course } from 'services/courses'

import { VideosTableRow } from './videos-table-row/VideosTableRow'

type Props = {
  courseId: number | string
  courses?: Course[]
  playlistId: string
  playlists?: Playlist[]
  setSettingIds: Dispatch<SetStateAction<string[]>>
  settingIds: string[]
  videosData?: Video[]
}

export const VideosTable = ({
  courseId,
  courses,
  playlistId,
  playlists,
  setSettingIds,
  settingIds,
  videosData,
}: Props) => {
  const [videos, setVideos] = useState(videosData)

  const [changeVideosOrder, { isLoading }] = useChangeVideosOrderMutation()

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(videos ?? [])

    const destinationItemOrder = items[result.destination.index].order

    const [reorderedItem] = items.splice(result.source.index, 1)

    items.splice(result.destination.index, 0, reorderedItem)

    setVideos(items)

    changeVideosOrder({
      courseId,
      order: destinationItemOrder,
      playlistId,
      videoId: result.draggableId,
    })
      .unwrap()
      .then(res => {
        if (res) {
          if (res.response.statusCode === 404) {
            toast.error(res.message || 'Произошла ошибка')
            setVideos(videosData)
          }
        }
      })
      .catch(error => {
        toast.error(error.data.message || 'Произошла ошибка')
        setVideos(videosData)
      })
  }

  useEffect(() => {
    setVideos(videosData)
  }, [videosData])

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <CustomTable
          onChange={() => {}}
          sortColumn={''}
          sortDirection={''}
          tableData={videosTableData}
        >
          <Droppable droppableId={'videos'}>
            {provided => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {videos?.map((video, index) => (
                    <VideosTableRow
                      courseId={courseId}
                      courses={courses}
                      index={index}
                      key={video.id}
                      playlistId={playlistId}
                      playlists={playlists}
                      setSettingIds={setSettingIds}
                      settingIds={settingIds}
                      video={video}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </CustomTable>
      </DragDropContext>

      <LoadingOverlay open={isLoading} />
    </>
  )
}

import { Dispatch, SetStateAction } from 'react'

import { Playlist } from 'features/playlists/service'
import { useGetCoursesQuery } from 'services/courses'
import { capitalizeFirstLetter } from 'utils'

import s from './CoursesAccessCell.module.css'

type Props = {
  playlist: Playlist
  setShowShort: Dispatch<SetStateAction<boolean>>
  showShort: boolean
}

export const CoursesAccessCell = ({ playlist, setShowShort, showShort }: Props) => {
  const { data: courses } = useGetCoursesQuery()

  const shortCount = 1

  const onChangeShow = () => setShowShort(!showShort)

  return (
    <div className={s.container}>
      {courses?.items.map(course => {
        return [...playlist.settings]
          .sort((a, b) => a.courseId - b.courseId)
          .map((setting, i) => {
            const regularArrayCondition = setting.courseId === course.id
            const shortArrayCondition = regularArrayCondition && i < shortCount

            const finalRenderCondition = showShort ? shortArrayCondition : regularArrayCondition

            return (
              finalRenderCondition && (
                <span className={s.mainContent} key={setting.id}>
                  {`${capitalizeFirstLetter(course.title)} (${setting.accessLevel} уровень)`}
                </span>
              )
            )
          })
      })}

      {playlist.settings.length > shortCount && (
        <div className={s.showOrHideButton} onClick={onChangeShow}>
          {showShort ? 'Показать ещё' : 'Скрыть'}
        </div>
      )}
    </div>
  )
}

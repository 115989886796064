import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FaqArgs, FaqModel, FaqsResponse } from 'features/faq/service/types'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

export const faqService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_SAMURAI_HUB_BACK_URL + 'api',
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    createFaq: build.mutation({
      invalidatesTags: () => ['faq'],
      query: (faqModel: FaqModel) => {
        return {
          body: faqModel,
          method: 'POST',
          url: 'faq/admin',
        }
      },
    }),
    deleteFaq: build.mutation({
      invalidatesTags: () => ['faq'],
      query: (faqId: number) => {
        return {
          method: 'DELETE',
          url: `faq/admin/${faqId}`,
        }
      },
    }),
    getFaqs: build.query<FaqsResponse, FaqArgs>({
      providesTags: () => ['faq'],
      query: ({ courseId, pageNumber, pageSize, searchTerm }) => {
        return {
          params: {
            courseId,
            pageNumber,
            pageSize,
            searchTerm,
          },
          url: 'faq/admin',
        }
      },
    }),
    updateFaq: build.mutation({
      invalidatesTags: () => ['faq'],
      query: ({ faqModel, id: faqId }) => {
        return {
          body: faqModel,
          method: 'PUT',
          url: `faq/admin/${faqId}`,
        }
      },
    }),
  }),
  reducerPath: 'faqService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['faq'],
})

export const { useCreateFaqMutation, useDeleteFaqMutation, useGetFaqsQuery, useUpdateFaqMutation } =
  faqService

import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'

import { TextField } from '@mui/material'
import { uiActions } from 'app'
import { useActions } from 'common/hooks'
import { usePutYoutubeLinkMutation } from 'features/dev-club/service'

import { ConfirmSavingModal } from '../../dev-club-modals/confirm-saving-modal/ConfirmSavingModal'

type Props = {
  firstName: string
  lastName: string
  studentId: number
  youtubeUrl: string
}

export const YoutubeLinkInput = ({ firstName, lastName, studentId, youtubeUrl }: Props) => {
  const { showSnackbar } = useActions(uiActions)

  const [value, setValue] = useState(youtubeUrl)
  const [currentYoutubeUrl, setCurrentYoutubeUrl] = useState(youtubeUrl)
  const [open, setOpen] = useState(false)

  const [putYoutubeLink, { isLoading }] = usePutYoutubeLinkMutation()

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setValue(event.currentTarget.value)

  const handleSubmit = () => {
    putYoutubeLink({
      studentId: studentId,
      youtubeUrl: value,
    })
      .unwrap()
      .then(res => {
        showSnackbar({
          message: 'Youtube link updated',
          severity: 'success',
        })
        setCurrentYoutubeUrl(res.youtubeUrl)
      })
      .catch(err => {
        showSnackbar({
          message: err.message,
          severity: 'error',
        })
      })
      .finally(() => setOpen(false))
  }

  const openSavingModal = () => {
    if (value !== currentYoutubeUrl) {
      setOpen(true)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      openSavingModal()
    }
  }

  const handleClose = () => {
    setOpen(false)
    setValue(currentYoutubeUrl)
  }

  useEffect(() => {
    if (value !== youtubeUrl) {
      setValue(youtubeUrl)
    }
  }, [youtubeUrl])

  return (
    <>
      <TextField
        disabled={isLoading}
        onBlur={openSavingModal}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={'Вставьте ссылку на отзыв'}
        sx={{ width: '100%' }}
        value={value}
      />
      <ConfirmSavingModal
        firstName={firstName}
        isLoading={isLoading}
        lastName={lastName}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
      />
    </>
  )
}

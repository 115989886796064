import { useNavigate } from 'react-router-dom'

import { Button, Error404 } from '@it-incubator/ui-kit'

import s from './NotFoundPage.module.css'

export const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <div className={s.mainBlock}>
      <div className={s.block}>
        <div className={s.leftBlock}>
          <div>
            <h2>Упс!</h2>
            <h3>Страница не найдена</h3>
          </div>
          <Button onClick={() => navigate(-1)} variant={'secondary'}>
            Вернуться назад
          </Button>
        </div>
        <div className={s.rightBlock}>
          <Error404 />
        </div>
      </div>
    </div>
  )
}

import { ReactNode, memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from '@it-incubator/ui-kit'
import { Alert, Snackbar } from '@mui/material'
import { appThunks, selectAppError, selectIsLoading, selectIsVerified } from 'app'
import { useActions } from 'common/hooks'

import s from './AuthRedirectPage.module.css'

import { Laba } from '../links/Laba'

type Props = {
  children: ReactNode
}

const AuthRedirectPage = memo(({ children }: Props) => {
  const error = useSelector(selectAppError)
  const isLoading = useSelector(selectIsLoading)
  const isVerified = useSelector(selectIsVerified)

  const [isFirstRendering, setFirstRendering] = useState<boolean>(true)
  const [isRedirect, setRedirect] = useState<boolean>(false)
  const [spin, setSpin] = useState<boolean>(!isVerified)
  const { loginThunk } = useActions({ ...appThunks })
  const [openError, setOpenError] = useState<string>()

  useEffect(() => {
    if (isFirstRendering) {
      if (!isVerified) {
        loginThunk({})
      }
      setFirstRendering(false) // + rerender
    } else {
      if (!isVerified && !isLoading && !isRedirect) {
        setOpenError('Not logged in!')
        setRedirect(true)
      } else {
        spin && isVerified && setSpin(false)
      }
    }
  }, [
    isFirstRendering,
    setFirstRendering,
    isVerified,
    setRedirect,
    spin,
    setSpin,
    isLoading,
    isRedirect,
    loginThunk,
    error,
  ])

  if (isRedirect) {
    return (
      <div className={s.redirect}>
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={6000}
          onClose={() => setOpenError('')}
          open={!!openError}
        >
          <Alert severity={'error'}>{openError}</Alert>
        </Snackbar>
        <Spinner />
        <span className={s.text}>For login - go to:</span>
        <Laba />
      </div>
    )
  }
  if (spin) {
    return <Spinner />
  }

  return <>{children}</>
})

export default AuthRedirectPage

import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { Combobox, Select, TextField } from '@it-incubator/ui-kit'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { Option } from 'common/types'
import { CommentQueries } from 'features/comments/service'
import { Course } from 'services/courses'
import { User } from 'services/users'
import { LOCAL_STORAGE_QUERIES_KEYS, getCoursesSelectOptions, saveState } from 'utils'

import s from './CommentsFilters.module.css'

type Props = {
  commentsQueries: CommentQueries
  courseId: string
  courses: Course[]
  search: string
  setCourseId: Dispatch<SetStateAction<string>>
  setPage: Dispatch<SetStateAction<number>>
  setSearch: Dispatch<SetStateAction<string>>
  setVideoAuthor: Dispatch<SetStateAction<string>>
  users: User[]
  videoAuthor: string
}

export const CommentsFilters = ({
  commentsQueries,
  courseId,
  courses,
  search,
  setCourseId,
  setPage,
  setSearch,
  setVideoAuthor,
  users,
  videoAuthor,
}: Props) => {
  const [mentor, setMentor] = useState('')

  const mentorsSelectOptions: { label: string; value: number | string }[] = []

  users.forEach(user => {
    mentorsSelectOptions.push({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })
  })

  const startCoursesSelectOptions: Option[] = [{ label: 'Все', value: '' }]
  const coursesSelectOptions = getCoursesSelectOptions(courses, startCoursesSelectOptions)

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearch = event.currentTarget.value

    setSearch(newSearch)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COMMENTS, {
      ...commentsQueries,
      filters: { ...commentsQueries.filters, search: newSearch },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COMMENTS, {
      ...commentsQueries,
      filters: { ...commentsQueries.filters, search: '' },
    })
  }

  const onChangeVideoAuthor = (newVideoAuthor: null | string) => {
    setVideoAuthor(newVideoAuthor || '')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COMMENTS, {
      ...commentsQueries,
      filters: { ...commentsQueries.filters, videoAuthor: newVideoAuthor },
    })
  }

  const onChangeCourseId = (newCourseId: string) => {
    setCourseId(newCourseId)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COMMENTS, {
      ...commentsQueries,
      filters: { ...commentsQueries.filters, courseId: newCourseId },
    })
  }

  return (
    <div>
      <TextField
        className={s.search}
        onChange={onChangeSearch}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <div className={s.selectsContainer}>
        <div className={s.combobox}>
          <Combobox
            inputValue={mentor}
            label={'ФИО преподавателя'}
            onChange={onChangeVideoAuthor}
            onInputChange={setMentor}
            options={mentorsSelectOptions}
            placeholder={'ФИО преподавателя'}
            value={videoAuthor}
          />
        </div>

        <Select
          label={'Курс'}
          onChange={onChangeCourseId}
          options={coursesSelectOptions}
          value={courseId}
          width={300}
        />
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Snackbar } from '@it-incubator/ui-kit'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { appThunks, selectIsDarkTheme, selectIsVerified } from 'app'
import { CustomSnackbar } from 'common/components/custom-snackbar/CustomSnackbar'
import { Layout } from 'common/components/layout/Layout'
import { Routing } from 'common/components/routing/Routing'
import { useActions, useAppSelector } from 'common/hooks'
import { getTheme } from 'common/theme'

export const Main = () => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme)
  const isVerified = useSelector(selectIsVerified)

  const { me } = useActions(appThunks)

  useEffect(() => {
    if (isVerified) {
      me({})
    }
  }, [isVerified])

  return (
    <ThemeProvider theme={getTheme(isDarkTheme)}>
      <CssBaseline />
      <CustomSnackbar />
      <Layout>
        <Routing />
      </Layout>
      <Snackbar />
    </ThemeProvider>
  )
}

import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { Select, TextField } from '@it-incubator/ui-kit'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { Option } from 'common/types'
import { CodeReviewQueries, ReviewStatusEnum } from 'features/code-review/service'
import { LOCAL_STORAGE_QUERIES_KEYS, saveState } from 'utils'

import s from './CodeReviewFilters.module.css'

type Props = {
  codeReviewQueries: CodeReviewQueries
  courseId: number | string
  onlyTakenByMe: string
  searchNameTerm: string
  setCourseId: Dispatch<SetStateAction<number | string>>
  setOnlyTakenByMe: Dispatch<SetStateAction<string>>
  setPage: Dispatch<SetStateAction<number>>
  setSearchNameTerm: Dispatch<SetStateAction<string>>
  setStatus: Dispatch<SetStateAction<string>>
  status: string
}

export const CodeReviewFilters = ({
  codeReviewQueries,
  courseId,
  onlyTakenByMe,
  searchNameTerm,
  setCourseId,
  setOnlyTakenByMe,
  setPage,
  setSearchNameTerm,
  setStatus,
  status,
}: Props) => {
  const statusSelectOptions: Option[] = [
    { label: 'Все', value: '' },
    { label: 'Новый', value: ReviewStatusEnum.NEW },
    { label: 'В процессе', value: ReviewStatusEnum.IN_PROGRESS },
    { label: 'Принято', value: ReviewStatusEnum.ACCEPTED },
    { label: 'Отклонен', value: ReviewStatusEnum.REJECTED },
  ]

  const mentorSelectOptions: Option[] = [
    { label: 'Все', value: 'false' },
    { label: 'Я', value: 'true' },
  ]

  const courseSelectOptions: Option[] = [
    { label: 'Все', value: '' },
    { label: 'Front-end', value: '1' },
    { label: 'Back-end', value: '3' },
  ]

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchNameTerm = event.currentTarget.value

    setSearchNameTerm(searchNameTerm)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CODE_REVIEW, {
      ...codeReviewQueries,
      filters: { ...codeReviewQueries.filters, searchNameTerm },
    })
  }

  const onClearClick = () => {
    setSearchNameTerm('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CODE_REVIEW, {
      ...codeReviewQueries,
      filters: { ...codeReviewQueries.filters, searchNameTerm: '' },
    })
  }

  const onChangeStatus = (newStatus: string) => {
    setStatus(newStatus)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CODE_REVIEW, {
      ...codeReviewQueries,
      filters: { ...codeReviewQueries.filters, status: newStatus },
    })
  }

  const onChangeMentor = (onlyTakenByMe: string) => {
    setOnlyTakenByMe(onlyTakenByMe)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CODE_REVIEW, {
      ...codeReviewQueries,
      filters: { ...codeReviewQueries.filters, onlyTakenByMe },
    })
  }

  const onChangeCourseId = (newCourseId: string) => {
    setCourseId(newCourseId)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.CODE_REVIEW, {
      ...codeReviewQueries,
      filters: { ...codeReviewQueries.filters, courseId: newCourseId },
    })
  }

  return (
    <div>
      <TextField
        className={s.search}
        onChange={onChangeSearch}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={searchNameTerm}
      />

      <div className={s.selectsContainer}>
        <Select
          label={'Статус'}
          onChange={onChangeStatus}
          options={statusSelectOptions}
          value={status}
          width={300}
        />

        <Select
          label={'Ментор'}
          onChange={onChangeMentor}
          options={mentorSelectOptions}
          value={onlyTakenByMe}
          width={300}
        />

        <Select
          label={'Курс'}
          onChange={onChangeCourseId}
          options={courseSelectOptions}
          value={courseId + ''}
          width={300}
        />
      </div>
    </div>
  )
}

import { SortDirection } from 'common/components/custom-table/SortColumn/SortColumn'
import { Admin } from 'common/types'

export type AdminCvsResponse = {
  admin: Admin
  cvs: CV[]
  totalCount: number
}

export type CV = {
  __v: number
  _id: string
  checks: CvCheck[]
  created: string
  cvDate: string
  lastName: string
  mentor?: string
  mentorStatus?: MentorStatus
  myCV: string
  myCVFileId: string
  myCVName: string
  myCVServiceId: string
  status: string
  student: Student
  studentId: number
  updated: string
}

export type MentorStatus = 'await' | 'done' | 'in work' | 'not done' | 'review' | 'work'

export enum MentorStatusEnum {
  AWAIT = 'await',
  DONE = 'done',
  IN_WORK = 'in work',
  NOT_DONE = 'not done',
  REVIEW = 'review',
  WORK = 'work',
}

export type CvCheck = {
  _id: string
  blockId: string
  checkList: CheckList[]
}

export type CheckList = {
  _id: string
  checkId: string
  done: boolean
  required: boolean
}

export type Student = {
  _id: string
  answers: Answers
  avatar: string
  baseId: number
  city: string
  country: string
  courseData: CourseData[]
  courseId: number
  courseTitle: string
  created: string
  currentCourseId: number
  cv: StudentCv
  email: string
  employed: boolean
  firstName: string
  groupsIds: number[]
  inactive: boolean
  lastName: string
  lastUpdateDate: string
  level: number
  linkedIn: string
  passedExamsCount: number
  portfolioURL: string
  telegramId: number
  updated: string
}

export type CourseData = {
  _id: string
  courseId: number
  courseTitle: string
  inactive: boolean
  level: number
}

export type Answers = {
  _id: string
  children: number
  educationalInstitution: string
  english: number
  familyStatus: number
  finishedCourses: string
  inProgressCourses: string
  inWorkLearnTime: number
  involvement: number
  isITFamily: boolean
  isOtherLanguages: boolean
  isWorking: boolean
  mathLvl: string
  otherLanguages: string
  speciality: string
  support: number
  toKnowIncubatorNumber: number
  weekLearnTime: string
}

export type StudentCv = {
  _id: string
  cvChecks: any[]
  friendCVChecks: any[]
}

export type AdminCvsArgs = {
  courseId?: number | string
  cvMentor: string
  itemForPageCount: number
  lastName: string
  mentorStatus?: MentorStatus
  pageNumber: number
  sort: string
}

export type CvQueries = {
  filters: {
    courseId?: number | string
    cvMentor: string
    lastName: string
    mentorStatus: MentorStatus
  }
  sorting: {
    sort: string
    sortColumn: string
    sortDirection: SortDirection
  }
}

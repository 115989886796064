import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { InputLabel, Select, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { DevClubQueries, StatusType } from 'features/dev-club/service'
import { LOCAL_STORAGE_QUERIES_KEYS, saveState } from 'utils'

type Props = {
  devClubQueries: DevClubQueries
  lastName: string
  setLastName: (lastName: string) => void
  setPage: Dispatch<SetStateAction<number>>
  setStatus: (status: StatusType) => void
  status: StatusType
}

export const DevClubFilters = ({
  devClubQueries,
  lastName,
  setLastName,
  setPage,
  setStatus,
  status,
}: Props) => {
  const onLastNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const lastName = event.target.value

    setLastName(lastName)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.DEV_CLUB, {
      ...devClubQueries,
      filters: { ...devClubQueries.filters, lastName },
    })
  }

  const onStatusChange = (event: SelectChangeEvent) => {
    const status = event.target.value as StatusType

    setStatus(status)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.DEV_CLUB, {
      ...devClubQueries,
      filters: { ...devClubQueries.filters, status },
    })
  }

  return (
    <div style={{ display: 'flex', gap: '20px', margin: '17px 0 24px' }}>
      <TextField
        fullWidth
        label={'Поиск по фамилии/имени'}
        onChange={onLastNameChange}
        value={lastName}
      />

      <div>
        <FormControl variant={'outlined'}>
          <InputLabel>Cтатус</InputLabel>
          <Select label={'Статус'} onChange={onStatusChange} sx={{ width: '204px' }} value={status}>
            <MenuItem value={''}>Все</MenuItem>
            <MenuItem value={'success'}>Принят</MenuItem>
            <MenuItem value={'reject'}>Отклонен</MenuItem>
            <MenuItem value={'filling'}>Заполняет</MenuItem>
            <MenuItem value={'pending'}>Ожидает</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

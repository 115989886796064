import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { scrollbarViewportSelector } from 'features/chats/constants'
import { Message } from 'features/chats/service'

export const useInfinityScroll = (
  messages: Message[],
  setTime: (time: string) => void,
  setScrollTrigger: Dispatch<SetStateAction<boolean>>
) => {
  const [scrollBarViewport, setScrollBarViewport] = useState<Element | null>(null)

  useEffect(() => {
    const scrollBarViewport = document.querySelector(scrollbarViewportSelector)

    setScrollBarViewport(scrollBarViewport)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (messages.length) {
        if (scrollBarViewport) {
          if (scrollBarViewport.scrollTop < 350) {
            setTime(messages[0].time)
            setScrollTrigger(true)
          }

          if (scrollBarViewport.scrollTop === 0) {
            scrollBarViewport.scrollTo({ top: 1 })
          }
        }
      }
    }

    scrollBarViewport?.addEventListener('scroll', handleScroll)

    return () => {
      scrollBarViewport?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollBarViewport?.scrollTop, messages])
}

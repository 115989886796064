import { Control, Controller } from 'react-hook-form'

import { SANITIZE_HTML_OPTIONS } from 'common/constants'
import { Video } from 'features/videos/service'
import sanitizeHtml from 'sanitize-html'
import SunEditor, { buttonList } from 'suneditor-react'

import s from './DescriptionController.module.css'

type Props = {
  control: Control<Video>
}

export const DescriptionController = ({ control }: Props) => {
  return (
    <Controller
      control={control}
      name={'description'}
      render={({ field }) => (
        <div className={s.container}>
          <div className={s.text}>Описание видео:</div>
          <SunEditor
            {...field}
            onChange={event => field.onChange(sanitizeHtml(event, SANITIZE_HTML_OPTIONS))}
            setContents={field.value}
            setDefaultStyle={'p {margin: 0px;}'}
            setOptions={{
              buttonList: [...buttonList.complex, ['textStyle']],
              height: '200px',
            }}
          />
        </div>
      )}
    />
  )
}

import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { CustomTable } from 'common/components/custom-table/CustomTable'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { MoodScores } from 'features/mood/data'
import { Mood } from 'features/mood/service'
import moment from 'moment'
import { Course } from 'services/courses'
import { capitalizeFirstLetter } from 'utils'

import s from '../MoodsPage.module.css'

import { StudentInfoList } from './student-info-list/StudentInfoList'

type Props = {
  courses?: Course[]
  learningTime: string
  mood: Mood
  onCLose: () => void
  onOpen: boolean
}

export const MoodModal = ({ courses, learningTime, mood, onCLose, onOpen }: Props) => {
  return (
    <CustomModal
      modalTitle={'Информация про студента'}
      onClose={onCLose}
      open={onOpen}
      width={1012}
    >
      <PageHeader title={`${mood.studentId.firstName} ${mood.studentId.lastName}`} />

      <p className={s.text}>
        {courses?.map(
          course =>
            mood.studentId.currentCourseId === course.id && capitalizeFirstLetter(course.title)
        )}
      </p>

      <StudentInfoList learningTime={learningTime} mood={mood} />

      <CustomTable
        onChange={() => {}}
        sortColumn={''}
        sortDirection={''}
        tableData={[
          {
            id: 1,
            isSort: false,
            name: moment(mood.updated).format('DD/MM/YYYY'),
            title: 'date',
            width: '100%',
          },
        ]}
      >
        <div className={s.contentModalTable}>
          <div className={s.contentModalBlock}>
            <h3 className={s.contentModalSubtitle}>Как настроение?</h3>

            <h4 className={s.contentModalText}>
              {mood.mood === MoodScores.GOOD && 'Супер, гребу во всю мощь'}
              {mood.mood === MoodScores.SATISFACTORY && 'Хорошее, в своем темпе'}
              {mood.mood === MoodScores.BAD && 'Переживаю'}
              {mood.mood === MoodScores.TERRIBLE && 'Печальное, тону'}
            </h4>

            {mood.moodFeedback && <p className={s.contentModalDescription}>{mood.moodFeedback}</p>}
          </div>

          <div className={s.contentModalBlock}>
            <h3 className={s.contentModalSubtitle}>Хватает времени на обучение?</h3>

            <h4 className={s.contentModalText}>
              {mood.learningTime === MoodScores.GOOD && 'Да, очень много'}
              {mood.learningTime === MoodScores.SATISFACTORY && 'Нормально'}
              {mood.learningTime === MoodScores.BAD && 'Мало'}
              {mood.learningTime === MoodScores.TERRIBLE && 'Вообще не хватает'}
            </h4>

            {mood.learningTimeFeedback && (
              <p className={s.contentModalDescription}>{mood.learningTimeFeedback}</p>
            )}
          </div>

          <div className={s.contentModalBlock}>
            <h3 className={s.contentModalSubtitle}>На твой взгляд как тебе программа обучения?</h3>

            <h4 className={s.contentModalText}>
              {mood.programComplexity === MoodScores.GOOD && 'Очень легко'}
              {mood.programComplexity === MoodScores.SATISFACTORY && 'Нормально'}
              {mood.programComplexity === MoodScores.BAD && 'Сложновато'}
              {mood.programComplexity === MoodScores.TERRIBLE && 'Очень сложно'}
            </h4>

            {mood.programComplexityFeedback && (
              <p className={s.contentModalDescription}>{mood.programComplexityFeedback}</p>
            )}
          </div>
        </div>
      </CustomTable>
    </CustomModal>
  )
}

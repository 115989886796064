import { useState } from 'react'

import { Pagination, Reaction, Select } from '@it-incubator/ui-kit'

import s from './lesson-reviews.module.css'

import { Reactions, ReactionsDataWithCount, reactionsData } from '../data/reactions'
import { useGetCoursesOptions } from '../hooks/useGetCoursesOptions'
import { useGetLessonsReviewsQuery } from '../service'
import { LessonReviewsTable } from './lesson-reviews-table'

export const LessonReviews = () => {
  const [page, setPage] = useState(1)
  const [itemsForPage, setItemsForPage] = useState(50)
  const [mentorId, setMentorId] = useState('')
  const [reaction, setReaction] = useState('')

  const [courseId, setCourseId] = useState('')
  const coursesSelectOptions = useGetCoursesOptions()

  const { data } = useGetLessonsReviewsQuery({
    courseId,
    itemsForPage,
    mentorId,
    page,
    reaction,
  })

  if (!data) {
    return null
  }

  const mentorOptions = [
    { label: 'All', value: '' },
    ...data.mentors.map(mentor => ({
      label: mentor.name,
      value: mentor.id,
    })),
  ]

  const changeMentorHandler = (value: string) => {
    setMentorId(value)
    setPage(1)
  }

  const changeCourseHandler = (value: string) => {
    setCourseId(value)
    setPage(1)
  }

  const changeReactionHandler = (itemReaction: Reactions) => {
    if (reaction === itemReaction) {
      setReaction('')
    } else {
      setReaction(itemReaction)
    }

    setPage(1)
  }

  const changePerPageHandler = (itemPerPage: number) => {
    setItemsForPage(itemPerPage)
    setPage(1)
  }

  const reactionsDataWithCount = reactionsData.reduce<ReactionsDataWithCount[]>((acc, item) => {
    const findReaction = data.reactions.find(reaction => reaction.r === item.reaction)

    if (findReaction) {
      acc.push({ ...item, count: findReaction.count })
    }

    return acc
  }, [])

  return (
    <div>
      <div className={s.filterBox}>
        <Select
          label={'Mentor'}
          onChange={changeMentorHandler}
          options={mentorOptions}
          value={mentorId}
          width={300}
        />

        <Select
          label={'Курс'}
          onChange={changeCourseHandler}
          options={coursesSelectOptions}
          value={courseId}
          width={300}
        />
      </div>

      <div className={s.reactionsContainer}>
        {reactionsDataWithCount.map(item => (
          <Reaction
            count={item.count}
            icon={item.icon}
            key={item.reaction}
            onPressedChange={() => changeReactionHandler(item.reaction)}
            pressed={reaction === item.reaction}
            text={item.title}
          />
        ))}
      </div>

      <LessonReviewsTable reviews={data.reviews} />

      <Pagination
        count={Math.ceil((data?.totalCount || 0) / itemsForPage)}
        onChange={setPage}
        onPerPageChange={changePerPageHandler}
        page={page}
        perPage={itemsForPage}
        perPageOptions={[20, 50, 100]}
      />
    </div>
  )
}

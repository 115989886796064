import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  className: string
  title: string
}

export const CurrentSettingHomework = ({ children, className, title }: Props) => {
  const styleTitle = {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    marginBottom: '6px',
  }

  return (
    <div className={className}>
      <div style={styleTitle}>{title}</div>
      {children}
    </div>
  )
}

import { KeyboardEventHandler, ReactNode } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, Grid, IconButton } from '@mui/material'
import { selectIsDarkTheme } from 'app'
import { useAppSelector } from 'common/hooks'

type Props = {
  children?: ReactNode
  modalTitle: ReactNode | string
  onClose: () => void
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
  open: boolean
  width?: number
}

export const CustomModal = ({ children, modalTitle, onClose, onKeyDown, open, width }: Props) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme)

  const style = {
    boxShadow: 24,
    width: width || 500,
  }

  return (
    <Dialog maxWidth={'xl'} onClose={onClose} onKeyDown={onKeyDown} open={open}>
      <Box sx={style}>
        <Grid
          alignItems={'center'}
          container
          direction={'row'}
          gap={2}
          justifyContent={'space-between'}
          style={{
            borderBottom: isDarkTheme ? '1px solid #263648' : '1px solid #E5E5E5',
            padding: '10px 24px',
          }}
        >
          <Box fontSize={18}>{modalTitle}</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid style={{ overflow: 'auto', padding: '15px 24px 36px 24px' }}>{children}</Grid>
      </Box>
    </Dialog>
  )
}

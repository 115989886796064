import { ReadMore, Table, Tag, Tooltip } from '@it-incubator/ui-kit'

import s from './lesson-reviews-table.module.css'

import { reactionsData } from '../data/reactions'
import { Reviews } from '../service/types'

type Props = {
  reviews: Reviews[]
}

export const LessonReviewsTable = ({ reviews }: Props) => {
  return (
    <Table.Root className={s.tableRoot} width={'100%'}>
      <Table.Head>
        <Table.HeadCell>Mentor</Table.HeadCell>
        <Table.HeadCell>Lesson</Table.HeadCell>
        <Table.HeadCell>Date</Table.HeadCell>
        <Table.HeadCell>Review</Table.HeadCell>
        <Table.HeadCell>Reactions</Table.HeadCell>
      </Table.Head>

      <Table.Body>
        {reviews.map(review => (
          <Table.Row key={review.review._id}>
            <Table.Cell>{review.mentor.name}</Table.Cell>
            <Table.Cell>{review.lesson.name}</Table.Cell>
            <Table.Cell>{new Date(review.lessonDate).toLocaleDateString('ru-RU')}</Table.Cell>
            <Table.Cell style={{ wordBreak: 'break-word' }}>
              <ReadMore maxLength={100} text={review.review.text} />
            </Table.Cell>
            <Table.Cell>
              <div className={s.reactionsContainer}>
                {reactionsData.map(rd => {
                  if (!review.review.reactions.includes(rd.reaction)) {
                    return null
                  }

                  return (
                    <Tooltip
                      component={
                        <Tag className={s.reaction} key={rd.reaction} pressed={false} readonly>
                          {rd.icon}
                        </Tag>
                      }
                      key={rd.reaction}
                    >
                      {rd.title}
                    </Tooltip>
                  )
                })}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import {
  ChangeVideoOrderBody,
  GetVideoByIdRes,
  Video,
  VideosArgs,
  VideosResponse,
} from '../service'

export const videosService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_VIDEO_URL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    addDaysForVideoSettings: build.mutation({
      invalidatesTags: ['videos'],
      query: (settingIds: string[]) => {
        return {
          body: { settingIds },
          method: 'PUT',
          url: 'videos/add-days',
        }
      },
    }),
    addVideo: build.mutation({
      invalidatesTags: ['videos'],
      query: (video: Video) => {
        return {
          body: video,
          method: 'POST',
          url: 'videos',
        }
      },
    }),
    changeVideosOrder: build.mutation({
      invalidatesTags: ['videos'],
      query: (body: ChangeVideoOrderBody) => {
        return {
          body,
          method: 'POST',
          url: 'videos/order',
        }
      },
    }),
    deleteVideo: build.mutation({
      invalidatesTags: ['videos'],
      query: (videoId: string) => {
        return {
          method: 'DELETE',
          url: `videos/${videoId}`,
        }
      },
    }),
    getVideoById: build.query<GetVideoByIdRes, { id: string }>({
      providesTags: () => ['videos', 'delete comment-card'],

      query: ({ id }) => {
        return {
          url: 'videos/' + id,
        }
      },
    }),
    getVideos: build.query<VideosResponse, VideosArgs>({
      providesTags: () => ['videos'],
      query: ({ course, page, pageSize, playlist, search, sort }) => {
        return {
          params: {
            course,
            page,
            pageSize,
            playlist,
            search,
            sort,
          },
          url: 'videos',
        }
      },
    }),
    updateVideo: build.mutation({
      invalidatesTags: ['videos'],
      query: ({ video, videoId }) => {
        return {
          body: video,
          method: 'PUT',
          url: `videos/${videoId}`,
        }
      },
    }),
  }),
  reducerPath: 'videosService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['videos', 'delete comment-card'],
})

export const {
  useAddDaysForVideoSettingsMutation,
  useAddVideoMutation,
  useChangeVideosOrderMutation,
  useDeleteVideoMutation,
  useGetVideoByIdQuery,
  useGetVideosQuery,
  useUpdateVideoMutation,
  util,
} = videosService

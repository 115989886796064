import { Button } from '@mui/material'

import s from './ConfirmationModal.module.css'

import { CustomModal } from '../custom-modal/CustomModal'

type Props = {
  cancelButtonText?: string
  disabledButtons?: boolean
  okButtonText?: string
  onCancel: () => void
  onOk: () => void
  show: boolean
  text?: string
  title: string
}

export const ConfirmationModal = ({
  cancelButtonText = 'Отменить',
  disabledButtons,
  okButtonText = 'Да',
  onCancel,
  onOk,
  show,
  text,
  title,
}: Props) => {
  return (
    <CustomModal modalTitle={title} onClose={onCancel} open={show} width={330}>
      {text ? <div>{text}</div> : null}
      <div className={s.buttonsBox}>
        <Button color={'secondary'} disabled={disabledButtons} onClick={onOk}>
          {okButtonText}
        </Button>
        <Button disabled={disabledButtons} onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </CustomModal>
  )
}

export const FIRST_PAGE_NUMBER = 1
export const MAX_TWO_DIGIT_NUMBER = 99

const ALLOWED_TAGS = [
  'p',
  'br',
  'a',
  'img',
  'div',
  'span',
  'ul',
  'li',
  'ol',
  'b',
  'i',
  'em',
  'strong',
  'strike',
  'u',
  's',
  'pre',
  'table',
  'thead',
  'tbody',
  'td',
  'th',
  'tr',
  'caption',
  'colgroup',
  'col',
  'tfoot',
  'code',
  'iframe',
  'figure',
]

export const SANITIZE_HTML_OPTIONS = {
  allowedAttributes: {
    '*': ['id', 'class', 'style', 'title'],
    a: ['href', 'target', 'rel', 'name', 'alt'],
    figure: ['style'],
    iframe: [
      'src',
      'data-size',
      'data-align',
      'data-file-name',
      'data-file-size',
      'data-origin',
      'data-proportion',
      'data-percentage',
      'style',
      'data-index',
    ],
    img: ['src', 'alt', 'width', 'height'],
    table: ['border'],
    td: ['colspan', 'rowspan'],
    th: ['colspan', 'rowspan'],
  },
  allowedTags: ALLOWED_TAGS,
  parseStyleAttributes: false,
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { Avatar, GetAvatarArgsType } from '../service'

export const avatarsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    deleteUserAvatar: build.mutation({
      invalidatesTags: () => ['usersAvatars'],
      query: (studentId: string) => {
        return {
          method: 'DELETE',
          url: '/avatars/' + studentId,
        }
      },
    }),
    deleteUsersAvatar: build.mutation({
      invalidatesTags: () => ['usersAvatars'],
      query: (studentIds: string[]) => {
        return {
          body: { ids: studentIds },
          method: 'POST',
          url: '/avatars/delete',
        }
      },
    }),
    getUsersAvatars: build.query<Avatar, GetAvatarArgsType>({
      providesTags: () => ['usersAvatars'],
      query: ({ page, pageCount, searchNameTerm }) => {
        return {
          params: {
            page,
            pageCount,
            searchNameTerm,
          },
          url: '/avatars',
        }
      },
    }),
  }),
  reducerPath: 'avatarsService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['usersAvatars'],
})

export const {
  useDeleteUserAvatarMutation,
  useDeleteUsersAvatarMutation,
  useGetUsersAvatarsQuery,
} = avatarsService

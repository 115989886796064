import * as React from 'react'
import { SVGProps, memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg fill={'none'} height={23} width={23} xmlns={'http://www.w3.org/2000/svg'} {...props}>
    <circle cx={11.5} cy={11.5} fill={'#FFC112'} r={11.5} />
    <path
      d={
        'M11.502 8.971c-.586 0-1.065.48-1.065 1.065v6.857c0 .586.48 1.065 1.065 1.065s1.065-.479 1.065-1.065v-6.857c0-.586-.48-1.065-1.065-1.065ZM11.5 7.667a1.438 1.438 0 1 0 0-2.876 1.438 1.438 0 0 0 0 2.875Z'
      }
      fill={'#263648'}
    />
  </svg>
)

const Memo = memo(SvgComponent)

export default Memo

import { Nullable } from 'common/types'
import { Message } from 'features/chats/service'

type AdditionalFields = {
  isAnotherManager: boolean
  isFirstOfDay: boolean
  isGroupOfMessages: boolean
  isReadSeparator: boolean
}

export type FormattedMessagesData = (AdditionalFields & Message)[]

export const formatMessagesData = (messages: Message[], userId: number): FormattedMessagesData => {
  let previousDate: Nullable<Date> = null
  const previousIsRead: Nullable<boolean> = null
  let previousGroupOfMessages: Nullable<boolean> = null
  let previousManagerId: Nullable<string> = null

  return messages.map(message => {
    const isManager = parseInt(message.user_id.slice(2)) === userId
    const currentDate = new Date(message.time)

    let isFirstOfDay = false

    if (previousDate === null || currentDate.getDate() !== previousDate.getDate()) {
      isFirstOfDay = true
    }

    previousDate = currentDate

    const isReadSeparator = false
    let isAnotherManager = false

    if (!isManager) {
      // if (previousIsRead !== null && previousIsRead !== message.isRead) {
      //   isReadSeparator = true
      // }
      //
      // previousIsRead = message.isRead
    } else {
      if (previousManagerId === null || previousManagerId !== message.user_id) {
        isAnotherManager = true
      }

      previousManagerId = message.user_id
    }

    let isGroupOfMessages = false

    if (previousGroupOfMessages === null || previousGroupOfMessages !== isManager) {
      isGroupOfMessages = true
    }

    previousGroupOfMessages = isManager

    return { ...message, isAnotherManager, isFirstOfDay, isGroupOfMessages, isReadSeparator }
  })
}

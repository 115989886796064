import { ChangeEvent, useState } from 'react'

import { instance } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import s from './HomeworkUser.module.css'

import { User, UserNewHomework } from './HomeworkChecked'
import { UserHomework } from './UserHomework'

type Props = {
  checkUser: (id: string) => void
  checked?: boolean
  u: User
  updateUser: (id: string, homeworks: UserNewHomework[]) => void
}

export const HomeworkUser = ({ checkUser, checked, u, updateUser }: Props) => {
  const [checkedH, checkHomework] = useState('')
  const [hwL, setHWL] = useState(u.homeworkLevel || 0)
  const [lesL, setLesL] = useState(u.lessonLevel || 0)
  const [courseId, setCourseId] = useState(u.courseId)
  const [disable, setDisable] = useState(false)

  const changeCourse = (e: ChangeEvent<HTMLSelectElement>) => {
    const c = +e.currentTarget.value

    setCourseId(c)
    const cc = u.courseData?.find(d => d.courseId === c)

    if (cc) {
      setHWL(cc.homeworkLevel || 0)
      setLesL(cc.lessonLevel || 0)
    }
  }
  const changeLvl = () => {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    setDisable(true)
    instance
      .put('homework/change-level', {
        courseId,
        homeworkLevel: hwL,
        lessonLevel: lesL,
        token,
        user_id: u._id,
      })
      .then(res => {
        updateUser(u._id, res.data.newHomeworks)
        const d = u.courseData?.find(d => d.courseId === courseId) || {
          homeworkLevel: hwL,
          lessonLevel: lesL,
        }

        d.homeworkLevel = hwL
        d.lessonLevel = lesL
        setHWL(d.homeworkLevel)
        setLesL(d.lessonLevel)

        setDisable(false)
      })
  }

  const hs = (u.newHomeworks || []).map((h, i) => (
    <UserHomework
      checkH={checkHomework}
      checked={checkedH === '' + h.homeworkId + h.blockId + h.uniqId}
      h={h}
      key={i}
      u_id={u._id}
      updateUser={updateUser}
    />
  ))

  const elseCourse = u.courseData?.filter(d => d.courseId !== u.courseId).map(d => ',' + d.courseId)

  return (
    <div>
      <div className={s.homeworkUserHeader}>
        <b>
          {u.firstName} {u.lastName} | {u.courseTitle}[{elseCourse}] |{' '}
          <select
            className={s.blackText}
            disabled={disable}
            onChange={changeCourse}
            onClick={e => e.stopPropagation()}
            value={courseId}
          >
            {(u.courseData || []).map((d, i) => (
              <option key={d.courseId + u._id + i} value={d.courseId}>
                {d.courseId}
              </option>
            ))}
          </select>
        </b>
        <span onClick={() => checkUser(checked ? '' : u._id)}>свернуть/развернуть</span>
        <span>
          {u.level} уровень | hwL:{' '}
          <input
            className={s.blackText}
            disabled={disable}
            onBlur={changeLvl}
            onChange={e => setHWL(+e.currentTarget.value)}
            onClick={e => e.stopPropagation()}
            style={{ width: 50 }}
            type={'number'}
            value={hwL}
          />{' '}
          lesL:{' '}
          <input
            className={s.blackText}
            disabled={disable}
            onBlur={changeLvl}
            onChange={e => setLesL(+e.currentTarget.value)}
            onClick={e => e.stopPropagation()}
            style={{ width: 50 }}
            type={'number'}
            value={lesL}
          />
        </span>
      </div>

      {checked && (
        <>
          <div className={s.linksBlock}>
            <div className={s.linkB}>
              <div className={s.linkDescription}>Ссылка на код</div>
              <a href={u.homeworkURL} rel={'noopener noreferrer'} target={'_blank'}>
                {u.homeworkURL}
              </a>
            </div>

            <div className={s.linkB}>
              <div className={s.linkDescription}>Ссылка на сайт</div>
              <a href={u.homeworkPage} rel={'noopener noreferrer'} target={'_blank'}>
                {u.homeworkPage}
              </a>
            </div>
          </div>

          {hs}
        </>
      )}
    </div>
  )
}

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { appReducer, uiReducer } from 'app'
import { chatsService } from 'features/chats/service'
import { codeReviewService } from 'features/code-review/service'
import { commentsService } from 'features/comments/service'
import { courseReviewsService } from 'features/course-reviews/service'
import { cvService } from 'features/cv/service'
import { devClubService } from 'features/dev-club/service'
import { faqService } from 'features/faq/service'
import { lessonReviewsService } from 'features/lesson-reviews/service'
import { moodsService } from 'features/mood/service'
import { playlistsService } from 'features/playlists/service'
import { avatarsService } from 'features/students-photos/service'
import { videosService } from 'features/videos/service'
import { labsCoursesAPI } from 'services/courses'
import { labsStudentsAPI } from 'services/students'
import { labsUsersAPI } from 'services/users'

const reducers = combineReducers({
  app: appReducer,
  [avatarsService.reducerPath]: avatarsService.reducer,
  [chatsService.reducerPath]: chatsService.reducer,
  [codeReviewService.reducerPath]: codeReviewService.reducer,
  [commentsService.reducerPath]: commentsService.reducer,
  [courseReviewsService.reducerPath]: courseReviewsService.reducer,
  [cvService.reducerPath]: cvService.reducer,
  [devClubService.reducerPath]: devClubService.reducer,
  [faqService.reducerPath]: faqService.reducer,
  [labsCoursesAPI.reducerPath]: labsCoursesAPI.reducer,
  [labsStudentsAPI.reducerPath]: labsStudentsAPI.reducer,
  [labsUsersAPI.reducerPath]: labsUsersAPI.reducer,
  [lessonReviewsService.reducerPath]: lessonReviewsService.reducer,
  [moodsService.reducerPath]: moodsService.reducer,
  [playlistsService.reducerPath]: playlistsService.reducer,
  ui: uiReducer,
  [videosService.reducerPath]: videosService.reducer,
})

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(commentsService.middleware)
      .concat(moodsService.middleware)
      .concat(labsStudentsAPI.middleware)
      .concat(labsCoursesAPI.middleware)
      .concat(labsUsersAPI.middleware)
      .concat(devClubService.middleware)
      .concat(avatarsService.middleware)
      .concat(devClubService.middleware)
      .concat(playlistsService.middleware)
      .concat(videosService.middleware)
      .concat(courseReviewsService.middleware)
      .concat(cvService.middleware)
      .concat(codeReviewService.middleware)
      .concat(faqService.middleware)
      .concat(chatsService.middleware)
      .concat(lessonReviewsService.middleware),
  reducer: reducers,
})

export type AppStore = ReturnType<typeof reducers>

// @ts-ignore
window.store = store // for developers

setupListeners(store.dispatch)

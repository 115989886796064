import { Status } from '@/features/chats/service'

type Group = {
  id: number
  last_message: { id: number }
}

export const isUnread = (
  statuses: Status[],
  group: Group,
  userId: string /*s_2 / a_7*/
): boolean => {
  if (!group.last_message) {
    return false
  }

  const status = statuses.find(s => s.group_id === group.id && s.user_id === userId)

  if (status) {
    if (!status.last_read_message_id) {
      return true
    }

    return status.last_read_message_id !== group.last_message.id
  }

  return false
}

import { SVGProps } from 'react'

import { Modal } from '@mui/base/Modal'
import { styled } from '@mui/material'
import Box from '@mui/material/Box/Box'

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: 'max-content',
}

type Props = {
  onClose: () => void
  open: boolean
  src: string
}

export const PreviewImage = ({ onClose, open, src }: Props) => {
  return (
    <div>
      <StyledModal
        aria-describedby={'unstyled-modal-description'}
        aria-labelledby={'unstyled-modal-title'}
        components={{ Backdrop }}
        onClick={onClose}
        onClose={onClose}
        open={open}
      >
        <Box sx={style}>
          <img
            alt={'Payload img'}
            src={src}
            style={{
              borderRadius: '6px',
              maxHeight: '80vh',
              maxWidth: '80vw',
            }}
          />
          <CloseIcon
            onClick={onClose}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: '10px',
              top: '10px',
            }}
          />
        </Box>
      </StyledModal>
    </div>
  )
}

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill={'none'} height={36} width={36} xmlns={'http://www.w3.org/2000/svg'} {...props}>
    <path d={'M0 0h36v36H0z'} fill={'#000'} fillOpacity={0.6} />
    <path
      d={
        'M22.08 33c.86 0 1.56-.7 1.56-1.56v-7.8h7.8a1.56 1.56 0 0 0 0-3.13h-9.36c-.87 0-1.57.7-1.57 1.57v9.36c0 .86.7 1.56 1.57 1.56ZM13.92 3c-.86 0-1.56.7-1.56 1.56v7.8h-7.8a1.56 1.56 0 1 0 0 3.13h9.36c.87 0 1.57-.7 1.57-1.56V4.56c0-.86-.7-1.56-1.57-1.56Zm-1.56 20.64v7.8a1.56 1.56 0 0 0 3.13 0v-9.36c0-.87-.7-1.57-1.57-1.57H4.56a1.56 1.56 0 1 0 0 3.13h7.8Zm11.28-11.27V4.56a1.56 1.56 0 1 0-3.13 0v9.37c0 .86.7 1.56 1.57 1.56h9.36a1.56 1.56 0 1 0 0-3.13h-7.8Z'
      }
      fill={'#fff'}
    />
  </svg>
)

import { CommonColumn } from 'common/components/custom-table/CustomTable'

export const devClubTableData: CommonColumn[] = [
  { id: 1, isSort: false, name: 'Студент', title: 'name', width: '25%' },
  {
    id: 2,
    isSort: false,
    name: 'Labs ID',
    title: 'id',
    width: '10%',
  },
  {
    id: 3,
    isSort: false,
    name: 'Статус',
    title: 'status',
    width: '10%',
  },
  {
    id: 4,
    isSort: false,
    name: 'Курс',
    title: 'courseId',
    width: '10%',
  },
  {
    id: 5,
    isSort: false,
    name: 'Обновлено',
    title: 'updated',
    width: '10%',
  },
  { id: 6, isSort: false, name: 'Youtube', title: 'youtubeUrl', width: '25%' },
  { id: 7, isSort: false, name: '', title: 'controls', width: '10%' },
]

export const statusLiterals = {
  filling: 'Заполняет',
  pending: 'Ожидает проверки',
  reject: 'Отклонен',
  success: 'Принят',
}

import { useParams } from 'react-router-dom'

import { Spinner, Typography } from '@it-incubator/ui-kit'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { useGetVideoByIdQuery } from 'features/videos/service'
import parse from 'html-react-parser'
import { generateVideoUrlFromId } from 'utils/parseVideoUrl'

import s from './WatchVideo.module.css'

import { Comments } from './comments/Comments'
import { Reactions } from './reactions/Reactions'

export const WatchVideo = () => {
  const { videoId } = useParams<{ videoId: string }>()

  const { data: video, isFetching, isLoading } = useGetVideoByIdQuery({ id: videoId ?? '' })

  const videoUrl = generateVideoUrlFromId(
    video?.hosting || 'youtube',
    video?.hostingId || '',
    video?.hostingHash,
    video?.url
  )

  if (isLoading || isFetching) {
    return <Spinner />
  }

  return (
    <div className={s.pageWrapper}>
      <PageHeader backButtonText={'Назад к видео'} title={''} />

      <div className={s.videoWrapper}>
        <iframe
          allow={
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          }
          allowFullScreen
          className={s.iframe}
          src={videoUrl}
          title={videoUrl}
        />
      </div>

      <Reactions reactions={video?.reactions} />

      <Typography.H2 className={s.videoTitle}>{video?.name}</Typography.H2>

      <Typography.Body2 className={s.videoDescription}>
        {parse(video?.description ?? '')}
      </Typography.Body2>

      <Comments videoComments={video?.comments} />
    </div>
  )
}

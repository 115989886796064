import { useState } from 'react'

import { Checkbox, Label, Reaction } from '@it-incubator/ui-kit'
import { reactionsData } from 'features/videos/data'
import { ReactionType } from 'features/videos/service'

import s from './Reactions.module.css'

type Props = {
  reactions?: ReactionType[]
}

export const Reactions = ({ reactions }: Props) => {
  const [emptyReactionsFilter, setEmptyReactionsFilter] = useState(true)

  const reactionsFilterHandler = () => {
    setEmptyReactionsFilter(prevState => !prevState)
  }

  return (
    <div>
      <div className={s.labelContainer}>
        <Label label={'Оценить качество материала и подачу материала автором видео:'} />

        <Checkbox
          checked={emptyReactionsFilter}
          label={'Только отмеченные реакции'}
          onChange={reactionsFilterHandler}
        />
      </div>

      <div className={s.tagsContainer}>
        {reactionsData.map(data => {
          const reactionCount = reactions?.filter(r => r.reaction === data.reaction).length || 0

          const filterReactionCondition = emptyReactionsFilter ? reactionCount > 0 : true

          return (
            filterReactionCondition && (
              <Reaction
                count={reactionCount}
                icon={data.icon}
                key={data.reaction}
                readonly
                text={data.title}
                variant={data.variant}
              />
            )
          )
        })}
      </div>
    </div>
  )
}

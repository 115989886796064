import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import DeleteIcon from '@mui/icons-material/DeleteForever'
import { IconButton } from '@mui/material'
import { selectAdmin } from 'app'
import { TelegramIcon } from 'assets/icons/adaptive-icons/TelegramIcon'
import { Mood } from 'features/mood/service'
import moment from 'moment'
import { Course } from 'services/courses'
import { useSendStudentLinkInTelegramMutation } from 'services/students'
import { capitalizeFirstLetter, getInflectedWord, getLearningMonthsCount } from 'utils'

import s from '../MoodsPage.module.css'

import { DeleteMoodModal } from '../delete-mood-modal/DeleteMoodModal'
import { MoodModal } from '../mood-modal/MoodModal'
import { ScoresColumn } from './scores-column/ScoresColumn'

type Props = {
  courses?: Course[]
  deleteMoodId: string
  mood: Mood
  onDeleteMoodId: (id: string) => void
  onShowMoodId: (id: string) => void
  showMoodId: string
}

export const MoodTableRow = ({
  courses,
  deleteMoodId,
  mood,
  onDeleteMoodId,
  onShowMoodId,
  showMoodId,
}: Props) => {
  const admin = useSelector(selectAdmin)

  const currentDate = new Date()
  const studentStartLearningDate = new Date(mood.studentId.created)
  const modifiedWords = ['месяц', 'месяца', 'месяцев']
  const learningMonthsCount = getLearningMonthsCount(studentStartLearningDate, currentDate)
  const month = getInflectedWord(learningMonthsCount, modifiedWords)
  const learningTime = `${learningMonthsCount} ${month}`

  const [sendStudentLinkInTelegram, { isLoading: isSendLoading }] =
    useSendStudentLinkInTelegramMutation()

  const sendLinkInTelegram = () => {
    sendStudentLinkInTelegram(mood.studentId.baseId)
      .unwrap()
      .then(() => {
        toast.success('Отправлено! проверьте телеграмм...')
      })
      .catch(e => {
        toast.error(e.response?.status || 'some error')
      })
  }

  return (
    <div className={s.tableRow}>
      <div className={s.column} style={{ width: '16%' }}>
        <u
          onClick={() => onShowMoodId(mood._id)}
        >{`${mood.studentId.lastName} ${mood.studentId.firstName}`}</u>
      </div>

      <div className={s.column} style={{ width: '12%' }}>
        {courses?.map(
          course =>
            mood.studentId.currentCourseId === course.id && capitalizeFirstLetter(course.title)
        )}
      </div>

      <div className={s.column} style={{ width: '12%' }}>
        {moment(mood.created).format('DD/MM/YYYY')}
      </div>

      <div className={s.column} style={{ width: '8%' }}>
        {mood.level}
      </div>

      <div className={s.column} style={{ width: '13%' }}>
        {learningTime}
      </div>

      <div className={s.column} style={{ width: '10%' }}>
        <ScoresColumn score={mood.mood} />
      </div>

      <div className={s.column} style={{ width: '10%' }}>
        <ScoresColumn score={mood.learningTime} />
      </div>

      <div className={s.column} style={{ width: '11%' }}>
        <ScoresColumn score={mood.programComplexity} />
      </div>

      <div className={`${s.column} ${s.buttonsContainer}`} style={{ width: '8%' }}>
        <IconButton disabled={isSendLoading} onClick={sendLinkInTelegram}>
          <TelegramIcon />
        </IconButton>

        <IconButton disabled={admin.role !== 'superadmin'} onClick={() => onDeleteMoodId(mood._id)}>
          <DeleteIcon fontSize={'small'} />
        </IconButton>
      </div>

      <MoodModal
        courses={courses}
        learningTime={learningTime}
        mood={mood}
        onCLose={() => onShowMoodId('')}
        onOpen={showMoodId === mood._id}
      />

      <DeleteMoodModal
        mood={mood}
        onCLose={() => onDeleteMoodId('')}
        onOpen={deleteMoodId === mood._id}
      />
    </div>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { CourseReviewArgsType, CourseReviewRes } from '../service'

export const courseReviewsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    deleteCourseReview: build.mutation({
      invalidatesTags: () => ['courseReviews'],
      query: (courseReviewId: string) => {
        return {
          method: 'DELETE',
          url: '/course-review/' + courseReviewId,
        }
      },
    }),
    getCourseReviews: build.query<CourseReviewRes, CourseReviewArgsType>({
      providesTags: () => ['courseReviews'],
      query: ({ courseId, lastName, page, perPage, sort }) => {
        return {
          params: {
            courseId,
            lastName,
            page,
            pageSize: perPage,
            sort,
          },
          url: '/course-review/all',
        }
      },
    }),
  }),
  reducerPath: 'courseReviewsService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['courseReviews'],
})

export const { useDeleteCourseReviewMutation, useGetCourseReviewsQuery } = courseReviewsService

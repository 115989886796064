export const TelegramIcon = () => (
  <svg
    fill={'none'}
    height={'16'}
    viewBox={'0 0 16 16'}
    width={'16'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect fill={'#419FD9'} height={'16'} rx={'8'} width={'16'} />
    <path
      clipRule={'evenodd'}
      d={
        'M9.77836 11.9612C10.3375 12.2058 10.5472 11.6933 10.5472 11.6933L12.0267 4.26095C12.015 3.76003 11.3394 4.06292 11.3394 4.06292L3.05667 7.31309C3.05667 7.31309 2.6606 7.45288 2.69554 7.69752C2.73049 7.94216 3.04502 8.05865 3.04502 8.05865L5.13026 8.75761C5.13026 8.75761 5.75933 10.8196 5.88747 11.2156C6.00397 11.6001 6.10881 11.6117 6.10881 11.6117C6.2253 11.6583 6.33015 11.5768 6.33015 11.5768L7.68148 10.3536L9.77836 11.9612ZM10.1395 5.57732C10.1395 5.57732 10.4307 5.40258 10.4191 5.57732C10.4191 5.57732 10.4657 5.60062 10.3142 5.76371C10.1744 5.9035 6.87767 8.86244 6.435 9.25852C6.40005 9.28182 6.37675 9.31677 6.37675 9.36337L6.24861 10.4584C6.22531 10.5749 6.07387 10.5866 6.03892 10.4817L5.4914 8.6877C5.4681 8.61781 5.4914 8.53626 5.56129 8.48966L10.1395 5.57732Z'
      }
      fill={'white'}
      fillRule={'evenodd'}
    />
  </svg>
)

import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Spinner, TextField } from '@it-incubator/ui-kit'
import { SelectChangeEvent } from '@mui/material/Select'
import { ConfirmationModal } from 'common/components/confirmation-modal/ConfirmationModal'
import { CustomPagination } from 'common/components/custom-pagination/CustomPagination'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { PreviewImage } from 'common/components/photo-uploader/PreviewImage/PreviewImage'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import {
  StudentsPhotosQueriesType,
  useDeleteUserAvatarMutation,
  useDeleteUsersAvatarMutation,
  useGetUsersAvatarsQuery,
} from 'features/students-photos/service'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState, saveState } from 'utils'

import s from './StudentsPhotosPage.module.css'

import { MassDeleteAvatars } from './mass-delete-avatars/MassDeleteAvatars'
import { StudentsPhotosTable } from './students-photos-table/StudentsPhotosTable'

export const StudentsPhotosPage = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(50)
  const [imagePreviewSrc, setImagePreviewSrc] = useState('')
  const [selectedStudents, setSelectedStudents] = useState<string[]>([])
  const [showDeleteAllConfirmationModal, setShowDeleteAllConfirmationModal] = useState(false)
  const [studentToDelete, setStudentToDelete] = useState({ id: '', name: '' })

  const debouncedSearch = useDebounce(search, 1000)

  const { data, isLoading } = useGetUsersAvatarsQuery({
    page,
    pageCount,
    searchNameTerm: debouncedSearch,
  })

  const allStudentsIds = data?.students?.map(s => s._id) || []

  const [deleteStudentAvatar, { isLoading: isDeleteStudentAvatarLoading }] =
    useDeleteUserAvatarMutation()

  const [deleteAllSelectedStudentAvatars, { isLoading: isDeleteAllSelectedStudentAvatarsLoading }] =
    useDeleteUsersAvatarMutation()

  const studentsPhotosQueries: StudentsPhotosQueriesType = {
    filters: {
      searchNameTerm: search,
    },
  }

  useEffect(() => {
    const restoredStudentPhotosQueries = restoreState(
      LOCAL_STORAGE_QUERIES_KEYS.STUDENTS_PHOTOS,
      studentsPhotosQueries
    )
    const { searchNameTerm } = restoredStudentPhotosQueries.filters

    if (searchNameTerm) {
      setSearch(searchNameTerm)
    }
  }, [])

  if (isLoading) {
    return <Spinner />
  }
  if (!data) {
    return <h2>No data</h2>
  }

  const totalPagesCount = Math.ceil(data.avatarsTotalCount / pageCount)
  const isDisabledDeleteButtons =
    isDeleteStudentAvatarLoading || isDeleteAllSelectedStudentAvatarsLoading

  const closeDeleteStudentAvatarConfirmationModal = () => {
    setStudentToDelete({ id: '', name: '' })
  }

  const closeDeleteAllConfirmationModal = () => {
    setShowDeleteAllConfirmationModal(false)
  }

  const deleteAllSelected = () => {
    deleteAllSelectedStudentAvatars(selectedStudents)
      .unwrap()
      .then(() => {
        closeDeleteAllConfirmationModal()
        setSelectedStudents([])
        toast.success('Аватары студентов успешно удалены')
      })
  }

  const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const searchNameTerm = event.currentTarget.value

    setSearch(searchNameTerm)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.STUDENTS_PHOTOS, {
      ...studentsPhotosQueries,
      filters: { ...studentsPhotosQueries.filters, searchNameTerm },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.STUDENTS_PHOTOS, {
      ...studentsPhotosQueries,
      filters: { ...studentsPhotosQueries.filters, searchNameTerm: '' },
    })
  }

  const handlePageChanged = (event: any, page: number) => {
    setPage(page)
  }
  const handlePageCountChanged = (event: SelectChangeEvent<number>) => {
    setPageCount(event.target.value as number)
    setPage(FIRST_PAGE_NUMBER)
  }
  const handleStudentSelected = (id: string) => {
    if (selectedStudents.includes(id)) {
      setSelectedStudents(selectedStudents.filter(s => s !== id))

      return
    }
    setSelectedStudents([...selectedStudents, id])
  }
  const handleDeleteStudentAvatarClicked = (id: string, name: string) => {
    setStudentToDelete({ id, name })
  }
  const handleDeleteAllSelectedClicked = () => {
    setShowDeleteAllConfirmationModal(true)
  }

  const handleDeleteOneOkClicked = () => {
    deleteStudentAvatar(studentToDelete.id)
      .unwrap()
      .then(() => {
        closeDeleteStudentAvatarConfirmationModal()
        toast.success('Аватар студента успешно удален')
      })
  }

  const handleOpenPreviewImageClicked = (src: string) => {
    setImagePreviewSrc(src)
  }
  const handleClosePreviewImage = () => {
    setImagePreviewSrc('')
  }

  const handleSelectAllClicked = (selectedAll: boolean) => {
    if (selectedAll) {
      setSelectedStudents(allStudentsIds)

      return
    }
    setSelectedStudents([])
  }

  return (
    <div>
      <PageHeader title={'Анализ фото студентов'} />

      <TextField
        className={s.search}
        onChange={handleSearchChanged}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <MassDeleteAvatars
        isDisabledDeleteButtons={isDisabledDeleteButtons}
        onDeleteAllSelected={handleDeleteAllSelectedClicked}
        selectedStudentAvatarIds={selectedStudents}
      />

      <StudentsPhotosTable
        data={data.students}
        handleSelectAllClicked={handleSelectAllClicked}
        isAtLeastOneStudentSelected={selectedStudents.length > 0}
        isDisabledDeleteButtons={isDisabledDeleteButtons}
        onOpenImagePreview={handleOpenPreviewImageClicked}
        onRowDeleted={handleDeleteStudentAvatarClicked}
        onRowSelected={handleStudentSelected}
        selectedStudents={selectedStudents}
      />

      <CustomPagination
        count={totalPagesCount}
        itemForPageCount={pageCount}
        itemName={'студентов'}
        onChange={handlePageChanged}
        onSelectChange={handlePageCountChanged}
        pageNumber={page}
      />

      <PreviewImage
        onClose={handleClosePreviewImage}
        open={imagePreviewSrc !== ''}
        src={imagePreviewSrc}
      />

      <ConfirmationModal
        cancelButtonText={'Нет'}
        disabledButtons={isDisabledDeleteButtons}
        okButtonText={'Да'}
        onCancel={closeDeleteAllConfirmationModal}
        onOk={deleteAllSelected}
        show={showDeleteAllConfirmationModal}
        text={'Вы уверены, что хотите удалить аватарку у всех выбранных студентов?'}
        title={'Удалить?'}
      />

      <ConfirmationModal
        cancelButtonText={'Нет'}
        disabledButtons={isDisabledDeleteButtons}
        okButtonText={'Да'}
        onCancel={closeDeleteStudentAvatarConfirmationModal}
        onOk={handleDeleteOneOkClicked}
        show={studentToDelete.id !== ''}
        text={`Вы уверены, что хотите удалить аватарку ${studentToDelete.name}?`}
        title={`Удалить`}
      />
    </div>
  )
}

export const scrollbarViewportSelector = '#chat-scrollbar > [data-radix-scroll-area-viewport]'

export enum PublicEvent {
  USER_SEND_MESSAGE = 'group/user-send-message',
}

export enum TabsValues {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'not/read',
}

export const FIRST_PAGE_NUMBER = 1

import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Pagination, ReadMore, Select, Spinner, Table, TextField } from '@it-incubator/ui-kit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { Backdrop, IconButton } from '@mui/material'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { Option } from 'common/types'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, capitalizeFirstLetter, restoreState, saveState } from 'utils'

import s from './CourseReviews.module.css'

import {
  CourseReviewQueriesType,
  useDeleteCourseReviewMutation,
  useGetCourseReviewsQuery,
} from '../service'

export const CourseReviews = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [courseId, setCourseId] = useState('')

  const courseReviewQueries: CourseReviewQueriesType = {
    filters: {
      courseId,
      lastName: search,
    },
  }

  const {
    data,
    isFetching,
    isLoading: isCourseReviewLoading,
  } = useGetCourseReviewsQuery({
    courseId,
    lastName: search,
    page,
    perPage,
  })
  const { data: courses, isLoading: isCoursesLoading } = useGetCoursesQuery()
  const [deleteReview, { isLoading: isDeletingReviewLoading }] = useDeleteCourseReviewMutation()

  const isLoading = isCourseReviewLoading || isCoursesLoading

  const totalPages = Math.ceil((data?.totalCount || 0) / perPage)

  const coursesMap = new Map()
  const selectOptions: Option[] = [{ label: 'Курс не выбран', value: '' }]

  courses?.items.forEach(item => {
    const courseTitle = capitalizeFirstLetter(item.title) as string

    coursesMap.set(item.id, courseTitle)
    selectOptions.push({ label: courseTitle, value: item.id })
  })

  const handleDeleteClicked = (id: string) => () => {
    deleteReview(id)
      .unwrap()
      .then(() => {
        toast.success('Отзыв успешно удален')
      })
  }

  const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const lastName = event.currentTarget.value

    setSearch(lastName)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COURSE_REVIEWS, {
      ...courseReviewQueries,
      filters: { ...courseReviewQueries.filters, lastName },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COURSE_REVIEWS, {
      ...courseReviewQueries,
      filters: { ...courseReviewQueries.filters, lastName: '' },
    })
  }

  const handleCourseIdChanged = (value: string) => {
    setCourseId(value)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.COURSE_REVIEWS, {
      ...courseReviewQueries,
      filters: { ...courseReviewQueries.filters, courseId: value },
    })
  }

  const perPageChangeHandler = (itemPerPage: number) => {
    setPerPage(itemPerPage)
    setPage(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredCourseReviewQueries = restoreState(
      LOCAL_STORAGE_QUERIES_KEYS.COURSE_REVIEWS,
      courseReviewQueries
    )
    const { courseId, lastName } = restoredCourseReviewQueries.filters

    if (lastName) {
      setSearch(lastName)
    }
    if (courseId) {
      setCourseId(courseId + '')
    }
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <PageHeader title={'Отзывы'} />

      <TextField
        className={s.search}
        onChange={handleSearchChanged}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <Select
        label={'Курс'}
        onChange={handleCourseIdChanged}
        options={selectOptions}
        value={courseId}
        width={300}
      />

      <Table.Root className={s.tableRoot}>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align={'left'}>Курс</Table.HeadCell>
            <Table.HeadCell align={'left'}>Имя студента</Table.HeadCell>
            <Table.HeadCell align={'left'}>Дата</Table.HeadCell>
            <Table.HeadCell align={'left'}>Отзыв</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {data?.curseReview.map(item => (
            <Table.Row key={item._id}>
              <Table.Cell className={s.tableCell} width={200}>
                {coursesMap.get(item.courseId)}
              </Table.Cell>
              <Table.Cell className={s.tableCell} width={250}>
                {item.studentId.firstName} {item.studentId.lastName}
              </Table.Cell>
              <Table.Cell className={s.tableCell} width={200}>
                {getDate(item.updated)}
              </Table.Cell>
              <Table.Cell className={s.tableCell}>
                <ReadMore maxLength={200} text={item.review} />
              </Table.Cell>
              <Table.Cell className={s.tableCell} width={50}>
                <IconButton
                  disabled={isDeletingReviewLoading}
                  onClick={handleDeleteClicked(item._id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <Backdrop
        open={isFetching || isLoading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <Spinner />
      </Backdrop>

      <Pagination
        count={totalPages}
        onChange={setPage}
        onPerPageChange={perPageChangeHandler}
        page={page}
        perPage={perPage}
        perPageOptions={[20, 50, 100]}
      />
    </div>
  )
}
function getDate(date: string) {
  const formatted = new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  const time = new Date(date).toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  return `${formatted} в ${time}`
}

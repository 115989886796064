import { Comment, SortComments } from 'features/videos/service'

export const getSortedComments = (comments: Comment[], sort: SortComments): Comment[] => {
  return [...comments].sort((a, b) => {
    switch (sort) {
      case SortComments.DateAsc:
        return new Date(a.updated).getTime() - new Date(b.updated).getTime()
      case SortComments.DateDesc:
        return new Date(b.updated).getTime() - new Date(a.updated).getTime()
      case SortComments.LikesAsc:
        return a.likes.length - b.likes.length
      case SortComments.LikesDesc:
        return b.likes.length - a.likes.length
      default:
        return 0
    }
  })
}

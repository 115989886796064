import { ChangeEvent, useState } from 'react'

import { Button } from '@it-incubator/ui-kit'
import { Grid, TextField } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SuperCheckbox } from 'common/components/custom-checkbox/SuperCheckbox'
import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { Playlist, PlaylistType, Setting } from 'features/playlists/service'
import moment from 'moment'
import { useGetCoursesQuery } from 'services/courses'
import { capitalizeFirstLetter } from 'utils'

import { PlaylistSettings } from './playlist-settings/PlaylistSettings'

type Props = {
  callback: (playlistModel: Playlist) => void
  close: () => void
  defName?: string
  defSettings?: Setting[]
  show: boolean
}

export const PlaylistModal = ({ callback, close, defName, defSettings, show }: Props) => {
  const [name, setName] = useState(defName || '')
  const [selectedCourses, setSelectedCourses] = useState<Setting[]>(defSettings || [])
  const [error, setError] = useState(false)

  const defStart = moment().unix() * 1000 - 1000 * 60 * 60 * 24 * 366 * 2 // - 2 years
  const defEnd = moment().unix() * 1000 + 1000 * 60 * 60 * 24 * 366 * 2 // + 2 years

  const { data: courses } = useGetCoursesQuery()

  const handleCoursesSelected = (courseId: number) => {
    if (selectedCourses.some(selectedCourse => selectedCourse.courseId === courseId)) {
      setSelectedCourses(
        selectedCourses.filter(selectedCourse => selectedCourse.courseId !== courseId)
      )

      return
    }

    setSelectedCourses([
      ...selectedCourses,
      {
        accessLevel: 0,
        courseId: courseId,
        maxStudentStartCourseDate: defEnd,
        minStudentStartCourseDate: defStart,
        plType: '',
      },
    ])
  }

  const changeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    const maxLengthValue = 250

    if (value.length > maxLengthValue) {
      setError(true)
    } else {
      setName(value)
      setError(false)
    }
  }

  const changeLevelAccess = (event: SelectChangeEvent<number>, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, accessLevel: +event.target.value }
          : { ...selectedCourse }
      )
    )
  }

  const changeStartDate = (event: null | number, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, minStudentStartCourseDate: moment(event).unix() * 1000 }
          : { ...selectedCourse }
      )
    )
  }

  const changeEndDate = (event: null | number, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, maxStudentStartCourseDate: moment(event).unix() * 1000 }
          : { ...selectedCourse }
      )
    )
  }

  const changePlaylistType = (event: SelectChangeEvent<PlaylistType>, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, plType: event.target.value as PlaylistType }
          : { ...selectedCourse }
      )
    )
  }

  const onSave = () => {
    callback({
      name: name,
      settings: selectedCourses,
    })

    if (!defName && !defSettings) {
      setSelectedCourses([])
      setName('')
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CustomModal
        modalTitle={defName && defSettings ? 'Редактировать плейлист' : 'Новый плейлист'}
        onClose={close}
        open={show}
      >
        <Grid marginBottom={2}>
          <TextField
            error={error}
            fullWidth
            helperText={error && 'Максимальная длина 250 символов'}
            label={'Название плейлиста'}
            onChange={changeName}
            value={name}
          />
        </Grid>

        <h4 style={{ marginBottom: '9px' }}>Доступно для курсов:</h4>

        <Grid marginBottom={2}>
          {courses?.items.map(course => {
            const selected = selectedCourses.some(
              selectedCourse => selectedCourse.courseId === course.id
            )
            const currentCourse = selectedCourses.find(
              selectedCourse => selectedCourse.courseId === course.id
            ) as Setting

            return (
              <div key={course.title + course.id}>
                <SuperCheckbox checked={selected} onChange={() => handleCoursesSelected(course.id)}>
                  {capitalizeFirstLetter(course.title)}
                </SuperCheckbox>

                {selected && (
                  <PlaylistSettings
                    changeEndDate={e => changeEndDate(e, course.id)}
                    changeLevelAccess={e => changeLevelAccess(e, course.id)}
                    changePlaylistType={e => changePlaylistType(e, course.id)}
                    changeStartDate={e => changeStartDate(e, course.id)}
                    endDate={currentCourse.maxStudentStartCourseDate}
                    levelAccess={currentCourse.accessLevel}
                    playlistType={currentCourse.plType}
                    startDate={currentCourse.minStudentStartCourseDate}
                  />
                )}
              </div>
            )
          })}
        </Grid>

        <Grid container justifyContent={'flex-end'} marginTop={4}>
          <Button disabled={!name || error} onClick={onSave}>
            Сохранить
          </Button>
        </Grid>
      </CustomModal>
    </LocalizationProvider>
  )
}

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Button, Table } from '@it-incubator/ui-kit'
import { MenuItem, Select, Tooltip } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { selectAdmin, selectIsDarkTheme } from 'app'
import { useAppSelector } from 'common/hooks'
import {
  Review,
  ReviewStatusEnum,
  ReviewStatusType,
  useTakeReviewToWorkMutation,
  useUpdateReviewStatusMutation,
} from 'features/code-review/service'
import moment from 'moment/moment'
import { Course } from 'services/courses'
import { capitalizeFirstLetter } from 'utils'

import s from '../CodeReviewTable.module.css'
import s2 from 'features/cv/ui/cv-table-row/CvTableRow.module.css'

import { ReviewModal } from '../review-modal/ReviewModal'

type Props = {
  courses?: Course[]
  review: Review
}

export const CodeReviewTableRow = ({ courses, review }: Props) => {
  const [showReviewModalId, setShowReviewModalId] = useState(-1)

  const [takeReviewToWork, { isLoading: isTakeReviewToWorkLoading }] = useTakeReviewToWorkMutation()
  const [updateReviewStatus, { isLoading: isUpdateReviewStatusLoading }] =
    useUpdateReviewStatusMutation()

  const isLoading = isTakeReviewToWorkLoading || isUpdateReviewStatusLoading

  const isDarkTheme = useAppSelector(selectIsDarkTheme)

  const admin = useSelector(selectAdmin)

  const onReviewStatusChange = (event: SelectChangeEvent) => {
    const newReviewStatus = event.target.value as ReviewStatusType

    if (newReviewStatus === ReviewStatusEnum.IN_PROGRESS) {
      takeReviewToWork(review.id)
        .unwrap()
        .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
    }

    if (newReviewStatus === ReviewStatusEnum.NEW) {
      updateReviewStatus({ reviewId: review.id, status: ReviewStatusEnum.NEW })
        .unwrap()
        .catch(error => toast.error(error.data.message.message || 'Произошла ошибка'))
    }
  }

  const getStatusClassname = (status: ReviewStatusType) => {
    if (status === ReviewStatusEnum.NEW) {
      return s2.cellStatusWaiting
    }
    if (status === ReviewStatusEnum.IN_PROGRESS) {
      return s2.cellStatusInWork
    }
    if (status === ReviewStatusEnum.ACCEPTED) {
      return s2.cellStatusAccept
    }
    if (status === ReviewStatusEnum.REJECTED) {
      return s2.cellStatusReWait
    }
    if (status === ReviewStatusEnum.FORWARDED) {
      return s2.cellStatusWait
    }
  }

  const statusClassname = `${
    isDarkTheme ? s2.cellStatusDark : s2.cellStatusLight
  } ${getStatusClassname(review.status || 'NEW')}`

  return (
    <Table.Row key={review.id}>
      <Table.Cell className={s.tableCell} width={200}>
        {`${review.student.lastName} ${review.student.firstName}`}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={150}>
        <div className={s.linksContainer}>
          <Tooltip title={review.projectLink}>
            <a
              className={'commonLinkText hiddenText'}
              href={review.projectLink}
              rel={'noopener noreferrer'}
              target={'_blank'}
            >
              {review.projectLink}
            </a>
          </Tooltip>

          <Tooltip title={review.codeLink}>
            <a
              className={'commonLinkText hiddenText'}
              href={review.codeLink}
              rel={'noopener noreferrer'}
              target={'_blank'}
            >
              {review.codeLink}
            </a>
          </Tooltip>
        </div>
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={100}>
        {moment(review.updated_at).format('DD/MM/YYYY')}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={200}>
        <Select
          className={statusClassname}
          disabled={isLoading}
          fullWidth
          onChange={onReviewStatusChange}
          size={'small'}
          value={review.status}
        >
          <MenuItem
            disabled={review.status !== ReviewStatusEnum.IN_PROGRESS}
            value={ReviewStatusEnum.NEW}
          >
            Новый
          </MenuItem>
          <MenuItem
            disabled={review.status !== ReviewStatusEnum.NEW}
            value={ReviewStatusEnum.IN_PROGRESS}
          >
            В процессе
          </MenuItem>
          <MenuItem disabled value={ReviewStatusEnum.FORWARDED}>
            Перепроверка
          </MenuItem>
          <MenuItem disabled value={ReviewStatusEnum.ACCEPTED}>
            Принято
          </MenuItem>
          <MenuItem disabled value={ReviewStatusEnum.REJECTED}>
            Отклонен
          </MenuItem>
        </Select>
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={200}>
        {review.mentor ? review.mentor.name : '-'}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={150}>
        {courses?.map(
          course => review.courseId === course.id && capitalizeFirstLetter(course.title)
        )}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={200}>
        <Button
          disabled={!review.mentor || admin.id !== review.mentor.id}
          onClick={() => setShowReviewModalId(review.id)}
          variant={'secondary'}
        >
          Отправить отзыв
        </Button>
      </Table.Cell>

      {showReviewModalId === review.id && (
        <ReviewModal
          defaultFeedbacks={review.feedbacks}
          onClose={() => setShowReviewModalId(-1)}
          open={showReviewModalId === review.id}
          reviewId={review.id}
          reviewStatus={review.status}
        />
      )}
    </Table.Row>
  )
}

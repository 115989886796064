import { EnvironmentVariable } from './app-settings'

export class ApiSettings {
  public readonly REACT_APP_BASE_URL: string
  public readonly REACT_APP_LOGIN_URL: string
  public readonly REACT_APP_SAMURAI_HUB_BACK_URL: string
  public readonly REACT_APP_SAMURAI_HUB_MESSENGER: string
  public readonly REACT_APP_VIDEO_URL: string

  constructor(envVariables: EnvironmentVariable) {
    this.REACT_APP_BASE_URL = envVariables.REACT_APP_BASE_URL as string
    this.REACT_APP_LOGIN_URL = envVariables.REACT_APP_LOGIN_URL as string
    this.REACT_APP_VIDEO_URL = envVariables.REACT_APP_VIDEO_URL as string
    this.REACT_APP_SAMURAI_HUB_BACK_URL = envVariables.REACT_APP_SAMURAI_HUB_BACK_URL as string
    this.REACT_APP_SAMURAI_HUB_MESSENGER = envVariables.REACT_APP_SAMURAI_HUB_MESSENGER as string
  }
}

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Pagination } from '@it-incubator/ui-kit'
import { LoadingOverlay } from 'common/components/loading-overlay/LoadingOverlay'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { useGetAllPlaylistsQuery } from 'features/playlists/service'
import {
  VideosQueries,
  useAddDaysForVideoSettingsMutation,
  useGetVideosQuery,
} from 'features/videos/service'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, restoreState } from 'utils'

import s from './Videos.module.css'

import { VideosHeader } from './videos-header/VideosHeader'
import { VideosTable } from './videos-table/VideosTable'

export const Videos = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50)
  const [search, setSearch] = useState<string>('')
  const [playlistId, setPlaylistId] = useState<string>('')
  const [courseId, setCourseId] = useState<number | string>('')
  const [sort, setSort] = useState('')
  const [settingIds, setSettingIds] = useState<string[]>([])

  const debouncedSearch = useDebounce(search, 1000)

  const videosQueries: VideosQueries = {
    filters: {
      course: courseId,
      playlist: playlistId,
      search,
    },
    sorting: {
      sort,
    },
  }

  const {
    data,
    isFetching,
    isLoading: isVideosLoading,
  } = useGetVideosQuery({
    course: courseId,
    page,
    pageSize,
    playlist: playlistId,
    search: debouncedSearch,
    sort,
  })

  const { data: coursesResponse, isLoading: isCoursesLoading } = useGetCoursesQuery()
  const courses = coursesResponse?.items

  const { data: playlistsResponse, isLoading: isPlaylistsLoading } = useGetAllPlaylistsQuery({})
  const playlists = playlistsResponse?.playlists

  const [addDaysForVideoSettings, { isLoading: isAddDaysLoading }] =
    useAddDaysForVideoSettingsMutation()

  const isLoading = isVideosLoading || isPlaylistsLoading || isCoursesLoading || isAddDaysLoading

  const addDaysHandler = () => {
    addDaysForVideoSettings(settingIds)
      .unwrap()
      .then(() => {
        toast.success('Выбранные даты успешно продлены')
        setSettingIds([])
      })
      .catch(() => toast.error('Произошла ошибка'))
  }

  const perPageChangeHandler = (itemPerPage: number) => {
    setPageSize(itemPerPage)
    setPage(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    let restoredVideosQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, videosQueries)
    const { course, playlist, search } = restoredVideosQueries.filters

    if (!restoredVideosQueries.sorting) {
      restoredVideosQueries = {
        ...videosQueries,
        sorting: { ...videosQueries.sorting },
      }
    }

    const { sort } = restoredVideosQueries.sorting

    if (search) {
      setSearch(search)
    }
    if (playlist) {
      setPlaylistId(playlist)
    }
    if (course) {
      setCourseId(course)
    }
    if (sort) {
      setSort(sort)
    }
  }, [])

  return (
    <div>
      <VideosHeader
        courseId={courseId}
        courses={courses}
        playlistId={playlistId}
        playlists={playlists}
        search={search}
        setCourseId={setCourseId}
        setPage={setPage}
        setPlaylistId={setPlaylistId}
        setSearch={setSearch}
        setSort={setSort}
        sort={sort}
        videosQueries={videosQueries}
      />

      <VideosTable
        courseId={courseId}
        courses={courses}
        playlistId={playlistId}
        playlists={playlists}
        setSettingIds={setSettingIds}
        settingIds={settingIds}
        videosData={data?.videos}
      />

      <LoadingOverlay open={isFetching || isLoading} />

      <div className={s.footerContainer}>
        <Pagination
          count={Math.ceil((data?.totalCount ?? 1) / pageSize)}
          onChange={setPage}
          onPerPageChange={perPageChangeHandler}
          page={page}
          perPage={pageSize}
          perPageOptions={[10, 20, 50, 100, 500]}
        />

        <Button
          disabled={settingIds.length === 0 || isLoading}
          onClick={addDaysHandler}
          variant={'secondary'}
        >
          Продлить выбранные даты
        </Button>
      </div>
    </div>
  )
}

import { Grid, InputLabel } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers'
import { MAX_LESSON, MAX_LEVEL, renderMenuItems } from 'utils'

type Props = {
  accessLevel: number
  changeAccessLevel: (event: SelectChangeEvent<number>) => void
  changeEndDate: (event: null | number) => void
  changeForLesson: (event: SelectChangeEvent<number>) => void
  changeStartDate: (event: null | number) => void
  endDate: number
  forLesson: number
  startDate: number
}

export const Settings = ({
  accessLevel,
  changeAccessLevel,
  changeEndDate,
  changeForLesson,
  changeStartDate,
  endDate,
  forLesson,
  startDate,
}: Props) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      marginBottom={2}
      marginTop={2}
    >
      <Grid width={'24%'}>
        <FormControl fullWidth variant={'outlined'}>
          <InputLabel>Доступ с</InputLabel>
          <Select label={'Доступ с'} onChange={changeAccessLevel} value={accessLevel}>
            {renderMenuItems(MAX_LEVEL, 'уровень')}
          </Select>
        </FormControl>
      </Grid>

      <Grid width={'24%'}>
        <FormControl fullWidth variant={'outlined'}>
          <InputLabel>Для урока</InputLabel>
          <Select label={'Для урока'} onChange={changeForLesson} value={forLesson}>
            {renderMenuItems(MAX_LESSON, 'урок')}
          </Select>
        </FormControl>
      </Grid>

      <Grid width={'24%'}>
        <DatePicker label={'Для студентов с'} onChange={changeStartDate} value={startDate} />
      </Grid>

      <Grid width={'24%'}>
        <DatePicker label={'Для студентов до'} onChange={changeEndDate} value={endDate} />
      </Grid>
    </Grid>
  )
}

import axios, { AxiosError } from 'axios'

export const errorHandler = (err: any) => {
  const errors = err as AxiosError | Error

  if (axios.isAxiosError(err)) {
    return err.response ? err?.response?.data.error : err.message
  } else {
    return errors.message
  }
}

import { StudentsPhotosTableHeader } from './students-photos-table-header/StudentsPhotosTableHeader'
import { StudentsPhotosTableRow } from './students-photos-table-row/StudentsPhotosTableRow'

type TableData = {
  _id: string
  avatar: string
  firstName: string
  lastName: string
  updated: string
}

type Props = {
  data: TableData[]
  handleSelectAllClicked: (selectedAll: boolean) => void
  isAtLeastOneStudentSelected: boolean
  isDisabledDeleteButtons: boolean
  onOpenImagePreview: (src: string) => void
  onRowDeleted: (id: string, name: string) => void
  onRowSelected: (id: string) => void
  selectedStudents: string[]
}

export const StudentsPhotosTable = ({
  data,
  handleSelectAllClicked,
  isAtLeastOneStudentSelected,
  isDisabledDeleteButtons,
  onOpenImagePreview,
  onRowDeleted,
  onRowSelected,
  selectedStudents,
}: Props) => {
  return (
    <>
      <StudentsPhotosTableHeader
        isAtLeastOneStudentSelected={isAtLeastOneStudentSelected}
        onSelectAll={handleSelectAllClicked}
      />

      <main>
        {data.map(d => {
          const selected = selectedStudents.includes(d._id)

          return (
            <StudentsPhotosTableRow
              avatar={d.avatar}
              deleteDisabled={isDisabledDeleteButtons}
              id={d._id}
              key={d._id}
              name={`${d.lastName} ${d.firstName}`}
              onDelete={onRowDeleted}
              onImageClicked={onOpenImagePreview}
              onSelected={onRowSelected}
              selected={selected}
              updatedDate={getDate(d.updated)}
            />
          )
        })}
      </main>
    </>
  )
}

function getDate(date: string) {
  const formatted = new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  const time = new Date(date).toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  return `${formatted} в ${time}`
}

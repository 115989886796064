import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import { LessonReviewsArgs, LessonReviewsResponse } from '../service/types'

export const lessonReviewsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_BASE_URL,
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: builder => ({
    getLessonsReviews: builder.query<LessonReviewsResponse, LessonReviewsArgs>({
      query: ({ courseId, itemsForPage, mentorId, page, reaction }) => {
        return {
          params: {
            courseId,
            itemsForPage,
            mentorId,
            page,
            reaction,
          },
          url: 'lessons/for-admin/reviews',
        }
      },
    }),
  }),
  reducerPath: 'lessonReviews',
  refetchOnMountOrArgChange: true,
})

export const { useGetLessonsReviewsQuery } = lessonReviewsService

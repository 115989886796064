import { Dispatch, SetStateAction } from 'react'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ru } from 'date-fns/locale/ru'
import { transformDate } from 'utils'

import s from '../WorkItem.module.css'

type Props = {
  date: Date
  editMode: boolean
  setDate: Dispatch<SetStateAction<Date>>
}

export const StartDate = ({ date, editMode, setDate }: Props) => {
  const startDate = transformDate(
    date.toLocaleDateString('ru-RU', {
      month: 'long',
      year: 'numeric',
    })
  )

  return (
    <div>
      {editMode ? (
        <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'Дата трудоустройства'}
            maxDate={new Date()}
            minDate={new Date('2017-01-01')}
            onChange={newValue => {
              newValue && setDate(new Date(newValue.setDate(3)))
            }}
            slotProps={{
              textField: { fullWidth: true, helperText: null },
            }}
            value={date}
            views={['year', 'month']}
          />
        </LocalizationProvider>
      ) : (
        <span className={s.textSecondary}>{startDate}</span>
      )}
    </div>
  )
}

import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { ReadMore, Table } from '@it-incubator/ui-kit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { IconButton } from '@mui/material'
import { PATH } from 'common/components/routing/Routing'
import { Comment } from 'features/comments/service'
import { Course } from 'services/courses'

import s from '../Comments.module.css'

import { ConfirmDeleteCommentModal } from './confirm-delete-comment-modal/ConfirmDeleteCommentModal'

type Props = {
  comment: Comment
  courses: Course[]
}

export const CommentsTableRow = ({ comment, courses }: Props) => {
  const [showModalId, setShowModalId] = useState('')

  const { courseIds, created, firstName, id, lastName, text, videoAuthor, videoId, videoName } =
    comment

  const course = courses
    .filter(course => courseIds.includes(course.id))
    .map(course => course.title)
    .join(', ')

  return (
    <>
      <Table.Row key={id}>
        <Table.Cell className={s.tableCell} width={300}>
          <NavLink className={'commonLinkText'} to={`${PATH.VIDEOS}${PATH.WATCH}/${videoId}`}>
            {videoName}
          </NavLink>
        </Table.Cell>

        <Table.Cell className={s.tableCell} width={170}>{`${firstName} ${lastName}`}</Table.Cell>

        <Table.Cell className={s.tableCell} width={200}>
          <ReadMore maxLength={20} text={course} />
        </Table.Cell>

        <Table.Cell className={s.tableCell} width={150}>
          {new Date(created).toLocaleString('ru-RU')}
        </Table.Cell>

        <Table.Cell className={s.tableCell} width={170}>
          {videoAuthor || '-'}
        </Table.Cell>

        <Table.Cell className={s.tableCell}>
          <ReadMore maxLength={200} text={text} />
        </Table.Cell>

        <Table.Cell className={s.tableCell} width={10}>
          <IconButton onClick={() => setShowModalId(id)}>
            <DeleteIcon />
          </IconButton>
        </Table.Cell>
      </Table.Row>

      {showModalId === id && (
        <ConfirmDeleteCommentModal
          commentId={id}
          firstName={firstName}
          lastName={lastName}
          onClose={() => setShowModalId('')}
          open={showModalId === id}
        />
      )}
    </>
  )
}

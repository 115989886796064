import { useState } from 'react'

import { selectIsDarkTheme, uiActions } from 'app'
import { useActions, useAppSelector } from 'common/hooks'
import { Work, usePutStudentWorksMutation } from 'features/dev-club/service'

export const useWorkItem = (studentId: number, works: Work[], work: Work) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme)

  const { showSnackbar } = useActions(uiActions)

  const [rank, setRank] = useState(work.rank)
  const [speciality, setSpeciality] = useState(work.speciality)
  const [company, setCompany] = useState(work.company)
  const [date, setDate] = useState(new Date(work.start * 1000))
  const [editMode, setEditMode] = useState(false)

  const [putStudentWorks, { isLoading }] = usePutStudentWorksMutation()

  const handleSubmit = () => {
    const start = Math.floor(new Date(date).getTime() / 1000)

    const worksModel = {
      studentId: studentId,
      works: works.map(workItem => {
        const updatedWork = {
          ...workItem,
          company,
          rank,
          speciality,
          start,
        }

        return workItem._id === work._id ? updatedWork : { ...workItem }
      }),
    }

    putStudentWorks(worksModel)
      .unwrap()
      .then(() => {
        showSnackbar({
          message: 'Изменения успешно сохранены',
          severity: 'success',
        })
      })
      .catch(err => {
        showSnackbar({
          message: err.data.error,
          severity: 'error',
        })
      })
      .finally(() => setEditMode(false))
  }

  const cancelButtonHandler = () => {
    setEditMode(false)
    setRank(work.rank)
    setSpeciality(work.speciality)
    setCompany(work.company)
    setDate(new Date(work.start * 1000))
  }

  return {
    cancelButtonHandler,
    company,
    date,
    editMode,
    handleSubmit,
    isDarkTheme,
    isLoading,
    rank,
    setCompany,
    setDate,
    setEditMode,
    setRank,
    setSpeciality,
    speciality,
  }
}

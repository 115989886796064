import { toast } from 'react-toastify'

import { Button, Modal } from '@it-incubator/ui-kit'
import { useDeleteCommentMutation } from 'features/comments/service'

import s from './ConfirmDeleteCommentModal.module.css'

type Props = {
  commentId: string
  firstName: string
  lastName: string
  onClose: () => void
  open: boolean
}

export const ConfirmDeleteCommentModal = ({
  commentId,
  firstName,
  lastName,
  onClose,
  open,
}: Props) => {
  const [deleteComment, { isLoading: isLoadingDelete }] = useDeleteCommentMutation()

  const handleDeleteClicked = () => {
    deleteComment(commentId)
      .unwrap()
      .then(() => toast.success('Комментарий успешно удален'))
      .catch(() => toast.error('Что-то пошло не так'))

    onClose()
  }

  return (
    <Modal onClose={onClose} open={open} size={'sm'} title={'Удалить'}>
      <div>
        Вы уверены, что хотите удалить
        <br />
        <b>комментарий ({`${lastName} ${firstName}`})?</b>
      </div>

      <div className={s.buttonsWrapper}>
        <Button disabled={isLoadingDelete} onClick={handleDeleteClicked} variant={'secondary'}>
          Да
        </Button>

        <Button onClick={onClose}>Нет</Button>
      </div>
    </Modal>
  )
}

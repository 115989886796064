import { KeyboardEvent } from 'react'

import { Button } from '@mui/material'
import { CustomModal } from 'common/components/custom-modal/CustomModal'

import s from './ConfirmSavingModal.module.css'

type Props = {
  firstName: string
  isLoading: boolean
  lastName: string
  onClose: () => void
  onSubmit: () => void
  open: boolean
}

export const ConfirmSavingModal = ({
  firstName,
  isLoading,
  lastName,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <CustomModal
      modalTitle={'Сохранить'}
      onClose={onClose}
      onKeyDown={handleKeyDown}
      open={open}
      width={478}
    >
      <div className={s.text}>
        Вы уверены, что хотите сохранить ссылку на видеоотзыв студента{' '}
        <b>{`${firstName} ${lastName}`}?</b>
      </div>
      <div className={s.buttonsWrapper}>
        <Button color={'info'} onClick={onClose}>
          Отмена
        </Button>
        <Button disabled={isLoading} onClick={onSubmit}>
          Сохранить
        </Button>
      </div>
    </CustomModal>
  )
}

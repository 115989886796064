import { App } from 'app/App'
import { createRoot } from 'react-dom/client'
import { zodValidation } from 'zod-validation'

import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@it-incubator/ui-kit/dist/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'suneditor/dist/css/suneditor.min.css'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

zodValidation()

root.render(<App />)

import { CommonColumn } from 'common/components/custom-table/CustomTable'

export const playlistsTableData: CommonColumn[] = [
  { id: 1, isSort: false, name: 'Название', title: 'name', width: '40%' },
  {
    id: 2,
    isSort: false,
    name: 'Доступно для курса (с какого уровня)',
    title: 'coursesAccess',
    width: '30%',
  },
  {
    id: 3,
    isSort: false,
    name: 'Доступен до',
    title: 'maxStudentStartCourseDate',
    width: '20%',
  },
  { id: 4, isSort: false, name: '', title: '', width: '10%' },
]

import { appSettings } from 'settings/app-settings'
import io, { Socket } from 'socket.io-client'

const baseURL = appSettings.api.REACT_APP_SAMURAI_HUB_MESSENGER

export class SocketIoApi {
  static socket: Socket | null = null

  static createConnection(token: string) {
    const options = {
      extraHeaders: {
        roomKey: 'chat',
        token,
      },
    }

    this.socket = io(baseURL + 'api/group', options)

    this.socket.on('connect', () => {
      console.log('ws:connect')
    })

    this.socket.on('disconnect', e => {
      console.log('ws:disconnect')
    })
  }
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'

import { LabsCoursesResponse } from '../courses'

export const labsCoursesAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_LOGIN_URL,
    credentials: 'include',
  }),
  endpoints: build => ({
    getCourses: build.query<LabsCoursesResponse, void>({
      providesTags: () => ['allCourses'],
      query: () => {
        return {
          url: '/courses?PageSize=50&SortBy=id&SortDirection=0',
        }
      },
    }),
  }),
  reducerPath: 'labsCoursesAPI',
  tagTypes: ['allCourses'],
})

export const { useGetCoursesQuery } = labsCoursesAPI

import { ReactNode } from 'react'

import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'

import s from './SuperCheckbox.module.css'

interface Props extends CheckboxProps {
  children?: ReactNode
  divClassName?: string
}

export const SuperCheckbox = ({ children, divClassName, ...rest }: Props) => {
  return (
    <div className={`${s.checkedBlock} ${divClassName}`}>
      <FormControlLabel control={<Checkbox {...rest} />} label={children} />
    </div>
  )
}

import { FeedbackModel } from 'features/code-review/service'

export const compareArrays = (array1: string[], array2: FeedbackModel[]): boolean => {
  for (let i = 0; i < array1.length; i++) {
    if (!array2.find(item => item.type === array1[i])) {
      return true
    }
  }

  return false
}

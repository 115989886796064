import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { appSettings } from 'settings/app-settings'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import {
  ChatsArgsType,
  ChatsResponseType,
  GroupMessagesModel,
  GroupMessagesResponse,
  MeArgs,
  MeResponse,
  PostManagerModel,
  PostMessageModel,
  ResolveIssueArgs,
  UnreadCountsResponse,
} from '../service'

export const chatsService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.api.REACT_APP_SAMURAI_HUB_BACK_URL + 'api/group',
    prepareHeaders: headers => {
      const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

      headers.set('token', token)

      return headers
    },
  }),
  endpoints: build => ({
    getChatById: build.query<GroupMessagesResponse, { groupId: string; time: string }>({
      query: ({ groupId, time }) => {
        return {
          params: {
            count: 10,
            time,
          },
          url: `students/group/${groupId}/messages`,
        }
      },
    }),
    getChats: build.query<ChatsResponseType, ChatsArgsType>({
      providesTags: () => ['chats'],
      query: ({ managerId, page, pageSize, student, tab }) => {
        return {
          params: {
            managerId,
            page,
            pageSize,
            student,
          },
          url: `students/${tab}`,
        }
      },
    }),
    getUnreadCounts: build.query<UnreadCountsResponse, void>({
      providesTags: () => ['chats'],
      query: () => {
        return {
          url: 'students/not/read/count',
        }
      },
    }),
    loginMessenger: build.mutation<MeResponse, MeArgs>({
      query: (args: MeArgs) => {
        return {
          body: args,
          method: 'POST',
          url: 'admin/me',
        }
      },
    }),
    postGroupMessages: build.mutation<GroupMessagesResponse, GroupMessagesModel>({
      invalidatesTags: () => ['chats'],
      query: (groupMessagesModel: GroupMessagesModel) => {
        return {
          body: groupMessagesModel,
          method: 'POST',
          url: 'students/group/messages',
        }
      },
    }),
    postManager: build.mutation<unknown, PostManagerModel>({
      query: (postManagerModel: PostManagerModel) => {
        const { groupId, id } = postManagerModel.admin

        return {
          body: postManagerModel,
          method: 'POST',
          url: `${id}/${groupId}`,
        }
      },
    }),
    postMessage: build.mutation<unknown, PostMessageModel>({
      query: (messageModel: PostMessageModel) => {
        return {
          body: messageModel,
          method: 'POST',
          url: 'admin/message',
        }
      },
    }),
    resolveIssue: build.mutation<unknown, ResolveIssueArgs>({
      invalidatesTags: () => ['chats'],
      query: (args: ResolveIssueArgs) => {
        const { groupId, messageId } = args

        return {
          method: 'POST',
          url: `status/${groupId}/${messageId}`,
        }
      },
    }),
  }),
  reducerPath: 'chatsService',
  refetchOnMountOrArgChange: true,
  tagTypes: ['chats'],
})

export const {
  useGetChatByIdQuery,
  useGetChatsQuery,
  useGetUnreadCountsQuery,
  useLoginMessengerMutation,
  usePostGroupMessagesMutation,
  usePostManagerMutation,
  usePostMessageMutation,
  useResolveIssueMutation,
} = chatsService

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Button, Divider, IconButton, Skeleton } from '@mui/material'
import { CompanyIcon } from 'assets/icons/adaptive-icons/CompanyIcon'
import { useWorkItem } from 'features/dev-club/hooks'
import { Work } from 'features/dev-club/service'

import s from './WorkItem.module.css'

import { Company } from './company/Company'
import { Rank } from './rank/Rank'
import { Speciality } from './speciality/Speciality'
import { StartDate } from './start-date/StartDate'

type Props = {
  showDivider: boolean
  studentId: number
  work: Work
  works: Work[]
}

export const WorkItem = ({ showDivider, studentId, work, works }: Props) => {
  const {
    cancelButtonHandler,
    company,
    date,
    editMode,
    handleSubmit,
    isDarkTheme,
    isLoading,
    rank,
    setCompany,
    setDate,
    setEditMode,
    setRank,
    setSpeciality,
    speciality,
  } = useWorkItem(studentId, works, work)

  if (isLoading) {
    return <Skeleton height={'110px'} />
  }

  return (
    <>
      <div className={s.workItem}>
        <div className={s.container}>
          <div className={s.avatar}>
            <CompanyIcon isDarkTheme={isDarkTheme} />
          </div>

          <div className={s.workInfoBlock}>
            <div className={isDarkTheme ? s.textMainDark : s.textMain}>
              <Rank editMode={editMode} rank={rank || 0} setRank={setRank} />
              <Speciality
                editMode={editMode}
                setSpeciality={setSpeciality}
                speciality={speciality}
              />
            </div>

            <Company company={company} editMode={editMode} setCompany={setCompany} />

            <StartDate date={date} editMode={editMode} setDate={setDate} />
          </div>
        </div>

        {editMode && (
          <div>
            <Button className={s.submitButton} onClick={handleSubmit}>
              Сохранить
            </Button>

            <Button color={'info'} onClick={cancelButtonHandler}>
              Отмена
            </Button>
          </div>
        )}

        {!editMode && (
          <IconButton onClick={() => setEditMode(true)}>
            <DriveFileRenameOutlineIcon />
          </IconButton>
        )}
      </div>

      {showDivider && <Divider />}
    </>
  )
}

import { Controller, useForm } from 'react-hook-form'

import { Button } from '@mui/material'
import { uiActions } from 'app'
import { CustomModal } from 'common/components/custom-modal/CustomModal'
import { SANITIZE_HTML_OPTIONS } from 'common/constants'
import { useActions } from 'common/hooks'
import { useUpdateReviewMutation } from 'features/dev-club/service'
import sanitizeHtml from 'sanitize-html'
import SunEditor, { buttonList } from 'suneditor-react'

import s from './StudentReviewModal.module.css'

type Props = {
  firstName: string
  lastName: string
  onClose: () => void
  open: boolean
  page: number
  pageCount: number
  review: string
  status: string
  studentId: number
}

export const StudentReviewModal = ({
  firstName,
  lastName,
  onClose,
  open,
  page,
  pageCount,
  review,
  status,
  studentId,
}: Props) => {
  const { showSnackbar } = useActions(uiActions)

  const [updateReview] = useUpdateReviewMutation()

  const onSubmit = async (data: { review: string }) => {
    try {
      await updateReview({
        lastName,
        page,
        pageCount,
        review: data.review,
        status,
        studentId,
      })
      showSnackbar({
        message: 'Review updated',
        severity: 'success',
      })
    } catch (err: any) {
      showSnackbar({
        message: err.message,
        severity: 'error',
      })
    } finally {
      onClose()
    }
  }

  const { control, handleSubmit } = useForm<{ review: string }>()

  return (
    <CustomModal
      modalTitle={
        <span>
          Отзыв от студента <b>{`${lastName} ${firstName}`}</b>
        </span>
      }
      onClose={onClose}
      open={open}
      width={1012}
    >
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue={replaceLineBreaks(review)}
          name={'review'}
          render={({ field }) => {
            const { ref, ...restOfField } = field

            return (
              <SunEditor
                {...restOfField}
                autoFocus
                onChange={event => field.onChange(sanitizeHtml(event, SANITIZE_HTML_OPTIONS))}
                setContents={field.value}
                setDefaultStyle={"p {margin: 0px}; font-family: 'Roboto', sans-serif;"}
                setOptions={{
                  buttonList: [...buttonList.complex, ['textStyle']],
                  height: '600px',
                }}
              />
            )
          }}
        />
        <Button type={'submit'}>Сохранить изменения в отзыве</Button>
      </form>
    </CustomModal>
  )
}

function replaceLineBreaks(text?: string) {
  return text?.replaceAll('\n', '<br>') || ''
}

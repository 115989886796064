import { Setting, VideoSettingsState } from 'features/videos/service'

export const restoreVideoSettingsState = (
  defaultSelectedPlaylistsIds: string[],
  settings: Setting[]
): VideoSettingsState => {
  const defaultVideoSettingsEmptyState = defaultSelectedPlaylistsIds.reduce((target, key) => {
    target[key] = []

    return target
  }, {} as VideoSettingsState)

  return settings.reduce((target, key) => {
    const {
      accessLevel,
      courseId,
      forLesson,
      id,
      maxStudentStartCourseDate,
      minStudentStartCourseDate,
      playlistId,
    } = key

    const newSetting = {
      accessLevel,
      courseId,
      forLesson,
      id,
      maxStudentStartCourseDate,
      minStudentStartCourseDate,
      playlistId,
    }

    target[playlistId] = [...target[playlistId], newSetting]

    return target
  }, defaultVideoSettingsEmptyState)
}

import { Close, MenuDots } from '@it-incubator/ui-kit'
import { selectUI, uiThunks } from 'app'
import { useActions, useAppSelector } from 'common/hooks'

import s from './MenuButton.module.scss'

export function MenuButton() {
  const { isNavBarOpen } = useAppSelector(selectUI)
  const { setNavbar } = useActions(uiThunks)

  const onMenuClicked = () => {
    setNavbar(!isNavBarOpen)
  }

  return (
    <button className={s.button} onClick={onMenuClicked}>
      {isNavBarOpen ? <Close /> : <MenuDots />}
    </button>
  )
}

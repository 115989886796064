type Props = {
  isDarkTheme: boolean
}

export const WorkIcon = ({ isDarkTheme }: Props) => (
  <svg
    fill={'none'}
    height={'19'}
    viewBox={'0 0 20 19'}
    width={'20'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      clipRule={'evenodd'}
      d={
        'M12 4V2H8V4H12ZM2 6V17H18V6H2ZM18 4C19.11 4 20 4.89 20 6V17C20 18.11 19.11 19 18 19H2C0.89 19 0 18.11 0 17L0.00999999 6C0.00999999 4.89 0.89 4 2 4H6V2C6 0.89 6.89 0 8 0H12C13.11 0 14 0.89 14 2V4H18Z'
      }
      fill={isDarkTheme ? 'white' : 'black'}
      fillRule={'evenodd'}
    />
  </svg>
)

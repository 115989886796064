import { MAX_TWO_DIGIT_NUMBER } from 'common/constants'

import s from './Notification.module.scss'

type Props = {
  color?: 'primary' | 'secondary'
  unreadCount: number
}

export const Notification = ({ color = 'primary', unreadCount }: Props) => {
  const count = unreadCount <= MAX_TWO_DIGIT_NUMBER ? unreadCount : `${MAX_TWO_DIGIT_NUMBER}+`

  return <div className={color === 'secondary' ? s.secondaryColor : s.notification}>{count}</div>
}

import { AlertProps } from '@mui/material/Alert'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppStore } from 'app/store'

type InitialState = {
  isNavBarOpen: boolean
  snackbarMessage: string
  snackbarOpen: boolean
  snackbarSeverity: AlertProps['severity']
}
export const setNavbar = createAsyncThunk('ui/setDarkTheme', async (payload: boolean, thunkAPI) => {
  const serializedState = JSON.stringify(payload)

  localStorage.setItem('isNavBarOpen', serializedState)
  thunkAPI.dispatch(uiActions.setNavBarOpen(payload))
})
export const uiSlice = createSlice({
  initialState: {
    snackbarMessage: '',
    snackbarOpen: false,
    snackbarSeverity: undefined,
  } as InitialState,
  name: 'ui',
  reducers: {
    clearSnackbarMessage: state => {
      state.snackbarOpen = false
      state.snackbarMessage = ''
    },
    setNavBarOpen: (state, action: PayloadAction<boolean>) => {
      state.isNavBarOpen = action.payload
    },
    showSnackbar: (
      state,
      action: PayloadAction<{
        message: string
        severity: AlertProps['severity']
      }>
    ) => {
      state.snackbarOpen = true
      state.snackbarSeverity = action.payload.severity
      state.snackbarMessage = action.payload.message
    },
  },
})

export const uiReducer = uiSlice.reducer
export const uiActions = uiSlice.actions
export const uiThunks = { setNavbar }
export const selectUI = (state: AppStore) => state.ui

import { HTMLAttributes } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ArrowBack, Typography } from '@it-incubator/ui-kit'
import { Grid } from '@mui/material'

import s from './PageHeader.module.css'

interface Props extends HTMLAttributes<HTMLDivElement> {
  backButtonText?: string
  onClick?: () => void
  onGoBack?: () => void
  title: string
}

// в children передаётся кнопка (или div с кнопками),
// отвечающая за основное действие страницы (добавить урок/видео...)
//если нужна кнопка назад - передать backButtonText

export const PageHeader = ({
  backButtonText,
  children,
  onClick,
  onGoBack,
  title,
  ...rest
}: Props) => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Grid container {...rest}>
      {backButtonText && (
        <Typography.Body2 className={s.backLink} component={Link} onClick={goBack}>
          <ArrowBack size={16} /> {backButtonText}
        </Typography.Body2>
      )}

      <Grid alignItems={'center'} container item justifyContent={'space-between'}>
        <Grid item onClick={onClick}>
          <Typography.H1>{title}</Typography.H1>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Grid>
  )
}

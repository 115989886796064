export type CodeReviewsArgs = {
  courseId?: number | string
  onlyTakenByMe?: boolean | string
  pageNumber?: number
  pageSize?: number
  searchNameTerm?: string
  status?: string
}

export type CodeReviewsResponse = {
  reviews: Review[]
  totalCount: number
}

export type Review = {
  codeLink: string
  courseId: number
  created_at: string
  feedbacks: FeedbackModel[]
  id: number
  mentor: Mentor
  projectLink: string
  projectTitle: string
  reviewer_id: number
  status: ReviewStatusType
  student: Student
  student_id: number
  updated_at: string
}

export type FeedbackModel = {
  feedback?: string
  id?: number
  score?: number // 0-10
  title?: string
  type?: FeedbackType
}

export type Mentor = {
  createdAt: string
  id: number
  name: string
  updatedAt: string
}

export type Student = {
  createdAt: string
  firstName: string
  id: number
  lastName: string
  updatedAt: string
}

export type Sorting = {}

export type CodeReviewQueries = {
  filters: CodeReviewsArgs
  sorting: Sorting
}

export type ReviewStatusType = 'ACCEPTED' | 'FORWARDED' | 'IN_PROGRESS' | 'NEW' | 'REJECTED'
type FeedbackType = 'CODE_QUALITY' | 'GENERAL' | 'PRODUCT_QUALITY' | 'REJECTED' | 'TESTS' | 'TOOLS'

export enum ReviewStatusEnum {
  ACCEPTED = 'ACCEPTED',
  FORWARDED = 'FORWARDED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
}

export enum FeedbackTypeEnum {
  CODE_QUALITY = 'CODE_QUALITY',
  GENERAL = 'GENERAL',
  PRODUCT_QUALITY = 'PRODUCT_QUALITY',
  REJECTED = 'REJECTED',
  TESTS = 'TESTS',
  TOOLS = 'TOOLS',
}

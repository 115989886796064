export const PERS_AREA_ADMIN_TOKEN = 'pers-area-admin-token'
export const PERS_AREA_MESSENGER_TOKEN = 'pers-area-messenger-token'

export const LOCAL_STORAGE_QUERIES_KEYS = {
  CHAT: 'pa-admin:chat-queries',
  CODE_REVIEW: 'pa-admin:code-review-queries',
  COMMENTS: 'pa-admin:comments-queries',
  COURSE_REVIEWS: 'pa-admin:course-reviews-queries',
  CV: 'pa-admin:cv-queries',
  DEV_CLUB: 'pa-admin:dev-club-queries',
  FAQ: 'pa-admin:faq-queries',
  MOOD: 'pa-admin:mood-queries',
  PLAYLISTS: 'pa-admin:playlists-queries',
  STUDENTS: 'pa-admin:students-queries',
  STUDENTS_PHOTOS: 'pa-admin:students-photos-queries',
  VIDEOS: 'pa-admin:videos-queries',
}

export const getToken = () => ({
  token: JSON.parse(localStorage.getItem(PERS_AREA_ADMIN_TOKEN) || '1'),
})

export const saveState = <T>(key: string, state: T) => {
  const stateAsString = JSON.stringify(state)

  localStorage.setItem(key, stateAsString)
}

export const restoreState = <T>(key: string, defaultState: T) => {
  const stateAsString = localStorage.getItem(key)

  if (stateAsString !== null) {
    defaultState = JSON.parse(stateAsString) as T
  }

  return defaultState
}

export const loadTheme = () => {
  try {
    const serializedState = localStorage.getItem('isDarkTheme')

    if (!serializedState) {
      return true
    }

    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export const loadNavBarStatus = () => {
  try {
    const serializedState = localStorage.getItem('isNavBarOpen')

    if (!serializedState) {
      return false
    }

    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

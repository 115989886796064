import { Alert, Snackbar } from '@mui/material'
import { selectUI, uiActions } from 'app'
import { ReactComponent as Error } from 'assets/icons/ErrorIcon.svg'
import { ReactComponent as Info } from 'assets/icons/InfoIcon.svg'
import { ReactComponent as Success } from 'assets/icons/SuccessIcon.svg'
import { useActions, useAppSelector } from 'common/hooks'

export const CustomSnackbar = () => {
  const { snackbarMessage, snackbarOpen, snackbarSeverity } = useAppSelector(selectUI)
  const { clearSnackbarMessage } = useActions(uiActions)
  let icon

  if (snackbarSeverity === 'error') {
    icon = <Error />
  } else if (snackbarSeverity === 'info') {
    icon = <Info />
  } else {
    icon = <Success />
  }

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={6000}
      onClose={() => clearSnackbarMessage()}
      open={snackbarOpen}
      sx={{ width: '420px' }}
    >
      <Alert
        color={'info'}
        icon={icon}
        onClose={() => clearSnackbarMessage()}
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )
}

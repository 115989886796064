import { HTMLAttributes, MouseEvent, useState } from 'react'
import { useForm } from 'react-hook-form'

import ClearIcon from '@mui/icons-material/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'
import { Screen } from 'common/api/upload-file-api'
import { FormInput } from 'common/components/photo-uploader/PhotoUploader'
import { PreviewImage } from 'common/components/photo-uploader/PreviewImage/PreviewImage'

import styles from './LoadedImages.module.css'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  editMode?: boolean
  removeImage: (imageId: string) => void
  screenshots?: Screen[]
  updateImage: (files: File[], imageId: string) => void
}

export const LoadedImages = ({
  className,
  editMode,
  removeImage,
  screenshots,
  updateImage,
  ...rest
}: IProps) => {
  const [srcPreview, setSrcPreview] = useState<string>('')
  const [mouseEnter, setMouseEnter] = useState<string>('')

  const { getValues } = useForm<FormInput>()

  const saveSrcInBuffer = (str: string) => {
    window.navigator.clipboard.writeText(str)
  }

  const imagesElements = screenshots?.map(el => {
    const copyUrl = (evt: MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation()
      saveSrcInBuffer(el.screen)
    }

    const removeImageHandler = (evt: MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation()
      removeImage(el.screenId)
    }

    // TODO: Update image (don't delete)
    const updateImageHandler = (evt: MouseEvent<HTMLDivElement>) => {
      const file = getValues('file')

      updateImage(file, el.screenId)
    }

    return (
      <div
        className={`${styles.image_wrapper} ${className}`}
        key={el.screenId}
        onClick={() => setSrcPreview(el.screen)}
        onMouseEnter={() => setMouseEnter(el.screenId)}
        onMouseLeave={() => setMouseEnter('')}
        {...rest}
      >
        {mouseEnter === el.screenId && editMode && (
          <>
            <Tooltip
              arrow
              describeChild
              placement={'bottom'}
              title={'Копировать ссылку на изображение'}
            >
              <div className={styles.copyButton} onClick={copyUrl}>
                <ContentCopyIcon fontSize={'small'} style={{ padding: '4px' }} />
              </div>
            </Tooltip>

            {/* TODO: Update image (don't delete)*/}
            {/*<Tooltip
                            placement="bottom"
                            arrow
                            describeChild
                            title="Обновить изображение"
                        >
                            <div
                                className={styles.updateButton}
                                onClick={updateImageHandler}
                            >
                                update
                            </div>
                        </Tooltip>*/}

            <Tooltip arrow describeChild placement={'bottom'} title={'Удалить изображение'}>
              <div className={styles.deleteButton} onClick={removeImageHandler}>
                <ClearIcon fontSize={'small'} style={{ padding: '4px' }} />
              </div>
            </Tooltip>
          </>
        )}
        <img alt={'For publication'} className={styles.image} src={el.screen} />
        {/* TODO: Update image (don't delete)*/}
        {/*<Input
                    {...register('file')}
                    accept="image/*"
                    id="file"
                    multiple
                    type="file"
                />*/}
      </div>
    )
  })

  return (
    <>
      <PreviewImage onClose={() => setSrcPreview('')} open={srcPreview !== ''} src={srcPreview} />
      <div className={styles.wrapper}>{imagesElements}</div>
    </>
  )
}

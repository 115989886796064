import { Button, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { selectIsDarkTheme, uiActions } from 'app'
import { useActions, useAppSelector } from 'common/hooks'
import { Admin } from 'common/types'
import { cvTableData } from 'features/cv/data'
import { CV, MentorStatus, MentorStatusEnum, useChangeCvStatusMutation } from 'features/cv/service'
import moment from 'moment/moment'
import { Course } from 'services/courses'
import { useSendStudentLinkInTelegramMutation } from 'services/students'

import s from './CvTableRow.module.css'

type Props = {
  admin: Admin
  courses?: Course[]
  cv: CV
}

export const CvTableRow = ({ admin, courses, cv }: Props) => {
  const { cvDate, mentor, mentorStatus, myCV, student, studentId } = cv

  const [sendStudentLinkInTelegram, { isLoading: isSendLoading }] =
    useSendStudentLinkInTelegramMutation()
  const [changeCvStatus, { isLoading: isChangeStatusLoading }] = useChangeCvStatusMutation()

  const isDarkTheme = useAppSelector(selectIsDarkTheme)
  const { showSnackbar } = useActions(uiActions)

  const sendLinkInTelegram = () => {
    sendStudentLinkInTelegram(student.baseId)
      .unwrap()
      .then(() => {
        showSnackbar({ message: 'Отправлено! проверьте телеграмм...', severity: 'success' })
      })
      .catch(e => {
        showSnackbar({ message: e.response?.status || 'some error', severity: 'error' })
      })
  }

  const onMentorStatusChange = (event: SelectChangeEvent) => {
    const newMentorStatus = event.target.value as MentorStatus

    changeCvStatus({ mentorStatus: newMentorStatus, studentId })
      .unwrap()
      .catch(e => {
        showSnackbar({ message: e.data.error || 'some error', severity: 'error' })
      })
  }

  const getStatusClassname = (status: MentorStatus) => {
    if (status === MentorStatusEnum.AWAIT) {
      return s.cellStatusWait
    }
    if (status === MentorStatusEnum.IN_WORK) {
      return s.cellStatusInWork
    }
    if (status === MentorStatusEnum.NOT_DONE) {
      return s.cellStatusWaiting
    }
    if (status === MentorStatusEnum.DONE) {
      return s.cellStatusAccept
    }
    if (status === MentorStatusEnum.REVIEW) {
      return s.cellStatusReWait
    }
  }

  const statusClassname = `${
    isDarkTheme ? s.cellStatusDark : s.cellStatusLight
  } ${getStatusClassname(mentorStatus || 'await')}`

  return (
    <div className={'commonRow'}>
      <div
        className={'commonCell'}
        style={{
          color: student.inactive ? 'red' : 'inherit',
          width: cvTableData[0].width,
        }}
      >
        <p className={'hiddenText'}>{`${student.firstName} ${student.lastName}`}</p>
      </div>

      <div className={'commonCell'} style={{ width: cvTableData[1].width }}>
        <p className={'hiddenText'}>
          {courses?.map(course => student.currentCourseId === course.id && course.title)}
        </p>
      </div>

      <div className={'commonCell'} style={{ width: cvTableData[2].width }}>
        <a
          className={'commonLinkText hiddenText'}
          href={myCV}
          rel={'noopener noreferrer'}
          target={'_blank'}
        >
          {myCV}
        </a>
      </div>

      <div className={'commonCell'} style={{ width: cvTableData[3].width }}>
        {moment(cvDate).format('DD/MM/YYYY')}
      </div>

      <div className={'commonCell'} style={{ padding: '12px', width: cvTableData[4].width }}>
        <Select
          className={statusClassname}
          disabled={isChangeStatusLoading}
          fullWidth
          onChange={onMentorStatusChange}
          size={'small'}
          value={mentorStatus}
        >
          <MenuItem value={MentorStatusEnum.AWAIT}>Ожидание</MenuItem>
          <MenuItem disabled={!!mentor} value={MentorStatusEnum.IN_WORK}>
            В работе
          </MenuItem>
          <MenuItem disabled={admin.name !== mentor} value={MentorStatusEnum.NOT_DONE}>
            Жду ответ
          </MenuItem>
          <MenuItem
            disabled={admin.name !== mentor || mentorStatus === MentorStatusEnum.NOT_DONE}
            value={MentorStatusEnum.DONE}
          >
            Принято
          </MenuItem>
          <MenuItem disabled value={MentorStatusEnum.REVIEW}>
            Перепроверка
          </MenuItem>
        </Select>
      </div>

      <div className={'commonCell'} style={{ width: cvTableData[5].width }}>
        <p className={'hiddenText'}>{mentor}</p>
      </div>

      <div className={'commonCell'} style={{ width: cvTableData[6].width }}>
        {student.country}
      </div>

      <div className={'commonCell'} style={{ width: cvTableData[7].width }}>
        <Button disabled={isSendLoading} onClick={sendLinkInTelegram}>
          Отправить ID
        </Button>
      </div>
    </div>
  )
}

import { ReactElement } from 'react'

import { MenuItem } from '@mui/material'

export const MAX_LEVEL = 50
export const MAX_LESSON = 50

export const renderMenuItems = (countOfItems: number, label: string): ReactElement[] => {
  return new Array(countOfItems + 1).fill(0).map((_, index) => {
    return (
      <MenuItem key={index} value={index}>
        {`${index} ${label}`}
      </MenuItem>
    )
  })
}

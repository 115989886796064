import { ChangeEvent, useEffect, useState } from 'react'

import { Slider, Switch } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { CustomPagination } from 'common/components/custom-pagination/CustomPagination'
import { instance } from 'services/instances/default'
import { PERS_AREA_ADMIN_TOKEN, restoreState } from 'utils'

import s from './HomeworkChecked.module.css'

import { HomeworkUser } from './HomeworkUser'
import { CurrentSettingHomework } from './common/currentSettingHomework'

export const HomeworkChecked = () => {
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(10)
  const [totalCount, setTotalCount] = useState(1)
  const [users, setUsers] = useState<User[]>([])
  const [checkedU, checkUser] = useState('')
  const [lastName, setLastName] = useState('') // find user in useEffect
  const [done, setDone] = useState(false) // is all users or not checked hw
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(20)
  const [t, setT] = useState(0) // id timeout for debounce find
  const [f, setF] = useState(lastName) // show find
  const [t2, setT2] = useState(0) // id timeout for debounce min
  const [mi, setMi] = useState(0)

  const onChangeLN = (e: ChangeEvent<HTMLInputElement>) => {
    setF(e.target.value)
    t && clearTimeout(t)
    setT(
      +setTimeout(() => {
        setLastName(e.target.value)
        setT(0)
      }, 1500)
    )
  }
  const onChangeMi = (n: number) => {
    setMi(n)
    t2 && clearTimeout(t2)
    setT2(
      +setTimeout(() => {
        setMin(n)
        setT2(0)
      }, 1500)
    )
  }

  const onChange = (event: any, page: number) => setPage(page)
  const onSelectChange = (event: SelectChangeEvent) => setPageCount(Number(event.target.value))
  const getU = () => {
    const token = restoreState(PERS_AREA_ADMIN_TOKEN, '')

    // setFetching(true)
    instance
      .get('auth/users', {
        params: {
          done: done || undefined,
          lastName,
          max,
          min,
          page,
          pageCount,
          token,
        },
      })
      .then(res => {
        setUsers(res.data.users)
        setTotalCount(res.data.usersTotalCount)
      })
  }

  useEffect(() => {
    getU()
  }, [page, pageCount, done, min, max, lastName])

  const updateUser = (id: string, newHomeworks: UserNewHomework[]) => {
    setUsers(
      users.map(u => {
        if (u._id === id) {
          return {
            ...u,
            newHomeworks,
          }
        } else {
          return u
        }
      })
    )
  }

  const mappedUsers = users.map(u => (
    <HomeworkUser
      checkUser={checkUser}
      checked={checkedU === u._id}
      key={u._id}
      u={u}
      updateUser={updateUser}
    />
  ))

  return (
    <div>
      <div className={s.titlePage} onClick={getU}>
        Проверка заданий
      </div>

      <div className={s.blockSearch}>
        <CurrentSettingHomework className={s.currentSearch} title={'Показать проверенные'}>
          нет
          <Switch checked={done} onChange={e => setDone(e.target.checked)} />
          да
        </CurrentSettingHomework>
        <CurrentSettingHomework className={s.currentSearch} title={'Заданий выполнено'}>
          <div className={s.slider}>
            <input
              className={s.sliderInput}
              onChange={e => onChangeMi(+e.target.value)}
              type={'number'}
              value={mi}
            />
            <div className={s.doubleSlider}>
              <Slider max={20} onChange={(e, v) => onChangeMi(v as number)} value={mi} />
            </div>
            <input
              className={s.sliderInput}
              onChange={e => setMax(+e.target.value)}
              type={'number'}
              value={max}
            />
          </div>
        </CurrentSettingHomework>
        <CurrentSettingHomework className={s.currentSearch} title={'Поиск'}>
          <div className={s.findContainer}>
            <input
              className={s.find}
              onChange={onChangeLN}
              placeholder={'lastName(фамилия студента)'}
              value={f}
            />
          </div>
        </CurrentSettingHomework>
      </div>

      {/*<button*/}
      {/*    onClick={() => {*/}
      {/*        //////////////////////*/}
      {/*        instance.get('script', {*/}
      {/*            params: {*/}
      {/*                token: restoreState(PERS_AREA_ADMIN_TOKEN, ''),*/}
      {/*            },*/}
      {/*        })*/}
      {/*    }}*/}
      {/*>*/}
      {/*    run script*/}
      {/*</button>*/}

      {mappedUsers}

      <CustomPagination
        count={Math.ceil(totalCount / pageCount)}
        itemForPageCount={pageCount}
        itemName={'Студентов'}
        onChange={onChange}
        onSelectChange={onSelectChange}
        pageNumber={page}
      />
    </div>
  )
}

// Types
export type UserHomework = {
  accessLevel: number // номер дз
  checked: boolean // ментор проверил, сбрасывается в false когда done или новый userComment
  done: boolean
  homeworkId: string
  mentorComment: string
  updated: number // время когда done
  userComment: string
}

export type UserNewHomework = {
  accessLevel: number // номер дз
  blockId: string
  checkType?: string // 'manually' | 'jest' | 'cypress'
  checked: boolean // ментор проверил, сбрасывается в false когда done или новый userComment
  commonFailureMessages?: string[]
  courseId: number
  done: boolean
  homeworkId: string

  mentorComment: string
  productionUrl: string
  uniqId?: string
  updated: number // время когда done
  userComment: string
}

export type CourseData = {
  courseId: number
  courseTitle?: string
  homeworkLevel?: number
  homeworkPage?: string
  homeworkStatus?: string
  homeworkURL?: string
  homeworksDone?: boolean
  inactive?: boolean
  lessonLevel?: number
  level?: number
  maxHomeworks?: number
  minHomeworks?: number
}

export type User = {
  _id: string

  availableCoursesIds?: number[]

  avatar: string
  avatarFileId?: string
  avatarServiceId?: string
  baseId: number // 3,
  baseToken: string
  city?: string

  codewars?: string
  codewarsScores?: number // { sql: 0, javascript: 240 }
  codewarsScoresSql?: number // { sql: 0, javascript: 240 }

  country?: string
  courseData?: CourseData[]
  courseId: number // 1,
  courseTitle: string // 'Front-end developer',

  created: Date
  currentCourseId?: number
  cvDate?: number
  //     <option value={-3}>not 5</option>
  cvMentor?: string
  employed?: boolean
  firstName: string // 'Игнат',
  freecodecamp?: string
  freecodecampScores?: number // { javascript: 140, htmlCss: 0 }
  freecodecampScoresHtmlCss?: number // { javascript: 140, htmlCss: 0 }
  github?: string
  githubScores?: number
  groupsIds?: number[]

  homeworkLevel?: number
  homeworkPage?: string
  homeworkStatus?: string
  homeworkURL?: string
  homeworks?: UserHomework[]
  homeworksDone?: boolean
  inactive: boolean // false,
  isAdmin: boolean
  lastName: string // 'Закалинский',
  lastUpdateDate: string // '2020-12-30T10:09:01.0488913Z',
  lessonLevel?: number
  level: number // 0,

  maxHomeworks?: number
  mentorStatus?: number
  minHomeworks?: number
  newHomeworks?: UserNewHomework[]
  passedExamsCount?: number
  portfolioURL?: string
  reviews?: Review[]
  statusCV?: number
  telegramId: number // 7xxxx2,
  tokens: string[]
  updated: Date

  viewed?: string[]
  viewedVideo?: { pl_id: string; v_id: string }[]
}

type Review = {
  _id: string
  date: number
  mentorId: number
  name: string
  practice: number
  role: string
  sessionId?: string
  text: string
  theory: number
}

export const formatDateTimeForChatTableRow = (dateTimeString: string): string => {
  const date = new Date(dateTimeString)

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDay = day < 10 ? `0${day}` : day
  const formattedMonth = month < 10 ? `0${month}` : month

  const formattedDate = `${formattedDay}.${formattedMonth}.${year}`

  const hours = date.getHours()
  let minutes: number | string = date.getMinutes()

  minutes = minutes < 10 ? `0${minutes}` : minutes

  const formattedTime = `${hours}:${minutes}`

  return `${formattedDate} - ${formattedTime}`
}

import { Option } from 'common/types'
import { Course } from 'services/courses'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export const getCoursesSelectOptions = (
  courses: Course[],
  coursesSelectOptions: Option[]
): Option[] => {
  courses?.forEach(course => {
    const courseTitle = capitalizeFirstLetter(course.title) as string

    coursesSelectOptions.push({ label: courseTitle, value: course.id })
  })

  return coursesSelectOptions
}

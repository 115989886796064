import { ChangeEvent, useEffect, useState } from 'react'

import { Pagination, Select, Table, TextField } from '@it-incubator/ui-kit'
import { LoadingOverlay } from 'common/components/loading-overlay/LoadingOverlay'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { Option } from 'common/types'
import { useGetFaqsQuery } from 'features/faq/service'
import { FaqQueries } from 'features/faq/service/types'
import { useGetCoursesQuery } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, getCoursesSelectOptions, restoreState, saveState } from 'utils'

import s from './Faq.module.css'

import { FaqPageHeader } from './faq-page-header/FaqPageHeader'
import { FaqTableRow } from './faq-table-row/FaqTableRow'

export const Faq = () => {
  const [search, setSearch] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [courseId, setCourseId] = useState('')

  const debouncedSearch = useDebounce(search, 1500)

  const faqQueries: FaqQueries = {
    filters: {
      courseId,
      searchTerm: search,
    },
    sorting: {},
  }

  const {
    data,
    isFetching,
    isLoading: isFaqLoading,
  } = useGetFaqsQuery({
    courseId,
    pageNumber,
    pageSize,
    searchTerm: debouncedSearch,
  })

  const { data: coursesData, isLoading: isCoursesLoading } = useGetCoursesQuery()

  const isLoading = isFaqLoading || isCoursesLoading

  const courses = coursesData?.items ?? []

  const startCoursesSelectOptions: Option[] = [{ label: 'Все', value: '' }]
  const coursesSelectOptions = getCoursesSelectOptions(courses, startCoursesSelectOptions)

  const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.currentTarget.value

    setSearch(searchTerm)
    setPageNumber(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.FAQ, {
      ...faqQueries,
      filters: { ...faqQueries.filters, searchTerm },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPageNumber(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.FAQ, {
      ...faqQueries,
      filters: { ...faqQueries.filters, searchTerm: '' },
    })
  }

  const handleCourseIdChanged = (value: string) => {
    setCourseId(value)
    setPageNumber(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.FAQ, {
      ...faqQueries,
      filters: { ...faqQueries.filters, courseId: value },
    })
  }

  const perPageChangeHandler = (itemPerPage: number) => {
    setPageSize(itemPerPage)
    setPageNumber(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredFaqQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.FAQ, faqQueries)
    const { courseId, searchTerm } = restoredFaqQueries.filters

    if (searchTerm) {
      setSearch(searchTerm)
    }
    if (courseId) {
      setCourseId(courseId + '')
    }
  }, [])

  return (
    <div>
      <FaqPageHeader courses={courses} />

      <TextField
        className={s.search}
        onChange={handleSearchChanged}
        onClearClick={onClearClick}
        placeholder={'Поиск...'}
        search
        value={search}
      />

      <Select
        label={'Курс'}
        onChange={handleCourseIdChanged}
        options={coursesSelectOptions}
        value={courseId}
        width={300}
      />

      <Table.Root className={s.tableRoot}>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align={'left'}>Название</Table.HeadCell>
            <Table.HeadCell align={'left'}>Обновлено</Table.HeadCell>
            <Table.HeadCell align={'left'}>Курс</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {data?.faq.map(faq => <FaqTableRow courses={courses} faq={faq} key={faq.id} />)}
        </Table.Body>
      </Table.Root>

      <LoadingOverlay open={isLoading || isFetching} />

      <Pagination
        count={Math.ceil((data?.totalCount || 0) / pageSize)}
        onChange={setPageNumber}
        onPerPageChange={perPageChangeHandler}
        page={pageNumber}
        perPage={pageSize}
        perPageOptions={[20, 50, 100]}
      />
    </div>
  )
}

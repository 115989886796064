import { Dispatch } from 'react'

import { Playlist } from 'features/playlists/service'
import { Setting } from 'features/videos/service'

import s from './PlaylistCell.module.css'

type Props = {
  playlists?: Playlist[]
  setShowShort: Dispatch<boolean>
  settings: Setting[]
  showShort: boolean
  showSwitchButton: boolean
}

export const PlaylistCell = ({
  playlists,
  setShowShort,
  settings,
  showShort,
  showSwitchButton,
}: Props) => {
  const onChangeShow = () => setShowShort(!showShort)

  return (
    <>
      {settings.map(setting => {
        return playlists?.map(playlist => {
          return (
            playlist.id === setting.playlistId && (
              <span className={s.mainContent} key={setting.id}>
                {playlist.name}
              </span>
            )
          )
        })
      })}

      {showSwitchButton && (
        <div className={s.showOrHideButton} onClick={onChangeShow}>
          {showShort ? 'Показать ещё' : 'Скрыть'}
        </div>
      )}
    </>
  )
}

import { Dispatch } from 'react'

import { Playlist } from 'features/playlists/service'
import { VideoSettingsState } from 'features/videos/service'
import { Course } from 'services/courses'

import { AvailableForCourses } from './avaliable-for-courses/AvailableForCourses'
import { PlaylistsSelect } from './playlists-select/PlaylistsSelect'
import { SelectedPlaylistsTabs } from './selected-playlists-tabs/SelectedPlaylistsTabs'

type Props = {
  courses?: Course[]
  playlists?: Playlist[]
  selectedPlaylistsIds: string[]
  setSelectedPlaylistsIds: Dispatch<string[]>
  setTabValue: Dispatch<boolean | string>
  setVideoSettingsState: Dispatch<VideoSettingsState>
  tabValue: boolean | string
  videoSettingsState: VideoSettingsState
}

export const VideoSettings = ({
  courses,
  playlists,
  selectedPlaylistsIds,
  setSelectedPlaylistsIds,
  setTabValue,
  setVideoSettingsState,
  tabValue,
  videoSettingsState,
}: Props) => {
  return (
    <>
      <PlaylistsSelect
        playlists={playlists}
        selectedPlaylistsIds={selectedPlaylistsIds}
        setSelectedPlaylistsIds={setSelectedPlaylistsIds}
        setTabValue={setTabValue}
        setVideoSettingsState={setVideoSettingsState}
        videoSettingsState={videoSettingsState}
      />

      {selectedPlaylistsIds.length !== 0 && (
        <SelectedPlaylistsTabs
          playlists={playlists}
          selectedPlaylistsIds={selectedPlaylistsIds}
          setTabValue={setTabValue}
          tabValue={tabValue}
        />
      )}

      {selectedPlaylistsIds.map(playlistId => (
        <AvailableForCourses
          courses={courses}
          key={playlistId}
          playlistId={playlistId}
          selectedPlaylistsIds={selectedPlaylistsIds}
          setVideoSettingsState={setVideoSettingsState}
          tabValue={tabValue}
          videoSettingsState={videoSettingsState}
        />
      ))}
    </>
  )
}

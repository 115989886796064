import { Checkbox, Tooltip } from '@mui/material'

import s from './StudentsPhotosTableHeader.module.css'

type Props = {
  isAtLeastOneStudentSelected: boolean
  onSelectAll: (selected: boolean) => void
}
export const StudentsPhotosTableHeader = ({ isAtLeastOneStudentSelected, onSelectAll }: Props) => {
  const tooltipTitle = isAtLeastOneStudentSelected ? 'Снять все выделения' : 'Выделить всё'
  const handleSelectAllChecked = () => {
    onSelectAll(!isAtLeastOneStudentSelected)
  }

  return (
    <div className={s.tableHeaderBox}>
      <div className={s.studentBox}>
        <Tooltip title={tooltipTitle}>
          <Checkbox
            checked={false}
            indeterminate={isAtLeastOneStudentSelected}
            onChange={handleSelectAllChecked}
            value={isAtLeastOneStudentSelected}
          />
        </Tooltip>
        <span>Студент</span>
      </div>
      <span>Дата обновления аватара</span>
      <span></span>
    </div>
  )
}

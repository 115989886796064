import { Dispatch, useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { SuperCheckbox } from 'common/components/custom-checkbox/SuperCheckbox'
import { Setting, VideoSettingsState } from 'features/videos/service'
import moment from 'moment/moment'
import { Course } from 'services/courses'
import { capitalizeFirstLetter } from 'utils'

import { Settings } from './settings/Settings'

type Props = {
  courses?: Course[]
  playlistId: string
  selectedPlaylistsIds: string[]
  setVideoSettingsState: Dispatch<VideoSettingsState>
  tabValue: boolean | string
  videoSettingsState: VideoSettingsState
}

export const AvailableForCourses = ({
  courses,
  playlistId,
  selectedPlaylistsIds,
  setVideoSettingsState,
  tabValue,
  videoSettingsState,
}: Props) => {
  const [selectedCourses, setSelectedCourses] = useState<Setting[]>(
    videoSettingsState[playlistId] || []
  )

  const defStart = moment().unix() * 1000 - 1000 * 60 * 60 * 24 * 366 * 2 // - 2 years
  const defEnd = moment().unix() * 1000 + 1000 * 60 * 60 * 24 * 366 * 2 // + 2 years

  const handleCoursesSelected = (courseId: number) => {
    if (selectedCourses.some(selectedCourse => selectedCourse.courseId === courseId)) {
      setSelectedCourses(
        selectedCourses.filter(selectedCourse => selectedCourse.courseId !== courseId)
      )

      setVideoSettingsState({
        ...videoSettingsState,
        [playlistId]: videoSettingsState[playlistId].filter(
          setting => setting.courseId !== courseId
        ),
      })

      return
    }

    const newSetting = {
      accessLevel: 0,
      courseId: courseId,
      forLesson: 0,
      maxStudentStartCourseDate: defEnd,
      minStudentStartCourseDate: defStart,
      playlistId: playlistId,
    }

    setSelectedCourses([...selectedCourses, newSetting])
    setVideoSettingsState({
      ...videoSettingsState,
      [playlistId]: [...videoSettingsState[playlistId], newSetting],
    })
  }

  const changeAccessLevel = (event: SelectChangeEvent<number>, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, accessLevel: +event.target.value }
          : { ...selectedCourse }
      )
    )
  }

  const changeForLesson = (event: SelectChangeEvent<number>, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, forLesson: +event.target.value }
          : { ...selectedCourse }
      )
    )
  }

  const changeStartDate = (event: null | number, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, minStudentStartCourseDate: moment(event).unix() * 1000 }
          : { ...selectedCourse }
      )
    )
  }

  const changeEndDate = (event: null | number, courseId: number) => {
    setSelectedCourses(
      selectedCourses.map(selectedCourse =>
        selectedCourse.courseId === courseId
          ? { ...selectedCourse, maxStudentStartCourseDate: moment(event).unix() * 1000 }
          : { ...selectedCourse }
      )
    )
  }

  useEffect(() => {
    if (selectedCourses.length !== 0) {
      setVideoSettingsState({ ...videoSettingsState, [playlistId]: [...selectedCourses] })
    }
  }, [selectedPlaylistsIds, selectedCourses])

  return (
    <div hidden={tabValue !== playlistId}>
      {tabValue === playlistId && (
        <>
          <h4>Доступно для курсов:</h4>

          <Grid marginBottom={2}>
            {courses?.map(course => {
              const selected = selectedCourses.some(
                selectedCourse => selectedCourse.courseId === course.id
              )
              const currentCourse = selectedCourses.find(
                selectedCourse => selectedCourse.courseId === course.id
              ) as Setting

              return (
                <div key={course.title + course.id}>
                  <SuperCheckbox
                    checked={selected}
                    onChange={() => handleCoursesSelected(course.id)}
                    required={selectedCourses.length === 0}
                  >
                    {capitalizeFirstLetter(course.title)}
                  </SuperCheckbox>

                  {selected && (
                    <Settings
                      accessLevel={currentCourse.accessLevel}
                      changeAccessLevel={e => changeAccessLevel(e, course.id)}
                      changeEndDate={e => changeEndDate(e, course.id)}
                      changeForLesson={e => changeForLesson(e, course.id)}
                      changeStartDate={e => changeStartDate(e, course.id)}
                      endDate={currentCourse.maxStudentStartCourseDate}
                      forLesson={currentCourse.forLesson}
                      startDate={currentCourse.minStudentStartCourseDate}
                    />
                  )}
                </div>
              )
            })}
          </Grid>
        </>
      )}
    </div>
  )
}

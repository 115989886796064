import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Table, Tooltip, Typography } from '@it-incubator/ui-kit'
import { selectAdmin } from 'app'
import { PATH } from 'common/components/routing/Routing'
import { Student, usePostGroupMessagesMutation } from 'features/chats/service'
import { formatDateTimeForChatTableRow, isUnread } from 'features/chats/utils'

import s from './ChatTableRow.module.css'

type Props = {
  student: Student
}

export const ChatTableRow = ({ student }: Props) => {
  const navigate = useNavigate()

  const [postGroupMessages, { isLoading: isPostGroupMessagesLoading }] =
    usePostGroupMessagesMutation()

  const formattedDate = student.last_message?.time
    ? formatDateTimeForChatTableRow(student.last_message.time)
    : '-'

  const admin = useSelector(selectAdmin)

  const {
    first_name,
    group_id,
    last_message,
    last_name,
    statuses,
    student_id,
    student_study_groups,
  } = student

  const managers = student.managers ? [...student.managers] : []

  const index = managers.findIndex(manager => parseInt(manager.id.slice(2)) === admin.id)

  if (index !== -1 && index !== 0) {
    const removedName = managers.splice(index, 1)[0]

    managers.unshift(removedName)
  }

  const writeButtonHandler = () => {
    if (student.group_id) {
      navigate(`${PATH.CHATS}/${student.group_id}`)
    } else {
      postGroupMessages({
        first_name,
        group_id,
        last_name,
        student_study_groups,
        userId: student_id,
      })
        .unwrap()
        .then(res => navigate(`${PATH.CHATS}/${res.group.id}`))
        .catch(() => toast.error('Возникла ошибка при создании чата'))
    }
  }

  const group = {
    id: group_id || 0,
    last_message: {
      id: last_message ? last_message.id : 0,
    },
  }

  const unread = isUnread(statuses, group, `a_${admin.id}`)

  return (
    <Table.Row>
      <Table.Cell align={'right'} className={s.lensTableCell}>
        <div className={unread ? s.lensActive : s.lens} />
        <div className={s.lens} />
      </Table.Cell>

      <Table.Cell
        className={s.lensTableCell}
      >{`${student.first_name} ${student.last_name}`}</Table.Cell>

      <Table.Cell className={s.tableCell}>
        {student.student_study_groups ? student.student_study_groups.join(', ') : '-'}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={200}>
        {formattedDate}
      </Table.Cell>

      <Table.Cell className={s.tableCell}>
        {managers.length ? `${managers[0].first_name} ${managers[0].last_name}` : '-'}
        {managers.length > 1 && (
          <Tooltip
            component={<span className={s.tooltipComponent}>еще {managers.length - 1} </span>}
          >
            {managers.filter((_, i) => i !== 0).join(', ')}
          </Tooltip>
        )}
      </Table.Cell>

      <Table.Cell className={s.tableCell} width={120}>
        <button
          className={s.button}
          disabled={isPostGroupMessagesLoading}
          onClick={writeButtonHandler}
        >
          <Typography.SubtitleLink>Написать</Typography.SubtitleLink>
        </button>
      </Table.Cell>
    </Table.Row>
  )
}

import { CommonColumn } from 'common/components/custom-table/CustomTable'

export const moodTableData: CommonColumn[] = [
  { id: 1, isSort: true, name: 'Студент', title: 'lastName', width: '16%' },
  {
    id: 2,
    isSort: true,
    name: 'Курс',
    title: 'courseId',
    width: '12%',
  },
  {
    id: 3,
    isSort: true,
    name: 'Дата',
    title: 'created',
    width: '12%',
  },

  {
    id: 4,
    isSort: true,
    name: 'Уровень',
    title: 'level',
    width: '8%',
  },
  {
    id: 5,
    isSort: false,
    name: 'Время обучения',
    title: 'studyingTime',
    width: '13%',
  },
  { id: 6, isSort: true, name: 'Настроение', title: 'mood', width: '10%' },
  { id: 7, isSort: true, name: 'Время', title: 'learningTime', width: '10%' },
  {
    id: 8,
    isSort: true,
    name: 'Программа',
    title: 'programComplexity',
    width: '11%',
  },
  {
    id: 9,
    isSort: false,
    name: '',
    title: 'deleteButton',
    width: '8%',
  },
]

export enum MoodScores {
  BAD = 2,
  GOOD = 0,
  SATISFACTORY = 1,
  TERRIBLE = 3,
}

export const transformDate = (str: string) => {
  const arr = str.split(' ')

  return arr
    .map(element => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
    }) //capitalize first letter of each word
    .join(' ')
    .slice(0, -2) //remove last two symbols (г.)
}

import { Control, Controller } from 'react-hook-form'

import { Video } from 'features/videos/service'

import s from './VideoUrlController.module.css'

type Props = {
  control: Control<Video>
  name: 'hostingHash' | 'hostingId'
}

export const VideoUrlController = ({ control, name }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue={''}
      name={name}
      render={({ field, fieldState }) => {
        return fieldState.error ? (
          <div className={s.error}>{fieldState.error.message}</div>
        ) : (
          <div className={field.value ? s.text : s.hide}>
            {name === 'hostingId' ? `ID: ` : `Hash: `} {field.value}
          </div>
        )
      }}
    />
  )
}

import { useState } from 'react'

import { Button, Card, DatePicker, Select } from '@it-incubator/ui-kit'
import { LoadingOverlay } from 'common/components/loading-overlay/LoadingOverlay'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { Option } from 'common/types'
import { downloadFile } from 'features/statistics/service'
import { useGetCoursesQuery } from 'services/courses'
import { getCoursesSelectOptions } from 'utils'

import s from './Statistics.module.css'

export const Statistics = () => {
  const [courseId, setCourseId] = useState<number | string>('')
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)
  const [downloadInProcess, setDownloadInProcess] = useState(false)

  const { data: coursesData } = useGetCoursesQuery()

  const courses = coursesData?.items ?? []

  const startCoursesSelectOptions: Option[] = [{ label: 'Все', value: '' }]
  const coursesSelectOptions = getCoursesSelectOptions(courses, startCoursesSelectOptions)

  const handleFromDateChange = (e: Date | null) => {
    if (e) {
      setFromDate(e)
    } else {
      setFromDate(new Date())
    }
  }

  const handleToDateChange = (e: Date | null) => {
    if (e) {
      setToDate(e)
    } else {
      setToDate(new Date())
    }
  }

  const handleDownloadOld = () => {
    setDownloadInProcess(true)

    downloadFile({ courseId }, 'old').finally(() => setDownloadInProcess(false))
  }

  const handleDownloadNew = () => {
    setDownloadInProcess(true)

    downloadFile({ courseId, fromDate, toDate }, 'new').finally(() => setDownloadInProcess(false))
  }

  return (
    <div className={s.pageContainer}>
      <PageHeader className={s.header} title={'Статистика'} />

      <Card contentClassName={s.content} title={'Статистика по анкете студента'}>
        <Select
          label={'Курс'}
          onChange={setCourseId}
          options={coursesSelectOptions}
          value={courseId + ''}
          width={250}
        />

        <DatePicker
          className={s.datePicker}
          label={'Дата от'}
          placeholder={'Выберите дату'}
          setStartDate={handleFromDateChange}
          startDate={fromDate}
        />

        <DatePicker
          className={s.datePicker}
          label={'Дата до'}
          placeholder={'Выберите дату'}
          setStartDate={handleToDateChange}
          startDate={toDate}
        />

        <Button disabled={downloadInProcess} onClick={handleDownloadOld} variant={'secondary'}>
          Скачать старую
        </Button>

        <Button disabled={!fromDate || !toDate || downloadInProcess} onClick={handleDownloadNew}>
          Скачать новую
        </Button>
      </Card>

      <LoadingOverlay open={downloadInProcess} />
    </div>
  )
}

import { useEffect, useState } from 'react'

import { Spinner } from '@it-incubator/ui-kit'
import { Backdrop, CircularProgress } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { CustomPagination } from 'common/components/custom-pagination/CustomPagination'
import { CustomTable } from 'common/components/custom-table/CustomTable'
import { PageHeader } from 'common/components/page-header/PageHeader'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useDebounce } from 'common/hooks'
import { devClubTableData } from 'features/dev-club/data'
import { StatusType, UserElement, useGetDevClubMembersQuery } from 'features/dev-club/service'
import { LOCAL_STORAGE_QUERIES_KEYS, getInflectedWord, restoreState } from 'utils'

import s from './DevClub.module.css'

import { DevClubFilters } from './dev-club-filters/DevClubFilters'
import { DevClubTableRow } from './dev-club-table-row/DevClubTableRow'

export const DevClub = () => {
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(50)
  const [status, setStatus] = useState<StatusType>('pending')
  const [lastName, setLastName] = useState('')

  const debouncedLastName = useDebounce(lastName, 1000)

  const { data, isFetching, isLoading } = useGetDevClubMembersQuery({
    lastName: debouncedLastName,
    page,
    pageCount,
    status,
  })

  const devClubQueries = {
    filters: {
      lastName,
      status,
    },
  }

  const countOfStudents = `${data?.totalCount} ${getInflectedWord(data?.totalCount as number, [
    'студент',
    'студента',
    'студентов',
  ])}`

  const onChange = (event: any, page: number) => {
    setPage(page)
  }
  const onSelectChange = (event: SelectChangeEvent<number>) => {
    setPageCount(event.target.value as number)
    setPage(FIRST_PAGE_NUMBER)
  }

  useEffect(() => {
    const restoredDcQueries = restoreState(LOCAL_STORAGE_QUERIES_KEYS.DEV_CLUB, devClubQueries)
    const { lastName, status } = restoredDcQueries.filters

    setLastName(lastName)
    setStatus(status)
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <PageHeader title={'Dev Club'} />

      <DevClubFilters
        devClubQueries={devClubQueries}
        lastName={lastName}
        setLastName={setLastName}
        setPage={setPage}
        setStatus={setStatus}
        status={status}
      />

      <div className={s.countOfStudents}>По вашим критериям найдено {countOfStudents}.</div>

      <div className={s.containerTable}>
        <CustomTable
          onChange={() => {}}
          sortColumn={''}
          sortDirection={''}
          tableData={devClubTableData}
        >
          {data?.users.map((userItem: UserElement) => {
            return (
              <DevClubTableRow
                key={userItem.user.id}
                page={page}
                pageCount={pageCount}
                status={status}
                userItem={userItem}
              />
            )
          })}
        </CustomTable>
      </div>

      <Backdrop open={isFetching} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
        <CircularProgress color={'inherit'} />
      </Backdrop>

      <CustomPagination
        count={Math.ceil((data?.totalCount ?? 1) / pageCount)}
        itemForPageCount={pageCount}
        itemName={''}
        onChange={onChange}
        onSelectChange={onSelectChange}
        pageNumber={page}
      />
    </div>
  )
}

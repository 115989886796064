import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { Main } from 'Main'
import { store } from 'app'

import './App.css'

export const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Main />
      </Provider>
    </BrowserRouter>
  )
}

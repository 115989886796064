import { useEffect } from 'react'

import { Message } from 'features/chats/service'

export const useScrollToMessageBubble = (messages: Message[], scrollTrigger: boolean) => {
  useEffect(() => {
    const messageBubbleEl = document.getElementById(`${messages.length}`)

    if (messageBubbleEl && !scrollTrigger) {
      messageBubbleEl.scrollIntoView({ block: 'start' })
    }
  }, [messages])
}

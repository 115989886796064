import { ChangeEvent, useState } from 'react'

import { uiActions } from 'app'
import { useActions } from 'common/hooks'
import { usePutStudentWorksMutation } from 'features/dev-club/service'
import { validateLinkedInUrl } from 'utils'

export const useLinkedIn = (studentId: number, defaultLinkedIn: string) => {
  const [linkedIn, setLinkedIn] = useState(defaultLinkedIn)
  const [editMode, setEditMode] = useState(false)
  const [error, setError] = useState(false)

  const [putStudentWorks, { isLoading }] = usePutStudentWorksMutation()

  const { showSnackbar } = useActions(uiActions)

  const handleSubmit = () => {
    if (linkedIn === defaultLinkedIn) {
      setEditMode(false)

      return
    }

    putStudentWorks({
      linkedIn,
      studentId,
    })
      .unwrap()
      .then(() => {
        showSnackbar({
          message: 'Изменения успешно сохранены',
          severity: 'success',
        })
      })
      .catch(err => {
        setLinkedIn(defaultLinkedIn)
        showSnackbar({
          message: err.data.error,
          severity: 'error',
        })
      })
      .finally(() => setEditMode(false))
  }

  const linkedInChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newLinkedInUrl = event.currentTarget.value
    const isUrlValid = validateLinkedInUrl(newLinkedInUrl)

    setLinkedIn(newLinkedInUrl)
    setError(!isUrlValid)

    if (newLinkedInUrl === '') {
      setError(false)
    }
  }

  const cancelButtonHandler = () => {
    setEditMode(false)
    setLinkedIn(defaultLinkedIn)
  }

  return {
    cancelButtonHandler,
    editMode,
    error,
    handleSubmit,
    isLoading,
    linkedIn,
    linkedInChangeHandler,
    setEditMode,
  }
}

import { Control, Controller } from 'react-hook-form'

import { TextField } from '@mui/material'
import { Video } from 'features/videos/service'

type Props = {
  control: Control<Video>
}

export const NameController = ({ control }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue={''}
      name={'name'}
      render={({ field, fieldState }) => (
        <TextField
          error={!!fieldState.error}
          fullWidth
          helperText={fieldState.error && 'Максимальная длина 250 символов'}
          label={'Название видео'}
          required
          style={{ marginTop: '10px' }}
          {...field}
        />
      )}
      rules={{ maxLength: 250 }}
    />
  )
}

import { Navigate, Route, Routes } from 'react-router-dom'

import AuthRedirectPage from 'common/components/auth-redirect-page/AuthRedirectPage'
import { NotFoundPage } from 'common/components/not-found-page/NotFoundPage'
import { Chats } from 'features/chats/ui/Chats'
import { ChatWithStudent } from 'features/chats/ui/chat-with-student/ChatWithStudent'
import { CodeReview } from 'features/code-review/ui/CodeReview'
import { Comments } from 'features/comments/ui/Comments'
import { CourseReviews } from 'features/course-reviews/ui/CourseReviews'
import { Cv } from 'features/cv/ui/Cv'
import { DevClub } from 'features/dev-club/ui/DevClub'
import { Faq } from 'features/faq/ui/Faq'
import { HomeworkChecked } from 'features/homework-checked/ui/HomeworkChecked'
import { LessonReviews } from 'features/lesson-reviews/ui'
import { MoodsPage } from 'features/mood/ui/MoodsPage'
import { Playlists } from 'features/playlists/ui/Playlists'
import { Statistics } from 'features/statistics/ui/Statistics'
import { StudentsPhotosPage } from 'features/students-photos/ui/StudentsPhotosPage'
import { Videos } from 'features/videos/ui/Videos'
import { WatchVideo } from 'features/videos/ui/watch-video/WatchVideo'

export const PATH = {
  CHATS: '/chats',
  CODE_REVIEW: '/code-review',
  COMMENTS: '/comments',
  CV: '/cv',
  DEV_CLUB: '/dev_club',
  FAQ: '/faq',
  HOMEWORKS_CHECKED: '/homeworks_checked',
  LESSON_REVIEWS: '/lesson-reviews',
  MOOD: '/mood',
  PLAYLISTS: '/playlists',
  REVIEWS: '/reviews',
  STATISTICS: '/statistics',
  STUDENTS_PHOTOS: '/students-photos',
  VIDEOS: '/videos',
  WATCH: '/watch-video',
}

export const Routing = () => {
  return (
    <Routes>
      <Route element={<Navigate to={PATH.PLAYLISTS} />} path={'/'} />

      <Route
        element={
          <AuthRedirectPage>
            <HomeworkChecked />
          </AuthRedirectPage>
        }
        path={PATH.HOMEWORKS_CHECKED}
      />

      <Route
        element={
          <AuthRedirectPage>
            <Playlists />
          </AuthRedirectPage>
        }
        path={PATH.PLAYLISTS}
      />

      <Route path={PATH.VIDEOS}>
        <Route
          element={
            <AuthRedirectPage>
              <Videos />
            </AuthRedirectPage>
          }
          path={PATH.VIDEOS}
        />

        <Route
          element={
            <AuthRedirectPage>
              <WatchVideo />
            </AuthRedirectPage>
          }
          path={`${PATH.VIDEOS}${PATH.WATCH}/:videoId`}
        />
      </Route>

      <Route
        element={
          <AuthRedirectPage>
            <Cv />
          </AuthRedirectPage>
        }
        path={PATH.CV}
      />

      <Route
        element={
          <AuthRedirectPage>
            <DevClub />
          </AuthRedirectPage>
        }
        path={PATH.DEV_CLUB}
      />

      <Route
        element={
          <AuthRedirectPage>
            <Statistics />
          </AuthRedirectPage>
        }
        path={PATH.STATISTICS}
      />

      <Route
        element={
          <AuthRedirectPage>
            <Comments />
          </AuthRedirectPage>
        }
        path={PATH.COMMENTS}
      />

      <Route
        element={
          <AuthRedirectPage>
            <MoodsPage />
          </AuthRedirectPage>
        }
        path={PATH.MOOD}
      />
      <Route
        element={
          <AuthRedirectPage>
            <LessonReviews />
          </AuthRedirectPage>
        }
        path={PATH.LESSON_REVIEWS}
      />

      <Route
        element={
          <AuthRedirectPage>
            <StudentsPhotosPage />
          </AuthRedirectPage>
        }
        path={PATH.STUDENTS_PHOTOS}
      />

      <Route
        element={
          <AuthRedirectPage>
            <Chats />
          </AuthRedirectPage>
        }
        path={PATH.CHATS}
      />

      <Route
        element={
          <AuthRedirectPage>
            <ChatWithStudent />
          </AuthRedirectPage>
        }
        path={`${PATH.CHATS}/:groupId`}
      />

      <Route
        element={
          <AuthRedirectPage>
            <CourseReviews />
          </AuthRedirectPage>
        }
        path={PATH.REVIEWS}
      />

      <Route
        element={
          <AuthRedirectPage>
            <CodeReview />
          </AuthRedirectPage>
        }
        path={PATH.CODE_REVIEW}
      />

      <Route
        element={
          <AuthRedirectPage>
            <Faq />
          </AuthRedirectPage>
        }
        path={PATH.FAQ}
      />

      <Route
        element={
          <AuthRedirectPage>
            <NotFoundPage />
          </AuthRedirectPage>
        }
        path={'*'}
      />
    </Routes>
  )
}

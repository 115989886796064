import {
  AnEasySmile,
  ConfusedFace,
  FaceWithSpiralEyes,
  Fire,
  HotFace,
  NerdFace,
  ThumbsUp,
  WinkingFace,
  YawningFace,
} from 'assets/icons/reactions-icons'
import { Reactions, ReactionsData } from 'features/videos/service'

export const reactionsData: ReactionsData[] = [
  {
    countName: 'superContentCount',
    icon: <ThumbsUp />,
    reaction: Reactions.SuperContent,
    title: 'Супер контент, спасибо',
    variant: 'primary',
  },
  {
    countName: 'improvedUnderstandingCount',
    icon: <NerdFace />,
    reaction: Reactions.ImprovedUnderstanding,
    title: 'Закрепил материал',
    variant: 'primary',
  },
  {
    countName: 'normalCount',
    icon: <WinkingFace />,
    reaction: Reactions.Normal,
    title: 'Норм',
    variant: 'primary',
  },
  {
    countName: 'nothingNewCount',
    icon: <ConfusedFace />,
    reaction: Reactions.NothingNew,
    title: 'Ничего нового не узнал',
    variant: 'primary',
  },
  {
    countName: 'allUnderstandingCount',
    icon: <AnEasySmile />,
    reaction: Reactions.AllUnderstanding,
    title: 'Всё понятно',
    variant: 'primary',
  },
  {
    countName: 'hardUnderstandingCount',
    icon: <FaceWithSpiralEyes />,
    reaction: Reactions.HardUnderstanding,
    title: 'Сложно для понимания',
    variant: 'primary',
  },
  {
    countName: 'firePresentationCount',
    icon: <Fire />,
    reaction: Reactions.FirePresentation,
    title: 'Подача материала огонь',
    variant: 'primary',
  },
  {
    countName: 'failedPresentationCount',
    icon: <YawningFace />,
    reaction: Reactions.FailedPresentation,
    title: 'Неудачная подача материала',
    variant: 'primary',
  },
  {
    countName: 'obsoleteCount',
    icon: <HotFace />,
    reaction: Reactions.Obsolete,
    title: 'Материал устарел',
    variant: 'secondary',
  },
]

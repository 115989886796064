import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { uiActions } from 'app'
import { FIRST_PAGE_NUMBER } from 'common/constants'
import { useActions } from 'common/hooks'
import { Option } from 'common/types'
import { Playlist } from 'features/playlists/service'
import { reactionsData } from 'features/videos/data'
import { Video, VideosQueries, useAddVideoMutation } from 'features/videos/service'
import { Course } from 'services/courses'
import { LOCAL_STORAGE_QUERIES_KEYS, capitalizeFirstLetter, saveState } from 'utils'

import s from '../../watch-video/reactions/Reactions.module.css'

export const useVideosHeader = (
  setPage: Dispatch<SetStateAction<number>>,
  setSearch: Dispatch<SetStateAction<string>>,
  setPlaylistId: Dispatch<SetStateAction<string>>,
  setCourseId: Dispatch<SetStateAction<number | string>>,
  setSortReaction: Dispatch<SetStateAction<string>>,
  videosQueries: VideosQueries,
  playlists: Playlist[],
  courses: Course[]
) => {
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [searchPlaylistTerm, setSearchPlaylistTerm] = useState('')

  const { showSnackbar } = useActions(uiActions)

  const onOpen = () => setShowAddVideoModal(true)
  const onClose = () => setShowAddVideoModal(false)

  const [addVideo, { isLoading }] = useAddVideoMutation()

  const addVideoCallback = (videoModel: Video) => {
    addVideo(videoModel)
      .unwrap()
      .catch(e => showSnackbar({ message: e.data.message, severity: 'error' }))
      .finally(onClose)
  }

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.currentTarget.value

    setSearch(searchValue)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {
      ...videosQueries,
      filters: { ...videosQueries.filters, search: searchValue },
    })
  }

  const onClearClick = () => {
    setSearch('')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {
      ...videosQueries,
      filters: { ...videosQueries.filters, search: '' },
    })
  }

  const onChangePlaylistId = (newPlaylistId: null | string) => {
    setPlaylistId(newPlaylistId || '')
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {
      ...videosQueries,
      filters: { ...videosQueries.filters, playlist: newPlaylistId },
    })
  }

  const onChangeCourseId = (newCourseId: string) => {
    setCourseId(newCourseId)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {
      ...videosQueries,
      filters: { ...videosQueries.filters, course: newCourseId },
    })
  }

  const onChangeSortReaction = (newSortReaction: string) => {
    setSortReaction(newSortReaction)
    setPage(FIRST_PAGE_NUMBER)

    saveState(LOCAL_STORAGE_QUERIES_KEYS.VIDEOS, {
      ...videosQueries,
      sorting: { ...videosQueries.sorting, sort: newSortReaction },
    })
  }

  const playlistsSelectOptions: { label: string; value: string }[] = []
  const coursesSelectOptions: Option[] = [{ label: 'Курс не выбран', value: '' }]
  const reactionsSelectOptions: Option[] = [{ label: 'Реакция не выбрана', value: '' }]

  playlists?.forEach(playlist =>
    playlistsSelectOptions.push({ label: playlist.name, value: playlist.id as string })
  )

  courses?.forEach(course => {
    const courseTitle = capitalizeFirstLetter(course.title) as string

    coursesSelectOptions.push({ label: courseTitle, value: course.id })
  })

  reactionsData.forEach(reaction =>
    reactionsSelectOptions.push({
      // @ts-ignore
      label: (
        <div className={s.labelContainer}>
          {reaction.title} {reaction.icon}
        </div>
      ),
      value: reaction.countName,
    })
  )

  return {
    addVideoCallback,
    coursesSelectOptions,
    isLoading,
    onChangeCourseId,
    onChangePlaylistId,
    onChangeSearch,
    onChangeSortReaction,
    onClearClick,
    onClose,
    onOpen,
    playlistsSelectOptions,
    reactionsSelectOptions,
    searchPlaylistTerm,
    setSearchPlaylistTerm,
    showAddVideoModal,
  }
}

import { ReactNode } from 'react'

import { CustomTableHeader } from './CustomTableHeader/CustomTableHeader'
import { CustomTableRows } from './CustomTableRows/CustomTableRows'
import { SortDirection } from './SortColumn/SortColumn'

export type CommonColumn = {
  id: number
  isSort: boolean
  name: string
  title: string
  width: string
}

type Props = {
  children?: ReactNode
  onChange: (sc: string, sd: SortDirection) => void
  sortColumn: string
  sortDirection: string
  tableData: CommonColumn[]
}

export const CustomTable = ({
  children,
  onChange,
  sortColumn,
  sortDirection,
  tableData,
}: Props) => {
  return (
    <div>
      <CustomTableHeader arr={tableData} />
      <CustomTableRows
        arr={tableData}
        onChange={onChange}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      {children}
    </div>
  )
}

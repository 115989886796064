import { CommonColumn } from '@/common/components/custom-table/CustomTable'

export const videosTableData: CommonColumn[] = [
  { id: 0, isSort: false, name: '', title: '', width: '4%' },
  { id: 1, isSort: false, name: 'Название', title: 'name', width: '14%' },
  {
    id: 2,
    isSort: false,
    name: 'Плейлист',
    title: 'playlistName',
    width: '17%',
  },
  {
    id: 3,
    isSort: false,
    name: 'Курс',
    title: 'courseId',
    width: '11%',
  },
  {
    id: 4,
    isSort: false,
    name: 'Старт группы до',
    title: 'maxStudentStartCourseDate',
    width: '14%',
  },
  {
    id: 5,
    isSort: false,
    name: 'Доступ',
    title: 'levelAccess',
    width: '7%',
  },
  {
    id: 6,
    isSort: false,
    name: 'Для урока',
    title: 'forLesson',
    width: '8%',
  },
  { id: 7, isSort: false, name: 'Преподаватель', title: 'mentor', width: '9%' },
  { id: 8, isSort: false, name: 'Дата', title: 'startDate', width: '9%' },
  { id: 9, isSort: false, name: '', title: '', width: '7%' },
]

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Button, IconButton, Skeleton, TextField } from '@mui/material'
import { useLinkedIn } from 'features/dev-club/hooks'

import s from './LinkedIn.module.css'

type Props = {
  linkedIn: string
  studentId: number
}

export const LinkedIn = ({ linkedIn: defaultLinkedIn, studentId }: Props) => {
  const {
    cancelButtonHandler,
    editMode,
    error,
    handleSubmit,
    isLoading,
    linkedIn,
    linkedInChangeHandler,
    setEditMode,
  } = useLinkedIn(studentId, defaultLinkedIn)

  if (isLoading) {
    return <Skeleton height={'60px'} />
  }

  return (
    <div className={s.container}>
      {editMode ? (
        <div className={s.editModeWrapper}>
          <TextField
            autoFocus
            error={error}
            fullWidth
            label={error ? 'Некорректная ссылка' : 'LinkedIn'}
            onChange={linkedInChangeHandler}
            value={linkedIn}
          />

          <Button disabled={error} onClick={handleSubmit}>
            Сохранить
          </Button>

          <Button color={'info'} onClick={cancelButtonHandler}>
            Отмена
          </Button>
        </div>
      ) : (
        <span>
          <b>LinkedIn:</b> {linkedIn ? linkedIn : 'не заполнено'}
        </span>
      )}

      {!editMode && (
        <IconButton onClick={() => setEditMode(true)}>
          <DriveFileRenameOutlineIcon />
        </IconButton>
      )}
    </div>
  )
}

export const getVimeoId = (url: string) => {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url)

  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1]
  }
}
// https://blog.devgenius.io/how-to-extract-the-id-of-a-youtube-or-vimeo-url-with-javascript-ad5e2d1049a

export const getVimeoHash = (url: string) => {
  /* This regex matches a hexadecimal hash if given in any of these forms:
   *  - [https://player.]vimeo.com/video/{id}/{hash}[?params]
   *  - [https://player.]vimeo.com/video/{id}?h={hash}[&params]
   *  - [https://player.]vimeo.com/video/{id}?[params]&h={hash}
   *  - video/{id}/{hash}
   * If matched, the hash is available in the named group `hash`
   */
  const regex = /^.*(?:vimeo.com\/|video\/)(?:\d+)(?:\?.*&*h=|\/)+(?<hash>[\d,a-f]+)/
  const match = url.match(regex)

  if (match) {
    return match[1]
  }
}
// https://git.rita.moe/Mirrors/plyr/commit/b256c102e80a69dd9722632aa0a2db63b284783c

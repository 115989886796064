import { useState } from 'react'

import { Avatar, IconButton, Tooltip } from '@mui/material'
import { selectIsDarkTheme } from 'app'
import { AvatarMockIcon } from 'assets/icons/adaptive-icons/AvatarMockIcon'
import { CommentIcon } from 'assets/icons/adaptive-icons/CommentIcon'
import { WorkIcon } from 'assets/icons/adaptive-icons/WorkIcon'
import { useAppSelector } from 'common/hooks'
import { statusLiterals } from 'features/dev-club/data'
import { UserElement } from 'features/dev-club/service'
import { useGetCoursesQuery } from 'services/courses'
import { capitalizeFirstLetter } from 'utils'

import s from '../DevClub.module.css'

import { StudentReviewModal } from '../dev-club-modals/student-review-modal/StudentReviewModal'
import { WorksModal } from '../dev-club-modals/works-modal/WorksModal'
import { YoutubeLinkInput } from './youtube-link-input/YoutubeLinkInput'

type Props = {
  page: number
  pageCount: number
  status: string
  userItem: UserElement
}

export const DevClubTableRow = ({ page, pageCount, status, userItem }: Props) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme)

  const [showStudentReviewId, setShowStudentReviewId] = useState(-1)
  const [showWorksId, setShowWorksId] = useState(-1)

  const { data } = useGetCoursesQuery()

  const course = data?.items.map(course => {
    return userItem.user.courseIds
      ?.filter(id => id !== null)
      .sort((a, b) => a - b)
      .map((courseId, i) => {
        if (courseId === course.id) {
          return <div key={i}>{capitalizeFirstLetter(course.title)}</div>
        }
      })
  })
  const updateDate = new Date(userItem.info.updated).toLocaleDateString('ru-RU') + ','
  const updateTime = new Date(userItem.info.updated).toLocaleTimeString('ru-RU')

  return (
    <div className={s.tableRow}>
      <div className={`${s.column} ${s.avatarCell}`} style={{ width: '25%' }}>
        <span>
          <a href={userItem.user.avatar || undefined} rel={'noreferrer'} target={'_blank'}>
            <Avatar
              alt={'avatar'}
              src={userItem.user.avatar}
              sx={{ height: '36px', width: '36px' }}
            >
              <AvatarMockIcon isDarkTheme={isDarkTheme} />
            </Avatar>
          </a>
        </span>
        <span>{`${userItem.user.lastName} ${userItem.user.firstName}`}</span>
      </div>
      <div className={s.column} style={{ width: '10%' }}>
        {userItem.user.id}
      </div>
      <div className={s.column} style={{ width: '10%' }}>
        {statusLiterals[userItem.info.status as keyof typeof statusLiterals]}
      </div>
      <div className={s.column} style={{ width: '10%' }}>
        {course}
      </div>
      <div className={s.column} style={{ width: '10%' }}>
        <div>{updateDate}</div>
        <div>{updateTime}</div>
      </div>
      <div className={s.column} style={{ width: '25%' }}>
        <YoutubeLinkInput
          firstName={userItem.user.firstName}
          lastName={userItem.user.lastName}
          studentId={userItem.user.id}
          youtubeUrl={userItem.info.youtubeUrl as string}
        />
      </div>
      <div className={s.column} style={{ width: '10%' }}>
        <Tooltip placement={'top-end'} title={'Отзыв от студента'}>
          <IconButton
            className={userItem.info.review ? '' : s.hideButton}
            onClick={() => setShowStudentReviewId(userItem.user.id)}
          >
            <CommentIcon isDarkTheme={isDarkTheme} />
          </IconButton>
        </Tooltip>
        <Tooltip placement={'top-end'} title={'Опыт работы'}>
          <IconButton onClick={() => setShowWorksId(userItem.user.id)}>
            <WorkIcon isDarkTheme={isDarkTheme} />
          </IconButton>
        </Tooltip>
      </div>

      <StudentReviewModal
        firstName={userItem.user.firstName}
        lastName={userItem.user.lastName}
        onClose={() => setShowStudentReviewId(-1)}
        open={showStudentReviewId === userItem.user.id}
        review={userItem.info.review as string}
        studentId={userItem.user.id}
        {...{ page, pageCount, status }}
      />

      <WorksModal
        firstName={userItem.user.firstName}
        lastName={userItem.user.lastName}
        linkedIn={userItem.user.linkedIn || ''}
        onClose={() => setShowWorksId(-1)}
        open={showWorksId === userItem.user.id}
        studentId={userItem.user.id}
        works={userItem.info.works}
      />
    </div>
  )
}

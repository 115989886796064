import { SVGProps, memo } from 'react'

import { selectIsDarkTheme } from 'app'
import { useAppSelector } from 'common/hooks'

export const ThumbUp = memo((props: SVGProps<SVGSVGElement>) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme)

  return (
    <svg
      fill={'none'}
      height={'16'}
      viewBox={'0 0 16 16'}
      width={'16'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <g clipPath={'url(#clip0_3254_62439)'}>
        <path
          d={
            'M8.73967 3.81325L8.35967 5.73992C8.27967 6.13325 8.38634 6.53992 8.63967 6.84659C8.89301 7.15325 9.26634 7.33325 9.66634 7.33325H13.333V8.05325L11.6197 11.9999H6.22634C6.10634 11.9999 5.99967 11.8933 5.99967 11.7733V6.54659L8.73967 3.81325ZM9.33301 1.33325L5.05967 5.60659C4.80634 5.85992 4.66634 6.19992 4.66634 6.55325V11.7733C4.66634 12.6333 5.36634 13.3333 6.22634 13.3333H11.6263C12.0997 13.3333 12.533 13.0866 12.773 12.6866L14.553 8.58659C14.6263 8.41992 14.6663 8.23992 14.6663 8.05325V7.33325C14.6663 6.59992 14.0663 5.99992 13.333 5.99992H9.66634L10.2797 2.89992C10.313 2.75325 10.293 2.59325 10.2263 2.45992C10.073 2.15992 9.87967 1.88659 9.63968 1.64659L9.33301 1.33325ZM2.66634 5.99992H1.33301V13.3333H2.66634C3.03301 13.3333 3.33301 13.0333 3.33301 12.6666V6.66659C3.33301 6.29992 3.03301 5.99992 2.66634 5.99992Z'
          }
          fill={isDarkTheme ? '#747D86' : '#8C8889'}
        />
      </g>
      <defs>
        <clipPath id={'clip0_3254_62439'}>
          <rect fill={'white'} height={'16'} width={'16'} />
        </clipPath>
      </defs>
    </svg>
  )
})
